import React from "react";
import { NftProperty } from "../../helpers/nftProperties";
import { Tag } from "antd";

const styles = {
    property: {
        background: "none",
        lineHeight: "14px",
        padding: "4px 10px",
        marginRight: "6px",
        marginBottom: "6px",
        width: "100px",
    } as React.CSSProperties,
    propertyName: {
        fontSize: "8px",
        color: "var(--tl-text-color)",
    } as React.CSSProperties,
    propertyValue: {
        fontSize: "12px",
        marginTop: "-2px",
        fontWeight: "600",
    } as React.CSSProperties,
} as const;

interface PopoverPropertiesSectionProps {
    title: string;
    properties: NftProperty[];
    style?: React.CSSProperties | undefined;
}

export const PopoverPropertiesSection = (props: PopoverPropertiesSectionProps) => {
    const properties = props.properties;
    return (
        <>
            {properties.length > 0 && (
                <div style={props.style}>
                    <div className="tl-props-container" style={{ justifyContent: "center" }}>
                        {properties.map((p, index) => (
                            <Tag key={`tag-${props.title}-${index}`} color={p.color} style={styles.property}>
                                <p style={styles.propertyName}>{p.name.toUpperCase()}</p>
                                <p style={styles.propertyValue}>{p.value}</p>
                            </Tag>
                        ))}
                    </div>
                </div>
            )}
        </>
    );
}