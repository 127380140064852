import { useReadContract } from "wagmi";
import ownerOfAbi from "../../data/erc721_ownerOf_abi.json";

const FUNCTION_NAME = "ownerOf";

export const useErc721OwnerOf = (enabled: boolean, nftContractAddress: string | undefined, tokenId: string | undefined) => {
    const { data, error } = useReadContract({
        query: {
            enabled: tokenId !== undefined && enabled,
        },
        functionName: FUNCTION_NAME,
        abi: [ownerOfAbi],
        address: nftContractAddress as `0x${string}`,
        args: [tokenId]
    });

    return {
        nftOwner: data ? (data as string) : undefined,
        nftOwnerError: error
    };
}
