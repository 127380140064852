import { Chain } from "viem";
import { NoNftsPlaceholder } from "../components/Nfts/NftList";
import { MIN_UNIQUE_TOKEN_ID, MAX_UNIQUE_TOKEN_ID } from "./constants";
import {
    LAND_CONTRACT_ADDRESS_KEY,
    BUILDING_CONTRACT_ADDRESS_KEY,
    UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY,
    TOWN_CONTRACT_ADDRESS_KEY,
    TOWN_EFFECT_CONTRACT_ADDRESS_KEY,
    getContractAddress
} from "./networks";
import { TlNftType } from "./nfts";

export const OVERVIEW_ROUTE = "/overview";
export const MY_PROPERTY_ROUTE = "/my-property";
export const MARKET_ROUTE = "/market";
export const VIEW_TOWNS_ROUTE = "/explore-towns";
export const BUILDER_ROUTE = "/builder";

export const MY_PROPERTY_TOWNS_SUBROUTE = "/my-property/towns";
export const MY_PROPERTY_BUILDINGS_SUBROUTE = "/my-property/buildings";
export const MY_PROPERTY_TOWN_EFFECTS_SUBROUTE = "/my-property/town-effects";
export const MARKET_LANDS_SUBROUTE = "/market/lands";
export const MARKET_BUILDINGS_SUBROUTE = "/market/buildings";
export const MARKET_UNIQUE_SUBROUTE = "/market/unique";
export const MARKET_TOWN_EFFECTS_SUBROUTE = "/market/town-effects";

export const NFT_ITEM_TEMPLATE_ROUTE = "/:id";

export const defaultRoute = MARKET_ROUTE;


export const resolveContractAddressFromRoute = (isConnected: boolean, chain: Chain | undefined, urlLocation: string, id: string | undefined): string | undefined => {
    const tokenId = id === undefined ? 0 : Number(id);

    let key: string | undefined = undefined;
    if (urlLocation.includes(MY_PROPERTY_ROUTE)) {
        if (urlLocation.includes(MY_PROPERTY_BUILDINGS_SUBROUTE) && (tokenId < MIN_UNIQUE_TOKEN_ID || tokenId > MAX_UNIQUE_TOKEN_ID)) {
            key = BUILDING_CONTRACT_ADDRESS_KEY;
        } else if (urlLocation.includes(MY_PROPERTY_BUILDINGS_SUBROUTE) && tokenId >= MIN_UNIQUE_TOKEN_ID && tokenId <= MAX_UNIQUE_TOKEN_ID) {
            key = UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY;
        } else if (urlLocation.includes(MY_PROPERTY_TOWNS_SUBROUTE)) {
            key = TOWN_CONTRACT_ADDRESS_KEY;
        } else if (urlLocation.includes(MY_PROPERTY_TOWN_EFFECTS_SUBROUTE)) {
            key = TOWN_EFFECT_CONTRACT_ADDRESS_KEY;
        }
    } else if (urlLocation.includes(MARKET_ROUTE)) {
        if (urlLocation.includes(MARKET_LANDS_SUBROUTE)) {
            key = LAND_CONTRACT_ADDRESS_KEY;
        } else if (urlLocation.includes(MARKET_BUILDINGS_SUBROUTE)) {
            key = BUILDING_CONTRACT_ADDRESS_KEY;
        } else if (urlLocation.includes(MARKET_UNIQUE_SUBROUTE)) {
            key = UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY;
        } else if (urlLocation.includes(MARKET_TOWN_EFFECTS_SUBROUTE)) {
            key = TOWN_EFFECT_CONTRACT_ADDRESS_KEY;
        }
    } else if (urlLocation.includes(VIEW_TOWNS_ROUTE)) {
        key = TOWN_CONTRACT_ADDRESS_KEY;
    }

    return key === undefined ? undefined : getContractAddress(isConnected, chain, key);
}

export const resolveMarketSubRoute = (tlNftType: TlNftType): string => {
    switch (tlNftType) {
        case TlNftType.Land:
        case TlNftType.Town:
            return MARKET_LANDS_SUBROUTE;
        case TlNftType.Building:
            return MARKET_BUILDINGS_SUBROUTE;
        case TlNftType.UniqueBuilding:
            return MARKET_UNIQUE_SUBROUTE;
        case TlNftType.TownEffect:
            return MARKET_TOWN_EFFECTS_SUBROUTE;
        default:
            return MARKET_ROUTE;
    }
}

export const resolveMyPropertyNftsAmountSubTitle = (currentTabIndex: number) => {
    if (currentTabIndex === 0) {
        return "Towns Amount";
    } else if (currentTabIndex === 1) {
        return "Buildings Amount";
    } else if (currentTabIndex === 2) {
        return "Town Effects Amount";
    }

    return "NFTs Amount";
}

export const resolveMyPropertyNoNftsPlaceholder = (currentTabIndex: number): NoNftsPlaceholder | undefined => {
    if (currentTabIndex === 0) {
        return {
            text: "You have no Towns. You can buy Land in the Market: ",
            buttonText: "Explore Lands",
            buttonRelativeHref: MARKET_LANDS_SUBROUTE,
        };
    } else if (currentTabIndex === 1) {
        return {
            text: "You have no unbuilt Buildings. You can buy some in the Market: ",
            buttonText: "Explore Buildings",
            buttonRelativeHref: MARKET_BUILDINGS_SUBROUTE,
            button2Text: "Explore Unique Buildings",
            button2RelativeHref: MARKET_UNIQUE_SUBROUTE,
        };
    } else if (currentTabIndex === 2) {
        return {
            text: "You have no Town Effects. You can buy some in the Market: ",
            buttonText: "Explore Town Effects",
            buttonRelativeHref: MARKET_TOWN_EFFECTS_SUBROUTE,
        };
    }

    return undefined;
}

export const resolveMarketContractAddressKey = (currentTabIndex: number) => {
    if (currentTabIndex === 0) {
        return LAND_CONTRACT_ADDRESS_KEY;
    } else if (currentTabIndex === 1) {
        return BUILDING_CONTRACT_ADDRESS_KEY;
    } else if (currentTabIndex === 2) {
        return UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY;
    } else if (currentTabIndex === 3) {
        return TOWN_EFFECT_CONTRACT_ADDRESS_KEY;
    }

    return "";
}

export const resolveMarketNftsAmountSubTitle = (currentTabIndex: number) => {
    if (currentTabIndex === 0) {
        return "Lands Amount";
    } else if (currentTabIndex === 1) {
        return "Buildings Amount";
    } else if (currentTabIndex === 2) {
        return "Unique Buildings Amount";
    } else if (currentTabIndex === 3) {
        return "Town Effects Amount";
    }

    return "NFTs Amount";
}
