import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import { Nft, getTlcPriceIfExists } from "../helpers/nfts";
import TlcIcon from "../img/tlc.png"
import { BuyForTlcModal } from "./Nfts/BuyForTlcModal";
import { useAccount } from "wagmi";
import { isChainSupported } from "../helpers/networks";
import { NOT_CONNECTED_ACTION_TOOLTIP_TEXT } from "../helpers/constants";

export interface BuyForTlcButtonProps {
    index: number | string;
    nft: Nft | undefined;
}

export const BuyForTlcButton = (props: BuyForTlcButtonProps) => {
    const { isConnected, chain } = useAccount();
    const [isBuyForTlcModalOpen, setIsBuyForTlcModalOpen] = useState<boolean>(false);

    let tlcPrice = getTlcPriceIfExists(props.nft);
    if (tlcPrice) {
        return (
            <div key={props.index + "-buy-for-tlc"} style={{ marginLeft: "8px" }}>
                <BuyForTlcModal
                    index={props.index}
                    nft={props.nft!}
                    isOpen={isBuyForTlcModalOpen}
                    onCancel={() => setIsBuyForTlcModalOpen(false)}
                    onOperationFinished={() => setIsBuyForTlcModalOpen(false)} />
                <Tooltip title={isConnected && isChainSupported(chain) ? undefined : NOT_CONNECTED_ACTION_TOOLTIP_TEXT}>
                    <Button
                        key={props.index}
                        type="primary"
                        size="large"
                        disabled={!isConnected || !isChainSupported(chain)}
                        onClick={() => setIsBuyForTlcModalOpen(true)}>
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <span style={{ paddingRight: "6px" }}>Buy for {tlcPrice}</span>
                            <img src={TlcIcon} className="tl-balance-icon" width={20} height={20} style={{ marginRight: "0px" }} />
                        </div>
                    </Button>
                </Tooltip>
            </div>
        );
    }

    return undefined;
}