import {
    BUILDING_CONTRACT_ADDRESS_KEY,
    LAND_CONTRACT_ADDRESS_KEY,
    TOWN_CONTRACT_ADDRESS_KEY,
    TOWN_EFFECT_CONTRACT_ADDRESS_KEY,
    UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY
} from "./networks";

export type Nft = {
    token_address: string;
    token_id: string;
    token_uri?: string | undefined;
    metadata?: NftMetadata;
    amount?: string | undefined;
    name: string;
    image: string;
    preview_image: string;
    owner?: string;
    tlNftType: TlNftType;
    tlSession?: string;
}

export type NftMetadata = {
    name: string;
    description: string;
    image: string;
    preview_image: string;
    attributes: NftMetadataAttribute[];
}

export type NftMetadataAttribute = {
    display_type: string;
    trait_type: string;
    value: any;
    tl_enum_value: number;

    tl_tlc_price: number;
    tl_price: number;
}

export enum TlNftType {
    Unknown = 1,
    Land = 2,
    Town = 3,
    Building = 4,
    UniqueBuilding = 5,
    TownEffect = 6
}

export const getTlcPriceIfExists = (nft: Nft | undefined): number | undefined => {
    let attribute = nft && nft.metadata && nft.metadata.attributes
        ? nft.metadata.attributes.find(a => a.tl_tlc_price && a.tl_tlc_price > 0)
        : undefined;

    return attribute ? attribute.tl_tlc_price : undefined;
}

export const getPriceIfExists = (nft: Nft | undefined): number | undefined => {
    let attribute = nft && nft.metadata && nft.metadata.attributes
        ? nft.metadata.attributes.find(a => a.tl_price && a.tl_price > 0)
        : undefined;

    return attribute ? attribute.tl_price : undefined;
}

export const resolveContractAddressKey = (tlNftType: TlNftType) => {
    switch (tlNftType) {
        case TlNftType.Land:
            return LAND_CONTRACT_ADDRESS_KEY;
        case TlNftType.Town:
            return TOWN_CONTRACT_ADDRESS_KEY;
        case TlNftType.Building:
            return BUILDING_CONTRACT_ADDRESS_KEY;
        case TlNftType.UniqueBuilding:
            return UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY;
        case TlNftType.TownEffect:
            return TOWN_EFFECT_CONTRACT_ADDRESS_KEY;
        default:
            return "";
    }
}

export const isErc1155 = (tlNftType: TlNftType) => {
    return tlNftType === TlNftType.Land
        || tlNftType === TlNftType.Building
        || tlNftType === TlNftType.UniqueBuilding
        || tlNftType === TlNftType.TownEffect;
}

export const isErc721 = (tlNftType: TlNftType) => {
    return tlNftType === TlNftType.Town;
}

export const isPurchaseable = (tlNftType: TlNftType): boolean => {
    return isErc1155(tlNftType) && tlNftType !== TlNftType.UniqueBuilding;
}