import React from "react";

const styles = {
    banner: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        textAlign: "center",
        margin: "0px auto 60px",
        width: "600px",
        height: "100px",
        filter: "drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px)",
    } as React.CSSProperties,
    collectionTitle: {
        color: "#d2cec9",
        fontSize: "60px",
        fontWeight: "bold",
    } as React.CSSProperties,
    collectionTitleSubText: {
        fontSize: "20px",
        color: "#9c9c9c",
        fontWeight: "normal",
        marginTop: "-10px"
    } as React.CSSProperties,
} as const;

interface NftPageTitleProps {
    title: string;
    subTitle: string;
    isLoading: boolean;
    totalNfts: number;
}

const NftPageTitle = (props: NftPageTitleProps) => {
    return (
        <div style={styles.banner}>
            <div style={styles.collectionTitle}>
                <>
                    <div>{props.title}</div>
                    <div style={styles.collectionTitleSubText}>
                        {`${props.subTitle}: ${props.isLoading || props.totalNfts === -1 ? "..." : props.totalNfts}`}
                    </div>
                </>
            </div>
        </div>
    );
}

export default NftPageTitle;
