import { useEffect, useState } from "react";
import { useAccount, useWriteContract, useSimulateContract } from "wagmi";
import { getContractAddress } from "../../helpers/networks";
import { message } from "antd";
import { getMessageContentWithTxDetails, waitForTransactionComplete } from "../../helpers/transactions";
import { NFT_NOT_ON_SALE_MESSAGE, NOT_ENOUGH_CRYPTO_MESSAGE, TOO_BIG_AMOUNT_MESSAGE } from "../../helpers/constants";
import { TlNftType, isPurchaseable, resolveContractAddressKey } from "../../helpers/nfts";
import buyAbi from "../../data/erc1155_buy_abi.json";

const CONTRACT_FUNCTION_NAME = "buy";
const MESSAGE_BOX_KEY = "erc1155_buy";

export const useErc1155Buy = (enabled: boolean, tokenId: string | undefined, amount: number | undefined,
    cryptoValueWei: BigInt | undefined, tlNftType: TlNftType) => {

    const { isConnected, chain } = useAccount();
    const { data, error } = useSimulateContract({
        query: {
            enabled: enabled && isConnected && isPurchaseable(tlNftType) && tokenId != undefined && amount !== undefined && cryptoValueWei != undefined
        },
        functionName: CONTRACT_FUNCTION_NAME,
        args: [
            tokenId,
            amount
        ],
        value: cryptoValueWei ? BigInt(String(cryptoValueWei!)) : BigInt(0),
        address: getContractAddress(isConnected, chain, resolveContractAddressKey(tlNftType)),
        abi: [buyAbi],
    });
    const { writeContractAsync } = useWriteContract();

    const [isPurchaseInProgress, setIsPurchaseInProgress] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [preparationError, setPreparationError] = useState<string>();

    useEffect(() => {
        setIsPrepared(data?.request !== undefined);
        if (data?.request) {
            setPreparationError(undefined);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            if (error.message.includes("owner or approved account cannot buy an NFT")) {
                setPreparationError("Owner or empowered accounts cannot buy NFTs");
            } else if (error.message.includes("not enough crypto to buy this NFT")) {
                setPreparationError(NOT_ENOUGH_CRYPTO_MESSAGE);
            } else if (error.message.includes("This NFT is not on sale")) {
                setPreparationError(NFT_NOT_ON_SALE_MESSAGE);
            } else if (error.message.includes("burn amount exceeds balance")) {
                setPreparationError(TOO_BIG_AMOUNT_MESSAGE);
            }
            else {
                setPreparationError("Unable to purchase this NFT due to unknown error :(");
            }
        }
    }, [error]);

    const buy = async (onSuccess: () => void = () => { }) => {
        setIsPurchaseInProgress(true);
        message.loading({ content: "Waiting for your wallet...", key: MESSAGE_BOX_KEY, duration: 0 });

        if (error) {
            message.error({ content: "Purchase failed to start due to Unknown error! Please try again later.", key: MESSAGE_BOX_KEY, duration: 5 });
            setIsPurchaseInProgress(false);
            return;
        }

        if (data?.request) {
            await writeContractAsync(data.request)
                .then(async txHash => {
                    message.loading({
                        content: getMessageContentWithTxDetails("Purchasing...", txHash, chain!.id),
                        key: MESSAGE_BOX_KEY,
                        duration: 0
                    });

                    await waitForTransactionComplete(txHash, chain!.id, MESSAGE_BOX_KEY,
                        onSuccess, "Purchase completed!");
                    setIsPurchaseInProgress(false);
                })
                .catch(e => {
                    if (e) {
                        let error = e as any;
                        if (error.message.includes("User rejected")) {
                            message.warn({ content: "You cancelled the purchase.", key: MESSAGE_BOX_KEY, duration: 2 });
                            setIsPurchaseInProgress(false);
                            return;
                        }
                    }

                    message.error({ content: "Purchase failed due to Unknown error! Please try again later.", key: MESSAGE_BOX_KEY, duration: 5 });
                    setIsPurchaseInProgress(false);
                });
        }
    }

    return {
        buy,
        isBuyPrepared: isPrepared,
        isBuyInProgress: isPurchaseInProgress,
        buyPreparationError: preparationError
    };
}