import { LOADING_CURRENCY_VALUE_PLACEHOLDER } from "../hooks/useBalance";
import { TLC_DECIMALS } from "./constants";

/**
 * Returns a string of form "abc...xyz"
 * @param {string} str string to transform 
 * @param {number} n number of chars to keep in the string 
 * @returns {string}
 */
export const getEllipsisText = (str: string, n = 6) => {
    if (str) {
        return `${str.slice(0, n)}...${str.slice(str.length - n)}`;
    }
    return "";
};

export const roundPriceForDisplay = (price: number | undefined): string => {
    if (price === undefined) {
        return LOADING_CURRENCY_VALUE_PLACEHOLDER;
    }

    if (price < 0.0001) {
        return "<0.0001";
    }

    const decimalPlaces = 4;
    let roundedPrice = price.toString();

    if (roundedPrice.indexOf('.') !== -1 && roundedPrice.split('.')[1].length > decimalPlaces) {
        roundedPrice = roundToNDecimalPlaces(price, decimalPlaces).toString();
    }

    return roundedPrice;
}

// export const roundPrice = (price: number | undefined): number | undefined => {
//     if (price === undefined) {
//         return undefined;
//     }

//     if (price < 0.0001) {
//         return 0.0001;
//     }

//     let priceToMultiply

//     const decimalPlaces = 4;
//     let roundedPrice = price.toString();

//     if (roundedPrice.indexOf('.') !== -1 && roundedPrice.split('.')[1].length > decimalPlaces) {
//         roundedPrice = roundToNDecimalPlaces(price, decimalPlaces).toString();
//     }

//     return Number(roundedPrice);
// }

export const roundToNDecimalPlaces = (num: number, decimalPlaces: number): number => {
    const factor = Math.pow(10, decimalPlaces);
    return Math.round(num * factor) / factor;
}

export const tlcToWei = (tlc: number | undefined): number | undefined => {
    return ethToWei(tlc);
}

export const weiToTlc = (tlcWei: number | undefined): number | undefined => {
    return weiToEth(tlcWei);
}

export const ethToWei = (eth: number | undefined): number | undefined => {
    return eth ? eth * (10 ** TLC_DECIMALS) : undefined;
}

export const weiToEth = (wei: number | undefined): number | undefined => {
    return wei ? wei / (10 ** TLC_DECIMALS) : undefined;
}