import React, { useState } from "react";
import { Button, Tooltip } from "antd";
import { Nft, getPriceIfExists } from "../helpers/nfts";
import { BuyModal } from "./Nfts/BuyModal";
import { useNativeCurrencyInfo } from "../hooks/useBalance";
import { useAccount } from "wagmi";
import { isChainSupported } from "../helpers/networks";
import { NOT_CONNECTED_ACTION_TOOLTIP_TEXT } from "../helpers/constants";

export interface BuyButtonProps {
    index: number | string;
    nft: Nft | undefined;
}

export const BuyButton = (props: BuyButtonProps) => {
    const { isConnected, chain } = useAccount();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const { tokenIcon } = useNativeCurrencyInfo();

    let price = getPriceIfExists(props.nft);
    if (price) {
        return (
            <div key={props.index + "-buy"} style={{ marginLeft: "8px" }}>
                <BuyModal
                    index={props.index}
                    nft={props.nft!}
                    isOpen={isModalOpen}
                    onCancel={() => setIsModalOpen(false)}
                    onOperationFinished={() => setIsModalOpen(false)} />
                <Tooltip title={isConnected && isChainSupported(chain) ? undefined : NOT_CONNECTED_ACTION_TOOLTIP_TEXT}>
                    <Button
                        key={props.index}
                        type="primary"
                        size="large"
                        disabled={!isConnected || !isChainSupported(chain)}
                        onClick={() => setIsModalOpen(true)}>
                        <div style={{ display: "flex" }}>
                            <span style={{ paddingRight: "6px" }}>Buy for {price}</span>
                            <img src={tokenIcon} className="tl-balance-icon" width={20} height={20} style={{ marginRight: "0px" }} />
                        </div>
                    </Button>
                </Tooltip>
            </div>
        );
    }

    return undefined;
}