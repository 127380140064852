import React, { useState } from "react";
import { Modal, Card, Button, Divider, message, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import { SelectOutlined } from "@ant-design/icons";
import { getConnectorInfo, isConnectorSupported } from "./connectorsConfig";
import { getEllipsisText } from "../../helpers/formatters";
import { getExplorer, isChainSupported } from "../../helpers/networks";
import Blockie from "../Blockie/Blockie";
import Address from "../Address";
import { NativeBalance, TlcBalance } from "./Balances";
import { scrollTop } from "../../helpers/scroller";
import { useConnect, useDisconnect, useAccount, Connector } from "wagmi";
import { WALLET_WAITING_FOR_ACTION_MESSAGE } from "../../helpers/constants";
import { SendTlcButton } from "../SendTlcButton";

const styles = {
    authModal: {
        fontSize: "16px",
        fontWeight: "500",
    } as React.CSSProperties,
    authModalTitle: {
        padding: "10px",
        display: "flex",
        justifyContent: "center",
        fontWeight: "700",
        fontSize: "20px",
    } as React.CSSProperties,
    connectorsContainer: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
    } as React.CSSProperties,
    connector: {
        alignItems: "center",
        display: "flex",
        flexDirection: "column",
        height: "auto",
        justifyContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        padding: "20px 5px",
        cursor: "pointer",
    } as React.CSSProperties,
    injectedWalletIcon: {
        fontSize: "30px",
        marginBottom: "14px",
        color: "rgb(100 145 182)",
    } as React.CSSProperties,
    connectorText: {
        fontSize: "14px",
        textAlign: "center",
        width: "120px"
    } as React.CSSProperties,
    blockie: {
        scale: 3,
        borderRadius: "50px",
    } as React.CSSProperties,
    accountModal: {
        fontSize: "16px",
        fontWeight: "500",
    } as React.CSSProperties,
    accountModalCard: {
        marginTop: "10px",
        borderRadius: "1rem",
        filter: "none",
        border: "1px solid var(--tl-card-border-color)",
    } as React.CSSProperties,
    accountModalAddress: {
        justifyContent: "center",
        fontSize: "20px",
    } as React.CSSProperties,
    accountModalExplorerLink: {
        textAlign: "center",
        marginTop: "10px",
        padding: "0 10px",
    } as React.CSSProperties,
    accountModalExplorerIcon: {
        marginRight: "5px",
    } as React.CSSProperties,
    balancesSection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    } as React.CSSProperties,
    accountWindowButton: {
        width: "100%",
        marginTop: "10px",
        borderRadius: "0.5rem",
        fontSize: "16px",
    } as React.CSSProperties,
} as const;

const Account = () => {
    const { connectAsync, connectors } = useConnect();
    const { disconnectAsync } = useDisconnect();
    const { isConnected, address, chain } = useAccount();
    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
    const [isAuthModalVisible, setIsAuthModalVisible] = useState<boolean>(false);
    const explorerUrl: string = getExplorer(chain);

    const getWalletCard = (connector: Connector) => {
        const connectorInfo = getConnectorInfo(connector);
        return (
            <div
                style={styles.connector}
                key={connector.id}
                onClick={async () => {
                    setIsAuthModalVisible(false);
                    await connectAsync({ connector })
                        .then(() => {
                            setIsAuthModalVisible(false);
                        })
                        .catch(e => {
                            const errorMessageDuration = 5;
                            if (e && e.message) {
                                if (e.message.includes("Non ethereum enabled browser")
                                    || e.message.includes("Extension context invalidated")
                                    || e.message.includes("Resource unavailable")
                                    || e.message.includes("Connector not found")) {

                                    message.error(`Please make sure your ${connectorInfo.title} extension is enabled and ready for use in your browser`, errorMessageDuration);
                                    return;
                                } else if (e.message.includes("User denied")
                                    || e.message.includes("User rejected")
                                    || e.message.includes("Connection request reset")) {
                                    message.warning("You rejected request from the wallet", errorMessageDuration);
                                    return;
                                } else if (e.message.includes("Already processing")) {
                                    message.error(WALLET_WAITING_FOR_ACTION_MESSAGE, errorMessageDuration);
                                    return;
                                }
                            }

                            message.error("Check if your wallet is ready for use in your browser.", errorMessageDuration);

                        });
                }}
            >
                <img className="tl-wallet-icon"
                    src={connectorInfo.icon}
                    alt={connectorInfo.title}
                    style={{ height: `${connectorInfo.iconSize}px` }} />
                <Text style={styles.connectorText}>{connector.name}</Text>
            </div>
        );
    }

    if (!isConnected) {
        return (
            <>
                <button className={`tl-solid-button bold ${isAuthModalVisible ? "hovered" : ""}`}
                    onClick={() => setIsAuthModalVisible(true)}
                >
                    <span className="noselect">Connect Wallet</span>
                </button>
                <Modal
                    open={isAuthModalVisible}
                    footer={null}
                    onCancel={() => setIsAuthModalVisible(false)}
                    bodyStyle={{
                        padding: "15px",
                        fontSize: "17px",
                        fontWeight: "500",
                    }}
                    style={styles.authModal}
                    width="340px"
                >
                    <div style={styles.authModalTitle}>
                        Connect Wallet
                    </div>
                    <div style={styles.connectorsContainer}>
                        {connectors
                            // .filter((connector) => checkIsWalletDisplayable(connector.id, connector.name))
                            .filter((connector) => isConnectorSupported(connector.id))
                            .map((connector) => (
                                connector.id === "injected"
                                    ? (
                                        <Tooltip key={connector.id} title={`${connector.name} detected in your browser`} overlayInnerStyle={{ marginBottom: "-20px", textAlign: "center" }}>
                                            {getWalletCard(connector)}
                                        </Tooltip>
                                    )
                                    : (
                                        getWalletCard(connector)
                                    )

                            ))}
                    </div>
                </Modal>
            </>
        );
    }

    return (
        <>
            <button className={`tl-solid-button inverted ${isModalVisible ? "hovered" : ""}`}
                onClick={() => setIsModalVisible(true)}
            >
                <span className="noselect">{getEllipsisText(address!, 6)}</span>
                <Blockie fromCurrentWallet scale={3} />
            </button>
            <Modal
                open={isModalVisible}
                footer={null}
                onCancel={() => setIsModalVisible(false)}
                bodyStyle={{
                    padding: "15px",
                    fontSize: "17px",
                    fontWeight: "500",
                }}
                style={styles.accountModal}
                width="400px"
                destroyOnClose
            >
                Account
                <Card
                    style={styles.accountModalCard}
                    bodyStyle={{ padding: "15px" }}
                >
                    <Address
                        avatar="left"
                        size={6}
                        copyable
                        style={styles.accountModalAddress}
                    />
                    <div style={styles.accountModalExplorerLink}>
                        <a
                            href={`${explorerUrl}address/${address}`}
                            target="_blank"
                            rel="noreferrer"
                        >
                            <SelectOutlined style={styles.accountModalExplorerIcon} />
                            View on Explorer
                        </a>
                    </div>
                    {isChainSupported(chain) && (
                        <>
                            <Divider />
                            <div style={styles.balancesSection}>
                                <NativeBalance />
                                <TlcBalance />
                            </div>
                        </>
                    )}
                </Card>
                {isChainSupported(chain) &&
                    <SendTlcButton style={styles.accountWindowButton} onOperationFinished={() => setIsModalVisible(false)} />
                }
                <Button
                    className="tl-button-darken-on-hover"
                    size="large"
                    type="primary"
                    style={{ ...styles.accountWindowButton, color: "rgb(196, 0, 0)", borderColor: "rgb(163 0 0)" }}
                    onClick={async () => {
                        await disconnectAsync();
                        scrollTop();
                        setIsModalVisible(false);
                    }}
                    ghost
                >
                    Disconnect Wallet
                </Button>
            </Modal>
        </>
    );
}

export default Account;
