import React, { useState } from "react";
import { Modal, Button, Tooltip, InputNumber, Input } from "antd";
import { NOT_ENOUGH_TLC_TO_TRANSFER_MESSAGE, TARGET_ACCOUNT_IS_EQUAL_TO_CURRENT_MESSAGE, TLC_CURRENCY_SYMBOL, WRONG_TARGET_ACCOUNT_MESSAGE } from "../../helpers/constants";
import { useAccount } from "wagmi";
import { isAddress } from "viem";
import { useErc20Transfer } from "../../hooks/contracts/useErc20Transfer";
import TlcIcon from "../../img/tlc.png";

export interface SendTlcModalProps {
    currentBalance: number | undefined;
    isOpen: boolean;
    onCancel?: () => void | undefined;
    onOperationFinished?: () => void | undefined;
}

export const SendTlcModal = (props: SendTlcModalProps) => {
    const { isConnected, address } = useAccount();
    const [transferAmount, setTransferAmount] = useState<number | undefined>(1);
    const [toAddress, setToAddress] = useState<string | undefined>();
    const [showErrorInAccountInput, setShowErrorInAccountInput] = useState<boolean>(false);

    const { transfer, isTransferInProgress, isTransferPrepared, prepareTransferError } = useErc20Transfer(isConnected, transferAmount, toAddress);

    const isEnoughAmount = (): boolean => {
        const totalAmount = props.currentBalance;
        if (transferAmount === undefined || totalAmount === undefined) {
            return false;
        }

        return totalAmount >= transferAmount;
    }

    const isToAccountValid = (): boolean => {
        return toAddress
            ? isAddress(toAddress) && address !== undefined && toAddress.toUpperCase() !== address.toUpperCase()
            : false;
    }

    const isTransferPossible = (): boolean => {
        return isEnoughAmount() && isToAccountValid();
    }

    const isTransferButtonLoading = (): boolean => {
        return isTransferPrepared === false && prepareTransferError === undefined && props.currentBalance === undefined;
    }

    const resolveTooltipTitle = () => {
        if (transferAmount === undefined) {
            return "Amount is empty";
        }
        else if (prepareTransferError !== undefined) {
            return prepareTransferError;
        }
        else if (!isEnoughAmount()) {
            return NOT_ENOUGH_TLC_TO_TRANSFER_MESSAGE;
        }
        else if (address !== undefined && toAddress != undefined && toAddress.toUpperCase() === address.toUpperCase()) {
            return TARGET_ACCOUNT_IS_EQUAL_TO_CURRENT_MESSAGE;
        }
        else if (!isToAccountValid()) {
            return WRONG_TARGET_ACCOUNT_MESSAGE;
        }
        else {
            return "";
        }
    }

    return (
        <Modal
            className="tl-send-or-buy-nft-modal close-icon-hidden"
            closeIcon={null}
            centered={true}
            style={{ marginTop: "-100px", maxWidth: "508px" }}
            maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.65)" }}
            open={props.isOpen}
            onCancel={props.onCancel}
            bodyStyle={{ paddingBottom: "20px", textAlign: "left" }}
            footer={[
                <div key="balance-amount"
                    style={{
                        color: "var(--tl-text-color)",
                        display: "flex",
                        fontSize: "14px",
                        justifyContent: "right",
                        marginRight: "auto"
                    }}>
                    <span style={{ marginRight: "12px", marginTop: "auto", marginBottom: "auto" }}>Your balance: </span>
                    <Tooltip title={TLC_CURRENCY_SYMBOL} placement="right">
                        <div style={{ display: "flex", justifyContent: "center" }}>
                            <img
                                src={TlcIcon}
                                className="tl-balance-icon noselect"
                                width={18}
                                height={18}
                            />
                            <span style={{ marginTop: "auto", marginBottom: "auto", fontWeight: "600" }}>
                                {props.currentBalance}
                            </span>
                        </div>
                    </Tooltip>
                </div>,
                <Button key="cancel-send-tlc"
                    onClick={() => {
                        setShowErrorInAccountInput(false);
                        props.onCancel?.();
                    }}
                >Cancel</Button>,
                <Tooltip key="send-tlc" placement="bottom" title={resolveTooltipTitle()}>
                    <Button
                        type="primary"
                        loading={isTransferButtonLoading()}
                        disabled={!isTransferPossible() || prepareTransferError !== undefined || isTransferInProgress}
                        className="tl-button-loading"
                        style={{ justifyContent: "center", marginLeft: "8px", transition: "all .3s ease" }}
                        onClick={async () => {
                            await transfer(() => {
                                props.onCancel?.();
                                props.onOperationFinished?.();
                            })
                        }}>
                        Send
                    </Button>
                </Tooltip >
            ]}
        >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <div>
                    <div>
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>How much TLC do you want to send?</span>
                    </div>
                    <InputNumber
                        min={1}
                        max={props.currentBalance ? props.currentBalance : 99}
                        defaultValue={1}
                        precision={0}
                        style={{ marginTop: "4px", width: "100%", minWidth: "100px" }}
                        onChange={(n) => { setTransferAmount(n === null ? undefined : n) }}></InputNumber>
                    <div style={{ marginTop: "20px" }}>
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>Receiver address:</span>
                    </div>
                    <Tooltip placement="bottomLeft" open={showErrorInAccountInput && !isToAccountValid()} title={resolveTooltipTitle()}>
                        <Input
                            status={showErrorInAccountInput && !isToAccountValid() ? "error" : undefined}
                            style={{ marginTop: "4px", width: "100%", minWidth: "460px", borderRadius: "0.5rem" }}
                            onChange={(n) => {
                                setToAddress(n.target.value);
                                setShowErrorInAccountInput(true);
                            }}
                        >
                        </Input>
                    </Tooltip>
                </div>
            </div>
        </Modal >
    );
}
