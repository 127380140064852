import { createConfig } from "wagmi";
import { MAIN_CHAIN, getAlchemyNetwork, getViemNetwork as getViemChain, resolveAlchemyRpcUrl } from "./helpers/networks";
import { connectorsConfig } from "./components/Account/connectorsConfig";
import { createPublicClient, http } from "viem";

const mainChain = getViemChain(MAIN_CHAIN);
const mainAlchemyNetwork = getAlchemyNetwork(MAIN_CHAIN);

export const wagmiConfig = createConfig({
    chains: [mainChain],
    transports: {
        [mainChain.id]: http(resolveAlchemyRpcUrl(mainAlchemyNetwork))
    },
    connectors: Object.entries(connectorsConfig).map(([, connector]) => connector.wagmiConnector)
});

export const viemClient = createPublicClient({
    chain: mainChain,
    transport: http(resolveAlchemyRpcUrl(mainAlchemyNetwork)),
});