import React from "react";
import { Alert } from "antd";

interface LoadingItemsErrorProps {
    itemNamePlural: string;
}

export const LoadingItemsError = (props: LoadingItemsErrorProps) => {
    return (
        <Alert
            message={`Error loading ${props.itemNamePlural}. Reload the page or try again later`}
            type="error"
            showIcon
            style={{ width: "fit-content", marginLeft: "auto", marginRight: "auto", borderWidth: "0px", marginTop: "120px" }} />
    );
}
