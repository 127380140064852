import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from '@dnd-kit/utilities';

interface SortableItemProps {
    id: string;
    isDisabled?: boolean;
    children?: React.ReactNode;
}

export const SortableItem = ({ id, isDisabled, children }: SortableItemProps) => {
    const {
        attributes,
        listeners,
        setNodeRef,
        transform,
        transition,
        isDragging,
    } = useSortable({ id });

    const style: React.CSSProperties = {
        transform: CSS.Translate.toString(transform),
        transition: transition,
        cursor: isDisabled ? "not-allowed" : "move",
        ...(isDragging ? { position: 'relative', zIndex: 9999 } : {}),
    };

    return (
        isDisabled
            ? (
                <div ref={setNodeRef} style={style} {...attributes} className="noselect">
                    {children}
                </div>
            )
            :
            (
                <div ref={setNodeRef} style={style} {...attributes} {...listeners} className="noselect">
                    {children}
                </div>
            )
    );
};
