import React, { useEffect, useRef, useState } from "react";
import { Alert } from "antd";
import { useNfts } from "../hooks/useNfts";
import { getContractAddress } from "../helpers/networks";
import { useLocation } from "react-router-dom";
import NftPageTitle from "./Nfts/NftPageTitle";
import NftPageSubTabs from "./Nfts/NftPageSubTabs";
import NftList from "./Nfts/NftList";
import { CONNECTING_TEXT } from "../helpers/constants";
import { MARKET_BUILDINGS_SUBROUTE, MARKET_LANDS_SUBROUTE, MARKET_UNIQUE_SUBROUTE, MARKET_TOWN_EFFECTS_SUBROUTE, resolveMarketContractAddressKey, resolveMarketNftsAmountSubTitle } from "../helpers/routes";
import { useAccount } from "wagmi";
import { SwitchChainBlock } from "./Common/SwitchChainBlock";
import "./Menu/menu.css";

const DEFAULT_SUBROUTE = MARKET_LANDS_SUBROUTE;
const DEFAULT_SUBROUTE_INDEX = 0;

const Market = () => {
    const { pathname } = useLocation();
    const { isConnected, address, chain } = useAccount();
    const [warningMessage, setWarningMessage] = useState<any | undefined>(CONNECTING_TEXT);
    const [contractAddress, setContractAddress] = useState<string>();
    const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);
    const [intersected, setIntersected] = useState<boolean>(false);
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(-1);
    const [currentTabRoute, setCurrentTabRoute] = useState<string>("");

    const {
        getNfts,
        isLoading,
        fetchSuccessful,
        fetchedNfts,
        cursor,
        hasMore,
        totalNfts
    } = useNfts(contractAddress!, false);

    useEffect(() => {
        if (pathname.includes(MARKET_LANDS_SUBROUTE)) {
            setCurrentTabIndex(0);
            setCurrentTabRoute(MARKET_LANDS_SUBROUTE);
        } else if (pathname.includes(MARKET_BUILDINGS_SUBROUTE)) {
            setCurrentTabIndex(1);
            setCurrentTabRoute(MARKET_BUILDINGS_SUBROUTE);
        } else if (pathname.includes(MARKET_UNIQUE_SUBROUTE)) {
            setCurrentTabIndex(2);
            setCurrentTabRoute(MARKET_UNIQUE_SUBROUTE);
        } else if (pathname.includes(MARKET_TOWN_EFFECTS_SUBROUTE)) {
            setCurrentTabIndex(3);
            setCurrentTabRoute(MARKET_TOWN_EFFECTS_SUBROUTE);
        } else {
            setCurrentTabIndex(DEFAULT_SUBROUTE_INDEX);
            setCurrentTabRoute(DEFAULT_SUBROUTE);
        }
    }, [pathname]);

    // consider showing collection from mainnet if user not authenricated
    useEffect(() => {
        const currentContractAddress = getContractAddress(isConnected, chain, resolveMarketContractAddressKey(currentTabIndex));
        if (currentContractAddress) {
            setWarningMessage(undefined);
            setContractAddress(currentContractAddress);
        } else if (isConnected) {
            setWarningMessage(<SwitchChainBlock />);
            setContractAddress(undefined);
        }
    }, [isConnected, chain, currentTabIndex]);

    useEffect(() => {
        if (contractAddress) {
            getNfts();
        }
    }, [contractAddress, chain, isConnected, address]);

    const observer = useRef(
        new IntersectionObserver(
            (entries) => {
                const first = entries[0];

                if (first.isIntersecting) {
                    setIntersected(true);
                }
            })
    );

    useEffect(() => {
        if (intersected && fetchSuccessful && hasMore) {
            getNfts(cursor);
        }

        return () => {
            setIntersected(false);
        }
    }, [intersected]);

    useEffect(() => {
        if (!fetchSuccessful) {
            return;
        }

        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    if (warningMessage) {
        return (
            <Alert message={warningMessage} type="info" showIcon />
        );
    }

    return (
        <>
            <div>
                <NftPageTitle title="Market" subTitle={resolveMarketNftsAmountSubTitle(currentTabIndex)} isLoading={isLoading} totalNfts={totalNfts} />
                <NftPageSubTabs currentTabIndex={currentTabIndex} subTabs={
                    [
                        { tabKey: "land", tabDisplayName: "Lands", tabSubRoute: MARKET_LANDS_SUBROUTE },
                        { tabKey: "building", tabDisplayName: "Buildings", tabSubRoute: MARKET_BUILDINGS_SUBROUTE },
                        { tabKey: "unique", tabDisplayName: "Unique", tabSubRoute: MARKET_UNIQUE_SUBROUTE },
                        { tabKey: "town-effects", tabDisplayName: "Town Effects", tabSubRoute: MARKET_TOWN_EFFECTS_SUBROUTE },
                    ]
                } />
                <NftList isLoading={isLoading} fetchSuccessful={fetchSuccessful}
                    currentTabRoute={currentTabRoute} fetchedNfts={fetchedNfts}
                    setLastElement={setLastElement} showPrices />
            </div>
        </>
    );
}

export default Market;
