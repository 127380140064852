import React, { useEffect, useState } from "react";
import { Button, Tooltip } from "antd";
import { SendOutlined } from "@ant-design/icons";
import { TransferModal } from "./Nfts/TransferModal";
import { useAccount } from "wagmi";
import { Nft } from "../helpers/nfts";

export interface TransferButtonProps {
    index: number | string;
    nft: Nft | undefined;
}

export const TransferButton = (props: TransferButtonProps) => {
    const { isConnected, address, chain } = useAccount();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        setIsModalOpen(false);
    }, [address, chain, isConnected]);

    return (
        <div key={props.index + "-transfer"}>
            <TransferModal
                index={props.index}
                nft={props.nft!}
                isOpen={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onOperationFinished={() => setIsModalOpen(false)}
            />
            <Tooltip title="Transfer NFT">
                <Button key={props.index}
                    className="tl-nft-modal-action-button"
                    size="large" onClick={() => setIsModalOpen(true)}>
                    <SendOutlined style={{
                        fontSize: "20px",
                        marginTop: "4px",
                        marginLeft: "2px",
                        textAlign: "center"
                    }} />
                </Button>
            </Tooltip>
        </div>
    );
}