import React from "react";
import { Tooltip } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { useNativeAccountBalance, useTlcBalance } from "../../hooks/useBalance";

const styles = {
    nativeBalanceTitle: {
        color: "grey",
    } as React.CSSProperties,
    tlcBalanceTitle: {
        color: "grey",
        marginTop: "10px",
    } as React.CSSProperties,
    tlcHelpIcon: {
        marginLeft: "4px",
    } as React.CSSProperties,
} as const;

export const NativeBalance = () => {
    const { balance, fetchError, currencySymbol, tokenIcon, tokenIconSize } = useNativeAccountBalance();

    return (
        <Balance
            title={
                <span style={styles.nativeBalanceTitle}>
                    Your balance in native blockchain currency
                </span>
            }
            balance={balance}
            fetchError={fetchError}
            currencySymbol={currencySymbol}
            tokenIcon={tokenIcon}
            iconSize={tokenIconSize} />
    );
}

export const TlcBalance = () => {
    const { balance, fetchError, currencySymbol, tokenIcon, tokenIconSize } = useTlcBalance();

    return (
        <Balance
            title={
                <span style={styles.tlcBalanceTitle}>
                    Your TownsLand Coin balance
                    <Tooltip title="Earn TLC for expading your towns and spend it on unique items. More buildings - more coins! The more prosperous your town - the more coins you get!">
                        <QuestionCircleOutlined style={styles.tlcHelpIcon} />
                    </Tooltip>
                </span>
            }
            balance={balance}
            fetchError={fetchError}
            currencySymbol={currencySymbol}
            tokenIcon={tokenIcon}
            iconSize={tokenIconSize} />
    );
}

interface BalanceProps {
    title: JSX.Element;
    balance: string;
    fetchError: string | undefined;
    currencySymbol: string;
    tokenIcon: string;
    iconSize: number;
}

const Balance = (props: BalanceProps) => {
    const { title, balance, fetchError, currencySymbol, tokenIcon, iconSize } = props;

    if (!balance || fetchError) {
        return null;
    }

    return (
        <>
            {title}
            <Tooltip title={currencySymbol} placement="left">
                <div className="tl-balance-container">
                    {tokenIcon &&
                        <img
                            src={tokenIcon}
                            className="tl-balance-icon noselect"
                            width={iconSize}
                            height={iconSize}
                        />
                    }
                    <span className="tl-balance-amount noselect">
                        {balance}
                    </span>
                </div>
            </Tooltip>
        </>
    );
}
