import { useAccount, useReadContract } from "wagmi";
import isBuildInProgressAbi from "../../data/town_isBuildInProgress_abi.json";
import { TOWN_CONTRACT_ADDRESS_KEY, getContractAddress } from "../../helpers/networks";

const ISBUILDINPROGRESS_FUNCTION_NAME = "isBuildInProgress";

export const useTownIsBuildInProgress = (enabled: boolean, tokenId: string | undefined) => {
    const { isConnected, address, chain } = useAccount();
    const { data, error } = useReadContract({
        query: {
            enabled: tokenId!== undefined && enabled,
        },
        functionName: ISBUILDINPROGRESS_FUNCTION_NAME,
        abi: [isBuildInProgressAbi],
        address: getContractAddress(isConnected, chain, TOWN_CONTRACT_ADDRESS_KEY),
        args: [address, tokenId]
    });

    return {
        isTownBuildInProgress: data ? (data as boolean) : undefined,
        isBuildInProgressError: error
    }
}