import React, { useEffect, useState } from "react";
import { Modal, Button, Tooltip, InputNumber, Image } from "antd";
import { useUniqueBuildingBuyForTlc } from "../../hooks/contracts/useUniqueBuildingBuyForTlc";
import { useNftFreeBalanceOf } from "../../hooks/contracts/useNftFreeBalanceOf";
import { useTlcBalance, LOADING_CURRENCY_VALUE_PLACEHOLDER } from "../../hooks/useBalance";
import { NFT_NOT_ON_SALE_MESSAGE, NOT_ENOUGH_TLC_MESSAGE, TOWNSLAND_MAIN_WALLET } from "../../helpers/constants";
import NoImageIcon from "../../img/no-image.png";
import { useErc1155GetPrice } from "../../hooks/contracts/useErc1155GetPrice";
import { Nft, TlNftType } from "../../helpers/nfts";

export interface BuyForTlcModalProps {
    index: number | string;
    nft: Nft;
    isOpen: boolean;
    onCancel?: () => void | undefined;
    onOperationFinished?: () => void | undefined;
}

export const BuyForTlcModal = (props: BuyForTlcModalProps) => {
    const nft = props.nft;

    const { refetchPrice, price: tlcPrice, isOnSale, getPriceError } = useErc1155GetPrice(
        props.isOpen && nft.tlNftType === TlNftType.UniqueBuilding, nft.token_id, nft.tlNftType);
    const { fetchBalance, balance, fetchError, currencySymbol, tokenIcon, tokenIconSize } = useTlcBalance();
    const {
        refetchNftFreeBalance: refetchUniqueBuildingsFreeBalance,
        freeAmount: freeUniqueBuildingsAmount,
        freeAmountError: freeUniqueBuildingsError } = useNftFreeBalanceOf(
            nft.tlNftType === TlNftType.UniqueBuilding && tlcPrice !== undefined,
            nft.token_id,
            TOWNSLAND_MAIN_WALLET,
            TlNftType.UniqueBuilding);
    const [amountToBuy, setAmountToBuy] = useState<number | undefined>(1);

    const isPurchasePossible = (): boolean => {
        const userBalance = balance === LOADING_CURRENCY_VALUE_PLACEHOLDER ? undefined : Number(balance);
        const requestedAmount = amountToBuy === undefined ? undefined : amountToBuy!;
        const totalAmount = freeUniqueBuildingsAmount === undefined ? undefined : Number(freeUniqueBuildingsAmount);
        if (userBalance === undefined || requestedAmount === undefined || totalAmount === undefined
            || tlcPrice === undefined || isOnSale !== true) {
            return false;
        }

        if (requestedAmount > totalAmount) {
            return false;
        }

        return userBalance - requestedAmount * tlcPrice >= 0;
    }

    const {
        buyForTlc,
        isBuyForTlcPrepared,
        isBuyForTlcInProgress,
        buyForTlcPreparationError
    } = useUniqueBuildingBuyForTlc(
        nft.tlNftType === TlNftType.UniqueBuilding && isPurchasePossible(),
        nft.token_id,
        amountToBuy);

    const isBuyButtonLoading = (): boolean => {
        return !isBuyForTlcPrepared && !buyForTlcPreparationError && !freeUniqueBuildingsAmount && balance != LOADING_CURRENCY_VALUE_PLACEHOLDER;
    }

    const resolveTooltipTitle = () => {
        if (amountToBuy === undefined) {
            return "Amount is empty";
        }
        else if (buyForTlcPreparationError !== undefined) {
            return buyForTlcPreparationError;
        }
        else if (isOnSale === false) {
            return NFT_NOT_ON_SALE_MESSAGE;
        }
        else if (!isPurchasePossible()) {
            return NOT_ENOUGH_TLC_MESSAGE;
        }
        else {
            return "";
        }
    }

    useEffect(() => {
        if (props.isOpen) {
            refetchPrice();
            refetchUniqueBuildingsFreeBalance();
            fetchBalance();
        }
    }, [props.isOpen]);

    return (
        <Modal
            className="tl-send-or-buy-nft-modal close-icon-hidden"
            closeIcon={null}
            centered={true}
            style={{ minWidth: "560px", marginTop: "-100px" }}
            maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.65)" }}
            open={props.isOpen}
            onCancel={props.onCancel}
            bodyStyle={{ paddingBottom: "10px", textAlign: "left" }}
            footer={[
                <Button key={`cancel-unique-purchase${props.index}`} onClick={props.onCancel}>Cancel</Button>,
                <Tooltip key={`unique-purchase${props.index}`} placement="bottom" title={resolveTooltipTitle()}>
                    <Button
                        key={props.index}
                        type="primary"
                        loading={isBuyButtonLoading()}
                        disabled={!isPurchasePossible() || buyForTlcPreparationError !== undefined || isBuyForTlcInProgress}
                        className="tl-button-loading"
                        style={{ justifyContent: "center", marginLeft: "8px" }}
                        onClick={async () => { await buyForTlc(() => props.onOperationFinished?.()); }}>
                        <div style={{ display: "flex" }}>
                            <span style={{ paddingRight: "6px" }}>Buy for {calculateTotalPrice(tlcPrice!, amountToBuy) ?? "..."}</span>
                            <img src={tokenIcon} className="tl-balance-icon" width={20} height={20} style={{ marginRight: "0px" }} />
                        </div>
                    </Button>
                </Tooltip>
            ]}
        >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Tooltip title={nft.name} trigger="click" showArrow={false} placement="bottom">
                    <div style={{ marginRight: "24px", cursor: "pointer" }}>
                        <Image
                            className="tl-send-or-buy-nft-image-clickable"
                            key={nft.token_id}
                            preview={false}
                            src={nft.preview_image}
                            fallback={NoImageIcon}
                            alt=""
                            style={{ marginRight: "24px", width: "160px" }}
                            width="160px"
                        />
                    </div>
                </Tooltip>
                <div>
                    <div>
                        <span style={{ fontSize: "16px" }}>How much do you want to purchase?</span>
                    </div>
                    <InputNumber
                        key={props.index}
                        min={1}
                        max={freeUniqueBuildingsAmount ? Number(freeUniqueBuildingsAmount) : 99}
                        defaultValue={1}
                        precision={0}
                        style={{ marginTop: "12px", width: "100%", minWidth: "100px" }}
                        onChange={(n) => { setAmountToBuy(n === null ? undefined : n) }}></InputNumber>
                    <div style={{ color: "var(--tl-text-color)", display: "flex", fontSize: "14px", justifyContent: "right", marginTop: "50px" }}>
                        <span style={{ marginRight: "12px", marginTop: "0.5px" }}>Your balance: </span>
                        <Tooltip title={currencySymbol} placement="right">
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                {tokenIcon &&
                                    <img
                                        src={tokenIcon}
                                        className="tl-balance-icon noselect"
                                        width={18}
                                        height={18}
                                    />
                                }
                                <span style={{ marginTop: "1.5px", fontWeight: "600" }}>
                                    {balance}
                                </span>
                            </div>
                        </Tooltip>
                    </div>
                    <div style={{ color: "var(--tl-text-color)", display: "flex", fontSize: "14px", justifyContent: "right" }}>
                        <span style={{ marginRight: "12px", marginTop: "0.5px" }}>Available amount: </span>
                        <span style={{ fontWeight: 600 }}>{freeUniqueBuildingsAmount ? Number(freeUniqueBuildingsAmount) : "..."}</span>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

const calculateTotalPrice = (pricePerEach: number, amountToBuy: number | undefined): number | null => {
    return amountToBuy ? Math.floor(pricePerEach * amountToBuy) : null;
}