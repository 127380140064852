import React, { useRef, useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate
} from "react-router-dom";
import { Layout, Tooltip } from "antd";
import Text from "antd/lib/typography/Text";
import MenuItems from "./components/Menu/MenuItems";
import Account from './components/Account/Account';
import { defaultRoute, MARKET_BUILDINGS_SUBROUTE, MARKET_ROUTE, MY_PROPERTY_ROUTE, MY_PROPERTY_TOWNS_SUBROUTE } from './helpers/routes';
import { menuRouteKeys, menuRoutes } from './helpers/menuRoutes';
import Logo from './components/Logo';
import useScrollListener from './hooks/useScrollListener';
import NotFound from './components/NotFound';
import { CONTENT_WIDTH, DISCORD_LINK, EMAIL_ADDRESS } from './helpers/constants';
import DiscordIcon from "./img/discord.svg";
import "antd/dist/antd.dark.css";
import "./style.css";
import DynamicBalance from './components/DynamicBalance';

const { Header, Footer } = Layout;

const COPY_EMAIL_TOOLTIP_TEXT = "Copy Email";
const COPIED_EMAIL_TOOLTIP_TEXT = "Copied!";

const styles = {
  headerRight: {
    marginRight: "8px",
    gap: "20px",
    alignItems: "center",
    fontSize: "15px",
    fontWeight: "600",
    justifyContent: "flex-end",
    paddingLeft: "10px",
  } as React.CSSProperties,
  content: {
    display: "flex",
    justifyContent: "center",
    fontFamily: "var(--tl-font-family)",
    color: "#041836",
    marginTop: "110px",
    padding: "10px",
  } as React.CSSProperties,
  footer: {
    textAlign: "center",
    fontFamily: "var(--tl-font-family)",
    marginTop: "auto",
    background: "none",
    padding: 0,
    maxHeight: "var(--tl-footer-height)",
    maxWidth: CONTENT_WIDTH,
    width: "100%",
    marginRight: "auto",
    marginLeft: "auto"
  } as React.CSSProperties,
} as const;

const App = () => {
  const layoutRef = useRef(null);
  const headerRef = useRef(null);
  const [copyEmailTooltipText, setCopyEmailTooltipText] = useState<string>("Copy Email");

  const handleScroll = () => {
    let layout = layoutRef.current;
    let header = headerRef.current as any;
    if (layout && header && (layout as any).scrollTop > 80) {
      header.classList.add("scrolled");
    } else if (header) {
      header.classList.remove("scrolled");
    }
  }

  useScrollListener(layoutRef, handleScroll);

  let routeElements: JSX.Element[] = menuRouteKeys.map(r => menuRoutes[r].routeElement);

  return (
    <Layout ref={layoutRef} className="tl-layout">
      <Router>
        <Header ref={headerRef} className="tl-header">
          <Logo className="first header-section" />
          <MenuItems className="header-section" />
          <div className="last header-section" style={styles.headerRight}>
            <DynamicBalance />
            <Account />
          </div>
        </Header>

        <div style={styles.content}>
          <Routes>
            {routeElements}
            <Route path={MY_PROPERTY_ROUTE} element={<Navigate to={MY_PROPERTY_TOWNS_SUBROUTE} replace />} />
            <Route path={MARKET_ROUTE} element={<Navigate to={MARKET_BUILDINGS_SUBROUTE} replace />} />
            <Route path="/" element={<Navigate to={defaultRoute} replace />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </div>
      </Router>

      <Footer style={styles.footer}>
        <Text className="tl-footer-text">
          © TownsLand 2024
          <span className="ant-typography" style={{ marginLeft: "30px", marginRight: "12px" }}>Social:</span>
          <a className="tl-social-link" target="_blank" rel="noreferrer" href={DISCORD_LINK}>
            <div style={{ width: "24px", height: "24px", display: "initial", marginTop: "200px" }}>
              <img className="tl-discord-icon" src={DiscordIcon} alt="" />
            </div>
            <span style={{ marginLeft: "4px" }}>Discord</span>
          </a>
          <span className="ant-typography" style={{ marginLeft: "30px", marginRight: "12px" }}>Mail:</span>
          <Tooltip title={copyEmailTooltipText} placement="right">
            <a className="tl-social-link" rel="noreferrer"
              onClick={() => {
                navigator.clipboard.writeText(EMAIL_ADDRESS);
                setCopyEmailTooltipText(COPIED_EMAIL_TOOLTIP_TEXT);
              }}
              onMouseLeave={() => setTimeout(() => setCopyEmailTooltipText(COPY_EMAIL_TOOLTIP_TEXT), 1000)}>
              <span style={{ marginLeft: "4px" }}>{EMAIL_ADDRESS}</span>
            </a>
          </Tooltip>
        </Text>
      </Footer>
    </Layout>
  );
}

export default App;
