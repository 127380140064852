import React from "react";
import { Skeleton, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { getEllipsisText } from "../helpers/formatters";
import Blockie from "./Blockie/Blockie";
import { useAccount } from "wagmi";

const styles = {
    address: {
        height: "36px",
        display: "flex",
        gap: "5px",
        backgroundColor: "rgba(29, 31, 32, 0.1)",
        borderRadius: "9px",
        alignItems: "center",
    } as React.CSSProperties,
};

interface AddressProps {
    address?: string;
    style?: React.CSSProperties;
    avatar: string;
    copyable: boolean;
    size?: number;
}

const Address = (props: AddressProps) => {
    const { isConnected, address } = useAccount();
    const [connectedAddress, setConnectedAddress] = useState<string>();
    const [isCopyClicked, setCopyIsClicked] = useState<boolean>(false);

    useEffect(() => {
        if (props.address != null) {
            setConnectedAddress(props.address);
        } else if (isConnected && address) {
            setConnectedAddress(address);
        }
        else {
            setConnectedAddress(undefined);
        }
    }, [isConnected, address, props]);

    if (!connectedAddress) {
        return (
            <Skeleton paragraph={{ rows: 1, width: "100%" }} title={false} active />
        );
    }

    return (
        <div style={{ ...styles.address, ...props.style }}>
            {props.avatar === "left" && <Blockie address={connectedAddress} size={7} />}
            <p>{props.size ? getEllipsisText(connectedAddress, props.size) : connectedAddress}</p>
            {props.avatar === "right" && <Blockie address={connectedAddress} size={7} />}
            {props.copyable && (isCopyClicked ? <Check /> : <Copy address={connectedAddress} setCopyIsClicked={setCopyIsClicked} />)}
        </div>
    );
}

interface CopyProps {
    address: string;
    setCopyIsClicked: React.Dispatch<React.SetStateAction<boolean>>;
}

const Copy = (props: CopyProps) => {
    return (
        <Tooltip title="Copy Address">
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                viewBox="0 0 24 24"
                strokeWidth="2"
                stroke="var(--tl-accent-color)"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                style={{ cursor: "pointer" }}
                onClick={() => {
                    navigator.clipboard.writeText(props.address);
                    props.setCopyIsClicked(true);
                }}
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M15 3v4a1 1 0 0 0 1 1h4" />
                <path d="M18 17h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h4l5 5v7a2 2 0 0 1 -2 2z" />
                <path d="M16 17v2a2 2 0 0 1 -2 2h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h2" />
            </svg>
        </Tooltip>
    );
}

const Check = () => {
    return (
        <Tooltip title="Copied!">
            <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                strokeWidth="3"
                stroke="#21BF96"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M5 12l5 5l10 -10" />
            </svg>
        </Tooltip>
    );
}

export default Address;