import React, { useState } from "react";
import { Nft } from "../helpers/nfts";

export interface INftContext {
    nftToDisplay: Nft | undefined;
    setNftToDisplay: React.Dispatch<React.SetStateAction<Nft | undefined>>;
}

export const NftContext = React.createContext<INftContext>({} as INftContext);

export const NftContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [nftToDisplay, setNftToDisplay] = useState<Nft>();

    return (
        <NftContext.Provider value={{ nftToDisplay, setNftToDisplay }}>
            {children}
        </NftContext.Provider>
    )
}