import React, { useEffect, useState } from "react";
import { Modal, Button, Tooltip, InputNumber, Image, Input } from "antd";
import { useNftFreeBalanceOf } from "../../hooks/contracts/useNftFreeBalanceOf";
import { NOT_ENOUGH_NFT_TO_TRANSFER_MESSAGE, TARGET_ACCOUNT_IS_EQUAL_TO_CURRENT_MESSAGE, WRONG_TARGET_ACCOUNT_MESSAGE } from "../../helpers/constants";
import { useAccount } from "wagmi";
import { useErc1155SafeTransferFrom, useErc721SafeTransferFrom } from "../../hooks/contracts/useNftSafeTransferFrom";
import NoImageIcon from "../../img/no-image.png";
import { Nft, isErc721 } from "../../helpers/nfts";
import { isAddress } from "viem";

export interface TransferModalProps {
    index: number | string;
    nft: Nft;
    isOpen: boolean;
    onCancel?: () => void | undefined;
    onOperationFinished?: () => void | undefined;
}

export const TransferModal = (props: TransferModalProps) => {
    const nft = props.nft;
    const { isConnected, address } = useAccount();
    const [transferAmount, setTransferAmount] = useState<number | undefined>(1);
    const [toAddress, setToAddress] = useState<string | undefined>();
    const [showErrorInAccountInput, setShowErrorInAccountInput] = useState<boolean>(false);

    const { refetchNftFreeBalance, freeAmount, freeAmountError } = useNftFreeBalanceOf(isConnected, nft.token_id, address, nft.tlNftType);

    const {
        safeTransferFrom: erc721SafeTransferFrom,
        isSafeTransferInProgress: isErc721SafeTransferInProgress,
        isSafeTransferPrepared: isErc721SafeTransferPrepared,
        prepareError: erc721TransferPrepareError
    } = useErc721SafeTransferFrom(
        isConnected, nft.token_id, toAddress, nft.tlNftType);
    const {
        safeTransferFrom: erc1155SafeTransferFrom,
        isSafeTransferInProgress: isErc1155SafeTransferInProgress,
        isSafeTransferPrepared: isErc1155SafeTransferPrepared,
        prepareError: erc1155TransferPrepareError
    } = useErc1155SafeTransferFrom(
        isConnected, nft.token_id, transferAmount, toAddress, nft.tlNftType);

    const resolveTransferData = () => {
        return isErc721(nft.tlNftType)
            ? {
                safeTransferFrom: erc721SafeTransferFrom,
                isSafeTransferPrepared: isErc721SafeTransferPrepared,
                isSafeTransferInProgress: isErc721SafeTransferInProgress,
                prepareTransferError: erc721TransferPrepareError
            }
            : {
                safeTransferFrom: erc1155SafeTransferFrom,
                isSafeTransferPrepared: isErc1155SafeTransferPrepared,
                isSafeTransferInProgress: isErc1155SafeTransferInProgress,
                prepareTransferError: erc1155TransferPrepareError
            }
    }

    const { safeTransferFrom, isSafeTransferPrepared, isSafeTransferInProgress, prepareTransferError } = resolveTransferData();

    const isEnoughAmount = (): boolean => {
        const totalAmount = freeAmount;
        if (transferAmount === undefined || totalAmount === undefined) {
            return false;
        }

        return totalAmount >= transferAmount;
    }

    const isToAccountValid = (): boolean => {
        return toAddress
            ? isAddress(toAddress) && address !== undefined && toAddress.toUpperCase() !== address.toUpperCase()
            : false;
    }

    const isTransferPossible = (): boolean => {
        return isEnoughAmount() && isToAccountValid();
    }

    const isTransferButtonLoading = (): boolean => {
        return isSafeTransferPrepared === false && prepareTransferError === undefined && freeAmountError === undefined;
    }

    const resolveTooltipTitle = () => {
        if (transferAmount === undefined) {
            return "Amount is empty";
        }
        else if (prepareTransferError !== undefined) {
            return prepareTransferError;
        }
        else if (!isEnoughAmount()) {
            return NOT_ENOUGH_NFT_TO_TRANSFER_MESSAGE;
        }
        else if (address !== undefined && toAddress != undefined && toAddress.toUpperCase() === address.toUpperCase()) {
            return TARGET_ACCOUNT_IS_EQUAL_TO_CURRENT_MESSAGE;
        }
        else if (!isToAccountValid()) {
            return WRONG_TARGET_ACCOUNT_MESSAGE;
        }
        else {
            return "";
        }
    }

    useEffect(() => {
        if (props.isOpen) {
            refetchNftFreeBalance();
        }
    }, [props.isOpen]);

    return (
        <Modal
            className="tl-send-or-buy-nft-modal close-icon-hidden"
            closeIcon={null}
            centered={true}
            style={{ minWidth: "740px", marginTop: "-100px" }}
            maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.65)" }}
            open={props.isOpen}
            onCancel={props.onCancel}
            bodyStyle={{ paddingBottom: "10px", textAlign: "left" }}
            footer={[
                <div key="nfts-amount"
                    style={{
                        color: "var(--tl-text-color)",
                        display: "flex",
                        fontSize: "14px",
                        justifyContent: "center",
                        marginRight: "auto",
                        width: "200px"
                    }}>
                    <span style={{ marginRight: "12px", marginTop: "auto", marginBottom: "auto" }}>Available amount: </span>
                    <span style={{ fontWeight: 600, marginTop: "auto", marginBottom: "auto" }}>{freeAmount !== undefined ? freeAmount : "..."}</span>
                </div>,
                <Button key={`cancel-transfer${props.index}`} onClick={props.onCancel}>Cancel</Button>,
                <Tooltip key={`transfer${props.index}`} placement="bottom" title={resolveTooltipTitle()}>
                    <Button
                        key={props.index}
                        type="primary"
                        loading={isTransferButtonLoading()}
                        disabled={!isTransferPossible() || prepareTransferError !== undefined || isSafeTransferInProgress}
                        className="tl-button-loading"
                        style={{ justifyContent: "center", marginLeft: "8px", transition: "all .3s ease" }}
                        onClick={async () => { await safeTransferFrom(() => props.onOperationFinished?.()); }}>
                        Transfer
                    </Button>
                </Tooltip>
            ]}
        >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Tooltip title={nft.name} trigger="click" showArrow={false} placement="top">
                    <div style={{ marginRight: "24px", cursor: "pointer" }}>
                        <Image
                            className="tl-send-or-buy-nft-image-clickable"
                            key={"amountInput" + nft.token_id}
                            preview={false}
                            src={nft.preview_image}
                            fallback={NoImageIcon}
                            alt=""
                            style={{ marginRight: "24px", width: "200px", borderRadius: "1rem" }}
                            width="200px"
                        />
                    </div>
                </Tooltip>
                <div>
                    <div>
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>How much do you want to transfer?</span>
                    </div>
                    <InputNumber
                        key={"toAddressInput" + props.index}
                        min={1}
                        max={freeAmount ? freeAmount : 99}
                        defaultValue={1}
                        precision={0}
                        style={{ marginTop: "4px", width: "100%", minWidth: "100px" }}
                        onChange={(n) => { setTransferAmount(n === null ? undefined : n) }}></InputNumber>
                    <div style={{ marginTop: "20px" }}>
                        <span style={{ fontSize: "16px", fontWeight: "600" }}>Target account address:</span>
                    </div>
                    <Tooltip placement="bottomLeft" open={showErrorInAccountInput && !isToAccountValid()} title={resolveTooltipTitle()}>
                        <Input
                            key={props.index}
                            status={showErrorInAccountInput && !isToAccountValid() ? "error" : undefined}
                            style={{ marginTop: "4px", width: "100%", minWidth: "460px", borderRadius: "0.5rem" }}
                            onChange={(n) => {
                                setToAddress(n.target.value);
                                setShowErrorInAccountInput(true);
                            }}
                        >
                        </Input>
                    </Tooltip>
                </div>
            </div>
        </Modal>
    );
}
