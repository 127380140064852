import React from "react";
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

const styles = {
    container: {
        marginTop: "120px",
    } as React.CSSProperties,
    spin: {
        color: "var(--tl-accent-color)",
        fontWeight: "600",
        fontSize: "20px",
    } as React.CSSProperties,
    indicator: {
        fontSize: "24px",
    } as React.CSSProperties,
    title: {
        fontWeight: "600",
        fontSize: "18px",
        color: "rgba(255, 255, 255, 0.45)",
        marginLeft: "14px",
    } as React.CSSProperties,
} as const;

interface LoadingItemsSpinProps {
    customTitle?: string;
    hideSpin?: boolean;
}

export const LoadingItemsSpin = (props: LoadingItemsSpinProps) => {
    return (
        <div style={styles.container}>
            {props.hideSpin !== true &&
                <Spin style={styles.spin} indicator={<LoadingOutlined style={styles.indicator} spin />} />
            }
            <span style={styles.title}>{props.customTitle ? props.customTitle : "Loading..."}</span>
        </div>
    );
}
