import { useEffect, useState } from "react";
import {
    BUILDING_CONTRACT_ADDRESS_KEY,
    LAND_CONTRACT_ADDRESS_KEY,
    TOWN_CONTRACT_ADDRESS_KEY,
    TOWN_EFFECT_CONTRACT_ADDRESS_KEY,
    UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY,
    getTlChainId,
    getContractAddress,
    isChainSupported,
    isNftContractValid
} from "../helpers/networks";
import { useAccount } from "wagmi";
import { useAlchemy } from "./useAlchemy";
import { Alchemy } from "alchemy-sdk";
import { Nft, TlNftType } from "../helpers/nfts";
import { Chain } from "viem";

export type NftFetchError = {
    message: string;
    isKnownError: boolean;
}

export const useNfts = (nftContractAddress: string, getNftsForCurrentUser: boolean | undefined = false, default_batch_size: number = 999,
    isEnabled: boolean = true
) => {

    const { isConnected, address, chain } = useAccount();
    const [totalNfts, setTotalNfts] = useState<number>(0);
    const [fetchSuccessful, setFetchSuccessful] = useState<boolean>();
    const [fetchedNfts, setFetchedNfts] = useState<Array<Nft>>([]);
    const [cursor, setCursor] = useState<string>("");
    const [hasMore, setHasMore] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(true);

    const { alchemy } = useAlchemy(chain);

    const getAllNfts = (cursor: string, limit: number) => {
        return alchemy?.nft.getNftsForContract(nftContractAddress,
            {
                pageKey: cursor,
                pageSize: limit
            });
    }

    const getCurrentUserNfts = (cursor: string, limit: number) => {
        return alchemy?.nft.getNftsForOwner(address ?? "",
            {
                contractAddresses: [nftContractAddress],
                pageKey: cursor,
                pageSize: limit
            });
    }

    const buildNftsRequest = () => {
        return getNftsForCurrentUser ? getCurrentUserNfts : getAllNfts;
    }

    const getNfts = async (cursor: string = "", limit: number = default_batch_size) => {
        if (!isEnabled) {
            return;
        }

        setIsLoading(true);
        const request = buildNftsRequest();

        return await request(cursor, limit)!
            .then(async (nftsResponse) => {
                const nftsResponseGeneric = (nftsResponse as any);
                const nfts = getNftsForCurrentUser ? nftsResponseGeneric.ownedNfts as [] : nftsResponseGeneric.nfts as [];

                if (nfts) {
                    const total = nftsResponseGeneric.totalCount ? nftsResponseGeneric.totalCount as number : nfts.length;
                    // const total = 3;
                    setTotalNfts(total);
                    setFetchSuccessful(true);

                    const tlNftType = resolveTlNftType(isConnected, chain, nftContractAddress);

                    const filledNfts: Array<Nft> = [];
                    for (let nft of nfts) {
                        const { success, error, mappedNft } = await tryMapNft(nft, nftContractAddress, tlNftType, getNftsForCurrentUser, address);
                        if (!success) {
                            setFetchSuccessful(false);
                            return;
                        }

                        filledNfts.push(mappedNft!);
                    }

                    setFetchedNfts((prevFetched) => {
                        let clearedPrevNfts = prevFetched.filter(n => n.token_address === nftContractAddress);
                        const fetchedRecords = cursor === "" ? [...filledNfts] : [...clearedPrevNfts, ...filledNfts];
                        setHasMore(total - fetchedRecords.length > 0);
                        // temp sorting by token_id of string type. will only work when full page is loaded without pagination.
                        // replace with int-based sorting later
                        return fetchedRecords.sort((a, b) => a.token_id.localeCompare(b.token_id));
                    });

                    setCursor(nftsResponseGeneric.pageKey);
                } else {
                    setFetchSuccessful(false);
                }
            })
            .catch((e) => {
                console.log(e);
                console.log("Error when trying to fetch NFTs");
                setFetchSuccessful(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const resetFetchedData = () => {
        setTotalNfts(0);
        setFetchSuccessful(undefined);
        setFetchedNfts([]);
        setHasMore(undefined);
        setIsLoading(true);
    }

    const refetchDataDeps: any = [isConnected, nftContractAddress, address];
    // if (getNftsForCurrentUser && isConnected && address) {
    //     refetchDataDeps.push(address);
    // }

    useEffect(() => {
        resetFetchedData();
    }, refetchDataDeps);

    return {
        isInitialized: alchemy !== undefined,
        getNfts,
        resetFetchedData,
        isLoading,
        fetchSuccessful,
        fetchedNfts,
        cursor,
        hasMore,
        totalNfts,
    };
}

export interface OwnedNftsHook {
    isInitialized: boolean;
    getNfts: (cursor?: string, limit?: number) => Promise<void>
    resetFetchedData: () => void;
    isLoading: boolean;
    fetchSuccessful: boolean | undefined;
    fetchedNfts: Nft[];
    cursor: string;
    hasMore: boolean | undefined;
    totalNfts: number;
}

export const useOwnedNfts = (nftContractAddresses: string[], default_batch_size: number = 999): OwnedNftsHook => {

    const { isConnected, address, chain } = useAccount();
    const [totalNfts, setTotalNfts] = useState<number>(0);
    const [fetchSuccessful, setFetchSuccessful] = useState<boolean>();
    const [fetchedNfts, setFetchedNfts] = useState<Array<Nft>>([]);
    const [cursor, setCursor] = useState<string>("");
    const [hasMore, setHasMore] = useState<boolean>();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [session, setSession] = useState<string>("");

    const { alchemy } = useAlchemy(chain);

    const getCurrentUserNfts = (cursor: string, limit: number) => {
        return alchemy!.nft.getNftsForOwner(address ?? "",
            {
                contractAddresses: nftContractAddresses,
                pageKey: cursor,
                pageSize: limit
            });
    }

    const getNfts = async (cursor: string = "", limit: number = default_batch_size) => {
        setIsLoading(true);

        return await getCurrentUserNfts(cursor, limit)!
            .then(async (nftsResponse) => {
                const nftsResponseGeneric = (nftsResponse as any);
                const nfts = nftsResponseGeneric.ownedNfts as any[];

                if (nfts) {
                    const total = nftsResponseGeneric.totalCount ? nftsResponseGeneric.totalCount as number : nfts.length;
                    // const total = 3;
                    setTotalNfts(total);
                    setFetchSuccessful(true);

                    const filledNfts: Array<Nft> = [];
                    for (let nft of nfts) {
                        const tlNftType = resolveTlNftType(isConnected, chain, nft.contract.address);
                        const { success, error, mappedNft } = await tryMapNft(nft, nft.contract.address, tlNftType, true, address, session);
                        if (!success) {
                            console.log(error);
                            setFetchSuccessful(false);
                            return;
                        }

                        filledNfts.push(mappedNft!);
                    }

                    setFetchedNfts((prevFetched) => {
                        let clearedPrevNfts = prevFetched.filter(n => n.tlSession === session);
                        const fetchedRecords = cursor === "" ? [...filledNfts] : [...clearedPrevNfts, ...filledNfts];
                        setHasMore(total - fetchedRecords.length > 0);
                        // temp sorting by token_id of string type. will only work when full page is loaded without pagination.
                        // replace with int-based sorting later
                        return fetchedRecords.sort((a, b) => a.token_id.localeCompare(b.token_id));
                    });

                    setCursor(nftsResponseGeneric.pageKey);
                } else {
                    setFetchSuccessful(false);
                }
            })
            .catch(() => {
                console.log("Error when trying to fetch NFTs");
                setFetchSuccessful(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const resetFetchedData = () => {
        setTotalNfts(0);
        setFetchSuccessful(undefined);
        setFetchedNfts([]);
        setHasMore(undefined);
        setIsLoading(true);
    }

    useEffect(() => {
        if (nftContractAddresses) {
            setSession(crypto.randomUUID());
        }
        resetFetchedData();
    }, [isConnected, address, ...nftContractAddresses]);

    return {
        isInitialized: isConnected && alchemy !== undefined,
        getNfts: getNfts,
        resetFetchedData,
        isLoading,
        fetchSuccessful,
        fetchedNfts,
        cursor,
        hasMore,
        totalNfts,
    };
}

export const useNft = (contractAddress: string | undefined, token: string | undefined, forOwner?: string) => {
    const { isConnected, address, chain } = useAccount();
    const { alchemy } = useAlchemy(chain);
    const [nft, setNft] = useState<Nft | undefined>(undefined);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [fetchSuccessful, setFetchSuccessful] = useState<boolean>();
    const [error, setError] = useState<string | undefined>(undefined);

    useEffect(() => {
        if (!isConnected) {
            setNft(undefined);
            setIsLoading(false);
            setFetchSuccessful(undefined);
            setError(undefined);
        }
    }, [isConnected]);

    useEffect(() => {
        if (isLoading && alchemy) {
            getNft();
        }

    }, [alchemy, isLoading]);

    const getNft = async () => {
        if (!contractAddress || !token || !isConnected && forOwner) {
            setError("Something went wrong. Please try again later.");
            setFetchSuccessful(false);
            setIsLoading(false);
            return;
        }
        if (isConnected && !isChainSupported(chain)) {
            setError("Your chain is not supported. Please change it in your Wallet.");
            setFetchSuccessful(false);
            setIsLoading(false);
            return;
        }
        if (!isNftContractValid(contractAddress, isConnected, chain)) {
            setError("Wrong NFT contract address");
            setFetchSuccessful(false);
            setIsLoading(false);
            return;
        }

        setIsLoading(true);
        setFetchSuccessful(undefined);
        setError(undefined);

        await alchemy?.nft.getNftMetadata(contractAddress, token, {})
            .then(async (nftResponse) => {
                if (nftResponse && nftResponse.raw && !nftResponse.raw.error) {
                    const tlNftType = resolveTlNftType(isConnected, chain, contractAddress);
                    const { success, error, mappedNft } = await tryMapNft(nftResponse, contractAddress, tlNftType, false, address);
                    if (!success) {
                        setError(error);
                        setFetchSuccessful(false);
                        return;
                    }

                    if (forOwner) {
                        const { isOwner, error: checkOwnershipError } = await checkIfUserOwnsNft(alchemy, contractAddress, token, forOwner);
                        if (checkOwnershipError) {
                            setError(checkOwnershipError);
                            setFetchSuccessful(false);
                            return;
                        } else if (!isOwner) {
                            setError("You don't own this NFT");
                            setFetchSuccessful(false);
                            return;
                        }
                    }

                    setNft(mappedNft!);
                    setFetchSuccessful(true);
                } else if (nftResponse && nftResponse.raw && nftResponse.raw.error
                    && (nftResponse.raw.error.includes("Token does not exist")
                        || nftResponse.raw.error.includes("Token uri responded with a non 200 response code"))) {
                    setError("NFT not found");
                    setFetchSuccessful(false);
                } else {
                    setError("Something wrong with getting NFT data. Please try again later.");
                    setFetchSuccessful(false);
                }
            })
            .catch((error) => {
                console.log(error);
                setError("Unknown error occured when trying to load NFT.");
                setFetchSuccessful(false);
            })
            .finally(() => {
                setIsLoading(false);
            });
    }

    return {
        getNft,
        nft,
        isLoading,
        fetchSuccessful,
        error
    };
}

const checkIfUserOwnsNft = async (alchemy: Alchemy, contractAddress: string, token: string, forOwner: string)
    : Promise<{ isOwner?: boolean; error?: string; }> => {
    try {
        let ownersResponse = await alchemy.nft.getOwnersForNft(contractAddress!, token!);
        if (ownersResponse && ownersResponse.owners) {
            if (ownersResponse.owners.findIndex(o => o.toLowerCase() === forOwner.toLowerCase()) === -1) {
                return { isOwner: false };
            } else {
                return { isOwner: true };
            }
        }

        return { error: "Something wrong with getting NFT data. Please try again later." };
    }
    catch (e) {
        return { error: "Something wrong with getting NFT data. Please try again later." };
    }
}

const tryMapNft = async (
    nftResponse: any,
    nftContractAddress: string,
    tlNftType: TlNftType,
    getNftsForCurrentUser: boolean,
    ownerAddress: `0x${string}` | undefined,
    tlSession: string | undefined = undefined)
    : Promise<{ success: boolean; error?: string; mappedNft?: Nft; }> => {

    const filledNft: Nft = {} as Nft;
    filledNft.token_address = nftContractAddress.toLowerCase();
    filledNft.token_id = nftResponse.tokenId;
    filledNft.token_uri = nftResponse.tokenUri;
    filledNft.name = nftResponse.name;
    filledNft.amount = nftResponse.balance;
    filledNft.owner = getNftsForCurrentUser ? ownerAddress : undefined;
    filledNft.tlNftType = tlNftType;
    filledNft.tlSession = tlSession;

    if (nftResponse.raw && nftResponse.raw.metadata) {
        fillNftMetadata(filledNft, nftResponse.raw.metadata);
    } else if (nftResponse.raw && nftResponse.raw.tokenUri) {
        try {
            await fetch(nftResponse.raw.tokenUri)
                .then((response) => response.json())
                .then((data) => {
                    fillNftMetadata(filledNft, data);
                });
        } catch {
            const msg = `Error when trying to fetch metadata for NFT ${nftResponse.tokenId}`;
            console.log(msg);
            return { success: false, error: msg };
        }
    } else {
        return { success: false, error: "No metadata loaded for the requested NFT. Please try again later." };
    }

    return { success: true, mappedNft: filledNft };
}

const fillNftMetadata = (nftToFill: Nft, nftMetadataResponse: any) => {
    const image = nftMetadataResponse.image;
    const preview_image = nftMetadataResponse.preview_image;
    nftToFill.metadata = {
        name: nftMetadataResponse.name,
        description: nftMetadataResponse.description,
        image: image,
        preview_image: preview_image,
        attributes: nftMetadataResponse.attributes
    };

    nftToFill.image = image;
    nftToFill.preview_image = preview_image;
    return nftToFill;
}

const resolveTlNftType = (isConnected: boolean, chain: Chain | undefined, nftContractAddress: string) => {
    const contractAddress = nftContractAddress.toLowerCase();
    if (getContractAddress(isConnected, chain, LAND_CONTRACT_ADDRESS_KEY) === contractAddress) {
        return TlNftType.Land;
    } else if (getContractAddress(isConnected, chain, TOWN_CONTRACT_ADDRESS_KEY) === contractAddress) {
        return TlNftType.Town;
    } else if (getContractAddress(isConnected, chain, BUILDING_CONTRACT_ADDRESS_KEY) === contractAddress) {
        return TlNftType.Building;
    } else if (getContractAddress(isConnected, chain, UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY) === contractAddress) {
        return TlNftType.UniqueBuilding;
    } else if (getContractAddress(isConnected, chain, TOWN_EFFECT_CONTRACT_ADDRESS_KEY) === contractAddress) {
        return TlNftType.TownEffect;
    }

    return TlNftType.Unknown;
}