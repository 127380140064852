import React from "react";

const eventName = "scroll";

const useScrollListener = (element: any, handleScroll: any | ((e: any) => void), throttleMs = 2) => {
    const scrollingTimer = React.useRef<NodeJS.Timeout>();
    const listenToScroll = React.useCallback(
        (e: any) => {
            if (element.current)
                element.current.removeEventListener(eventName, listenToScroll);
            clearTimeout(scrollingTimer.current);
            scrollingTimer.current = setTimeout(
                () => element.current ? element.current.addEventListener(eventName, listenToScroll) : null,
                throttleMs
            );
            handleScroll(e);
        },
        [throttleMs, element, handleScroll]
    );

    React.useEffect(() => {
        const currentElement = element.current;
        if (currentElement) {
            currentElement.addEventListener(eventName, listenToScroll);
        }
        return () => {
            if (currentElement)
                currentElement.removeEventListener(eventName, listenToScroll);
            clearTimeout(scrollingTimer.current);
        };
    }, [listenToScroll, element]);
};

export default useScrollListener;