import React from "react";
import { NavLink } from "react-router-dom";
import "../Menu/menu.css";
import { Badge, Tooltip } from "antd";

const styles = {
    subTabs: {
        marginBottom: "26px",
        marginRight: "auto",
        marginLeft: "auto"
    } as React.CSSProperties,
} as const;

interface SubTabInfo {
    tabKey: string,
    tabDisplayName: string;
    tabSubRoute: string;
    badgeText?: number;
    tootlipText?: string;
}

interface NftPageSubTabsProps {
    subTabs: SubTabInfo[];
    currentTabIndex: number;
}

const NftPageSubTabs = (props: NftPageSubTabsProps) => {
    return (
        <div className={`tabs`} style={{ ...styles.subTabs, "width": "600px" }}>
            <ul>
                {
                    props.subTabs.map(st => {
                        let id = st.tabKey + "_tab";
                        return (
                            <li key={id}>
                                <label htmlFor={id} role="button">
                                    <Tooltip title={st.tootlipText}>
                                        <Badge overflowCount={9} count={st.badgeText} color="#21bf94" className="noselect" style={{ top: "3px", right: "-8px", boxShadow: "none", fontWeight: "600" }}>
                                            <NavLink to={st.tabSubRoute} id={id + "_link"} className="sub-tab">
                                                {st.tabDisplayName}
                                            </NavLink>
                                        </Badge>
                                    </Tooltip>
                                </label>
                            </li>
                        )
                    })
                }
            </ul>
            <div className="slider" style={{
                width: `${100 / props.subTabs.length}%`,
                transform: `translateX(${props.currentTabIndex * 100}%)`,
                display: props.currentTabIndex === -1 ? "none" : "block"
            }}>
                <div className="indicator"></div>
            </div>
        </div>
    );
}

export default NftPageSubTabs;
