import { useAccount, useReadContract } from "wagmi";
import { getContractAddress } from "../../helpers/networks";
import frozenBalanceOfAbi from "../../data/erc1155_frozenBalanceOf_abi.json";
import { TlNftType, isErc1155, resolveContractAddressKey } from "../../helpers/nfts";

const FUNCTION_NAME = "frozenBalanceOf";

export const useErc1155FrozenBalanceOf = (enabled: boolean, tokenId: string | undefined, tlNftType: TlNftType) => {
    const { isConnected, address, chain } = useAccount();
    const { data, error } = useReadContract({
        query: {
            enabled: enabled && isErc1155(tlNftType) && tlNftType !== TlNftType.Land,
        },
        functionName: FUNCTION_NAME,
        abi: [frozenBalanceOfAbi],
        address: getContractAddress(isConnected, chain, resolveContractAddressKey(tlNftType)),
        args: [address, tokenId]
    });

    return {
        frozenNftsAmount: data ? Number((data as bigint)) : undefined,
        frozenBalanceOfError: error
    };
}
