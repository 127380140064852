import { useAccount, useReadContract } from "wagmi";
import { TOWN_BUILDER_CONTRACT_ADDRESS_KEY, getContractAddress } from "../../helpers/networks";
import getConstructedBuildingsBatchAbi from "../../data/townBuilder_getConstructedBuildingsBatch_abi.json";

const FUNCTION_NAME = "getConstructedBuildingsBatch";

export interface GetConstructedBuildingsBatchHook {
    refetchConstructedBuildings: () => {};
    isLoadingConstructedBuildings: boolean;
    inProgressBuildingsCounts: { [townId: string]: number; } | undefined;
    constructedBuildings: { [townId: string]: number[]; } | undefined;
    constructedBuildingsError: Error | null;
}

export const useTownBuilderGetConstructedBuildingsBatch = (enabled: boolean, townIds: string[] | undefined): GetConstructedBuildingsBatchHook => {
    const { isConnected, address, chain } = useAccount();
    const { refetch, data, error, isFetching, isLoading } = useReadContract({
        query: {
            enabled: townIds !== undefined && townIds.length > 0 && address !== undefined && enabled,
        },
        functionName: FUNCTION_NAME,
        abi: [getConstructedBuildingsBatchAbi],
        address: getContractAddress(isConnected, chain, TOWN_BUILDER_CONTRACT_ADDRESS_KEY),
        args: [address, townIds]
    });

    return {
        refetchConstructedBuildings: refetch,
        isLoadingConstructedBuildings: isLoading || isFetching,
        inProgressBuildingsCounts: getInProgressCounts(townIds, data),
        constructedBuildings: getConstructedBuildings(townIds, data),
        constructedBuildingsError: error
    }
}

const getInProgressCounts = (townIds: string[] | undefined, data: unknown): { [k: string]: number } | undefined => {

    if (townIds && data) {
        const countsResponse = ((data as any[])[0] as bigint[]).map(b => Number(b));
        return townIds.reduce<{ [k: string]: number }>((result, townId, index) => {
            result[townId] = countsResponse[index];
            return result;
        }, {});
    }

    return undefined;
}

const getConstructedBuildingsResponse = (data: unknown): number[][] | undefined => {
    if (data) {
        const value = (data as any[])[1] as bigint[][];
        return value.map(arr => arr.map(b => Number(b)));
    }

    return undefined;
}

const getConstructedBuildings = (townIds: string[] | undefined, data: unknown): { [k: string]: number[] } | undefined => {
    const constructedBuildingsResponse: number[][] | undefined = getConstructedBuildingsResponse(data);
    if (townIds && constructedBuildingsResponse) {
        return townIds.reduce<{ [k: string]: number[] }>((result, townId, index) => {
            result[townId] = constructedBuildingsResponse[index];
            return result;
        }, {});
    }

    return undefined;
}
