import { useEffect, useState } from "react";
import { useAccount, useWriteContract, useSimulateContract } from "wagmi";
import { TOWN_BUILDER_CONTRACT_ADDRESS_KEY, getContractAddress } from "../../helpers/networks";
import { message } from "antd";
import { getMessageContentWithTxDetails, waitForTransactionComplete } from "../../helpers/transactions";
import buildAbi from "../../data/townBuilder_build_abi.json";

const CONTRACT_FUNCTION_NAME = "build";
const MESSAGE_BOX_KEY = "townBuilder_build";

export const useBuild = (enabled: boolean, townId: string | undefined, buildingTokenIds: string[], newTownEffectTokenIds: string[]) => {

    const { isConnected, chain } = useAccount();
    const { data, error } = useSimulateContract({
        query: {
            enabled: enabled && isConnected && chain !== undefined && townId !== undefined,
        },
        functionName: CONTRACT_FUNCTION_NAME,
        args: [
            townId,
            buildingTokenIds,
            newTownEffectTokenIds
        ],
        address: getContractAddress(isConnected, chain, TOWN_BUILDER_CONTRACT_ADDRESS_KEY),
        abi: [buildAbi],
    });
    const { writeContractAsync } = useWriteContract();

    const [isBuildInProgress, setIsBuildInProgress] = useState<boolean>(false);
    const [isPrepared, setIsPrepared] = useState<boolean>(false);
    const [preparationError, setPreparationError] = useState<string>();

    useEffect(() => {
        setIsPrepared(data?.request !== undefined);
        if (data?.request) {
            setPreparationError(undefined);
        }
    }, [data]);

    useEffect(() => {
        if (error && error.message) {
            if (error.message.includes("Only the town owner can perform construction work")) {
                setPreparationError("You should own the town to build on it");
            } else if (error.message.includes("User should own all listed buildings to construct them")) {
                setPreparationError("You don't own all of the buildings you want to construt");
            } else if (error.message.includes("Listed buildings cannot be built on this type of town")) {
                setPreparationError("Some of the buildings can't be built on this type of town");
            } else if (error.message.includes("Not enough space in the town")) {
                setPreparationError("Not enough space in the town to construct selected buildings");
            }
            else {
                setPreparationError(error.message);
            }
        } else if (error) {
            setPreparationError("Unknown error :(");
        }
    }, [error]);

    const build = async (onSuccess: () => void = () => { }) => {
        setIsBuildInProgress(true);
        message.loading({ content: "Waiting for your wallet...", key: MESSAGE_BOX_KEY, duration: 0 });

        if (error) {
            message.error({ content: "Build failed to start due to Unknown error! Please try again later.", key: MESSAGE_BOX_KEY, duration: 5 });
            setIsBuildInProgress(false);
            return;
        }

        if (data?.request) {
            await writeContractAsync(data.request)
                .then(async txHash => {
                    message.loading({
                        content: getMessageContentWithTxDetails("Starting build...", txHash, chain!.id),
                        key: MESSAGE_BOX_KEY,
                        duration: 0
                    });

                    await waitForTransactionComplete(txHash, chain!.id, MESSAGE_BOX_KEY,
                        onSuccess, "Build successfully started!");
                    setIsBuildInProgress(false);
                })
                .catch(e => {
                    if (e) {
                        let error = e as any;
                        if (error.message.includes("User rejected")) {
                            message.warn({ content: "You cancelled the build.", key: MESSAGE_BOX_KEY, duration: 2 });
                            setIsBuildInProgress(false);
                            return;
                        }
                    }

                    message.error({ content: "Build failed due to Unknown error! Please try again later.", key: MESSAGE_BOX_KEY, duration: 5 });
                    setIsBuildInProgress(false);
                });
        }
    }

    return {
        build,
        isBuildPrepared: isPrepared,
        isBuildInProgress: isBuildInProgress,
        buildPreparationError: preparationError
    };
}