import MetaMaskIcon from "./wallet_icons/metamask.svg";
import WalletConnectIcon from "./wallet_icons/walletconnect.svg";
import CoinbaseIcon from "./wallet_icons/coinbase.svg";
import InjectedIcon from "./wallet_icons/injected.svg";
import { walletConnect, coinbaseWallet, injected } from 'wagmi/connectors';
import { Connector } from "wagmi";

const WALLETCONNECT_PROJECT_ID = process.env.REACT_APP_WALLETCONNECT_PROJECT_ID!;

const PINNED_WALLETS = ["metamask", "walletconnect", "coinbase"];

interface ConnectorInfo {
    title: string;
    icon: string | undefined;
    iconSize?: number;
    wagmiConnector: any
}

export const isConnectorSupported = (id: string): boolean => {
    return connectorsConfig.hasOwnProperty(id)
        || !PINNED_WALLETS.some(w => id.includes(w));
}

export const getConnectorInfo = (connector: Connector): ConnectorInfo => {
    const id = connector.id;
    if (connectorsConfig.hasOwnProperty(id)) {
        return connectorsConfig[id];
    } else {
        return {
            title: "Detected Wallet",
            icon: connector.icon ? connector.icon : InjectedIcon,
            iconSize: 28,
            wagmiConnector: injected()
        }
    }
}

export const connectorsConfig: { [id: string]: ConnectorInfo } = {
    "metaMask": {
        title: "MetaMask",
        icon: MetaMaskIcon,
        iconSize: 34,
        wagmiConnector: injected({ target: "metaMask" })
    },
    "walletConnect": {
        title: "WalletConnect",
        icon: WalletConnectIcon,
        wagmiConnector: walletConnect({ projectId: WALLETCONNECT_PROJECT_ID })
    },
    "coinbaseWalletSDK": {
        title: "Coinbase",
        icon: CoinbaseIcon,
        wagmiConnector: coinbaseWallet({ appName: "TownsLand" })
    },
};