import { Alchemy } from "alchemy-sdk";
import { useEffect, useState } from "react";
import { getAlchemyNetwork, getTlChainId, resolveAlchemyApiKey } from "../helpers/networks";
import { Chain } from "viem";

export const useAlchemy = (chain: Chain | undefined) => {
    const [alchemy, setAlchemy] = useState<Alchemy>();

    useEffect(() => {
        const chainId = getTlChainId(chain);
        if (chainId) {
            const network = getAlchemyNetwork(chainId);
            const apiKey = resolveAlchemyApiKey(network);
            if (apiKey) {
                setAlchemy(new Alchemy({
                    apiKey: apiKey,
                    network: network
                }));
            } else {
                console.error(`TownsLand: Unknown network - ${network}`);
            }
        }
    }, [chain]);

    return { alchemy };
}
