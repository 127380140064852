import React from "react";
import { OwnedNftsHook } from "../../../hooks/useNfts";
import { SelectedTownNft } from "../../../helpers/townBuilder";
import { Nft } from "../../../helpers/nfts";
import { TOWN_PROGRESS_ATTRIBUTE_NAME, TOWN_SIZE_ATTRIBUTE_NAME } from "../../../helpers/constants";
import { LoadingItemsSpin } from "../LoadingItemsSpin";
import { LoadingItemsError } from "../LoadingItemsError";
import { List, Popover } from "antd";
import { TownBuilderNftPopover } from "../TownBuilderNftPopover";
import { ListItemImage } from "../ListItemImage";
import { GetConstructedBuildingsBatchHook } from "../../../hooks/contracts/useTownBuilderGetConstructedBuildingsBatch";

interface TownStepProps {
    ownedTownsHook: OwnedNftsHook;
    townBuilderGetConstructedBuildingsHook: GetConstructedBuildingsBatchHook;
    selectedTown: SelectedTownNft | undefined;
    setSelectedTown: React.Dispatch<React.SetStateAction<SelectedTownNft | undefined>>;
}

export const TownStep = ({ ownedTownsHook, townBuilderGetConstructedBuildingsHook, selectedTown, setSelectedTown }: TownStepProps) => {

    const {
        isLoading: isTownsLoading,
        fetchSuccessful: isTownsFetchSuccessful,
        fetchedNfts: townNfts,
    } = ownedTownsHook;

    const {
        inProgressBuildingsCounts,
        constructedBuildings
    } = townBuilderGetConstructedBuildingsHook;

    if (isTownsLoading || isTownsFetchSuccessful === undefined) {
        return <LoadingItemsSpin />;
    }

    if (isTownsFetchSuccessful === false && isTownsLoading === false
        || isTownsFetchSuccessful === true && townNfts.length === 0) {
        return <LoadingItemsError itemNamePlural="Towns" />;
    }

    return (
        <List
            className="tl-builder-list"
            grid={{ gutter: 16, column: 3, }}
            dataSource={filterAndSortTowns(townNfts)}
            locale={{ emptyText: "You have no towns" }}
            renderItem={(town: Nft, index) =>
                <Popover
                    key={`town-popover-${index}`}
                    overlayClassName="tl-builder-item-popover"
                    content={
                        <TownBuilderNftPopover
                            nft={town}
                            inProgressMessage={getInProgressText(town, inProgressBuildingsCounts)}
                            showTokenId />
                    }
                    mouseLeaveDelay={0}>
                    <List.Item
                        key={`town-${index}`}
                        className={`tl-builder-list-item tl-town 
                        ${isTownInProgress(town, inProgressBuildingsCounts) ? "tl-town-in-progress" : ""} 
                        ${selectedTown && selectedTown.nft.token_id === town.token_id ? "tl-selected-list-item" : ""}`}
                        style={{ marginBottom: "0px" }}
                        onClick={() => {
                            if (selectedTown && selectedTown.nft.token_id === town.token_id) {
                                return;
                            }

                            setSelectedTown({
                                nft: town,
                                maxSize: getTownMaxSize(town),
                                emptySlots: getTownEmptySlotsNumber(town, constructedBuildings)
                            });
                        }}
                    >
                        <ListItemImage nft={town} />
                    </List.Item>
                </Popover>
            }
        >
        </List>
    );
}

const getTownMaxSize = (town: Nft): number => {
    const townSize = town?.metadata?.attributes.find(attr => attr.trait_type === TOWN_SIZE_ATTRIBUTE_NAME);
    return townSize?.value ?? 0;
}

const getTownEmptySlotsNumber = (town: Nft, constructedBuildings: { [townId: string]: number[]; } | undefined): number => {
    return constructedBuildings ? getTownMaxSize(town) - constructedBuildings[town.token_id].length : 0;
}

const filterAndSortTowns = (townsList: Nft[]) => {
    return townsList
        .sort((a, b) => {
            const attributeA = a.metadata?.attributes.find(attr => attr.trait_type === TOWN_PROGRESS_ATTRIBUTE_NAME);
            const attributeB = b.metadata?.attributes.find(attr => attr.trait_type === TOWN_PROGRESS_ATTRIBUTE_NAME);

            // Sort by "Town Built-Up Progress" attribute value
            if (attributeA && attributeB) {
                const sortByProgress = attributeA.value - attributeB.value;
                if (sortByProgress !== 0) {
                    return sortByProgress;
                }
            }

            return parseInt(a.token_id) - parseInt(b.token_id);
        });
}

const isTownInProgress = (town: Nft, inProgressBuildingsCounts: { [townId: string]: number; } | undefined): boolean => {
    return inProgressBuildingsCounts !== undefined && inProgressBuildingsCounts[town.token_id] > 0;
}

const getInProgressText = (town: Nft, inProgressBuildingsCounts: { [townId: string]: number; } | undefined): string | undefined => {
    if (!isTownInProgress(town, inProgressBuildingsCounts)) {
        return undefined;
    }

    const count = inProgressBuildingsCounts![town.token_id];
    return `${count} ${count > 1 ? "buildings are" : "building is"} being built in this town`;
}
