import React from "react";
import { Button, Space, message } from "antd";
import { useSwitchChain } from "wagmi";
import { SWITCH_CHAIN_CANCELLED_MESSAGE, SWITCH_CHAIN_ERROR_MESSAGE, WALLET_WAITING_FOR_ACTION_MESSAGE, WRONG_CHAIN_TEXT } from "../../helpers/constants";
import { MAIN_CHAIN } from "../../helpers/networks";

export const SwitchChainBlock = () => {
    const { switchChainAsync, error: switchNetworkError } = useSwitchChain();

    return (
        <Space>
            <span>{WRONG_CHAIN_TEXT}</span>
            <Button type="primary" size="middle" loading={switchChainAsync === undefined} onClick={async () => {
                try {
                    await switchChainAsync({ chainId: MAIN_CHAIN });
                }
                catch (e) {
                    const error = e as Error;
                    if (error && error.message.includes("User rejected")) {
                        message.warn(SWITCH_CHAIN_CANCELLED_MESSAGE);
                    }
                    else if (error && error.message.includes("already pending for")) {
                        message.warn(WALLET_WAITING_FOR_ACTION_MESSAGE);
                    }
                    else {
                        message.error(SWITCH_CHAIN_ERROR_MESSAGE, 5);
                    }
                }
            }}>Switch Chain</Button>
        </Space>
    );
}