export const resolveOwnedBuildingSubTitle = (ownedAmount: string | number | undefined, frozenBuildingsAmount: number | undefined): string => {
    const totalAmount = Number(ownedAmount);
    if (frozenBuildingsAmount === undefined || frozenBuildingsAmount === 0) {
        return "";
    } else if (frozenBuildingsAmount === 1 && totalAmount === 1) {
        return "This building is currently being built in your Town";
    } else if (frozenBuildingsAmount === totalAmount) {
        return `These ${frozenBuildingsAmount} buildings are currently being built in your Town`;
    } else {
        return `${frozenBuildingsAmount} of ${totalAmount} buildings are currently being built in your Town`;
    }
}

export const resolveOwnedBuildingSubTitleForModal = (ownedAmount: string | number | undefined, frozenBuildingsAmount: number | undefined): string => {
    const totalAmount = Number(ownedAmount);
    if (frozenBuildingsAmount === undefined || frozenBuildingsAmount === 0) {
        return "";
    } else if (frozenBuildingsAmount === 1 && totalAmount === 1) {
        return "Building is being built";
    } else if (frozenBuildingsAmount === totalAmount) {
        return `${frozenBuildingsAmount} buildings are being built`;
    } else {
        return `${frozenBuildingsAmount} of ${totalAmount} buildings are being built`;
    }
}

export const resolveOwnedTownEffectsSubTitle = (ownedAmount: string | number | undefined, frozenEffectsAmount: number | undefined): string => {
    const totalAmount = Number(ownedAmount);
    if (frozenEffectsAmount === undefined || frozenEffectsAmount === 0) {
        return "";
    } else if (frozenEffectsAmount === 1 && totalAmount === 1) {
        return "This effect is currently being applied to your Town";
    } else if (frozenEffectsAmount === totalAmount) {
        return `These ${frozenEffectsAmount} effects are currently being applied to your Town`;
    } else {
        return `${frozenEffectsAmount} of ${totalAmount} effects are currently being applied to your Town`;
    }
}

export const resolveOwnedTownEffectSubTitleForModal = (ownedAmount: string | number | undefined, frozenEffectsAmount: number | undefined): string => {
    const totalAmount = Number(ownedAmount);
    if (frozenEffectsAmount === undefined || frozenEffectsAmount === 0) {
        return "";
    } else if (frozenEffectsAmount === 1 && totalAmount === 1) {
        return "Town effect is being applied";
    } else if (frozenEffectsAmount === totalAmount) {
        return `${frozenEffectsAmount} town effects are being applied`;
    } else {
        return `${frozenEffectsAmount} of ${totalAmount} town effects are being applied`;
    }
}

export const resolveOwnedTownSubTitle = (isTownBuildInProgress: boolean | undefined) => {
    return isTownBuildInProgress ? "New buildings are currently being built in this Town" : "";
}

export const resolveOwnedTownSubTitleForModal = (isTownBuildInProgress: boolean | undefined) => {
    return isTownBuildInProgress ? "New buildings are being built " : "";
}