import { Network } from "alchemy-sdk";
import { goerli, sepolia, polygonMumbai, polygonAmoy } from "wagmi/chains";
import { Chain } from "viem/chains";

export const MAIN_CHAIN = 80002; // amoy
export const SupportedChains = [MAIN_CHAIN];

export const LAND_CONTRACT_ADDRESS_KEY = "landContractAddress";
export const TOWN_CONTRACT_ADDRESS_KEY = "townContractAddress";
export const BUILDING_CONTRACT_ADDRESS_KEY = "buildingContractAddress";
export const UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY = "uniqueBuildingContractAddress";
export const TOWN_EFFECT_CONTRACT_ADDRESS_KEY = "townEffectContractAddress";
export const TLC_CONTRACT_ADDRESS_KEY = "tlcContractAddress";
export const TOWN_BUILDER_CONTRACT_ADDRESS_KEY = "townBuilderAddress";

export const resolveAlchemyApiKey = (network: Network): string | undefined => {
    switch (network) {
        case Network.ETH_GOERLI:
            return process.env.REACT_APP_GOERLI_ALCHEMY_API_KEY!;
        case Network.ETH_SEPOLIA:
            return process.env.REACT_APP_SEPOLIA_ALCHEMY_API_KEY!;
        case Network.MATIC_MUMBAI:
            return process.env.REACT_APP_POLYGON_MUMBAI_ALCHEMY_API_KEY!;
        case Network.MATIC_AMOY:
            return process.env.REACT_APP_POLYGON_AMOY_ALCHEMY_API_KEY!;
        default:
            return undefined;
    }
}

export const resolveAlchemyRpcUrl = (network: Network): string | undefined => {
    switch (network) {
        case Network.ETH_GOERLI:
            return process.env.REACT_APP_GOERLI_ALCHEMY_RPC_URL!;
        case Network.ETH_SEPOLIA:
            return process.env.REACT_APP_SEPOLIA_ALCHEMY_RPC_URL!;
        case Network.MATIC_MUMBAI:
            return process.env.REACT_APP_POLYGON_MUMBAI_ALCHEMY_RPC_URL!;
        case Network.MATIC_AMOY:
            return process.env.REACT_APP_POLYGON_AMOY_ALCHEMY_RPC_URL!;
        default:
            return undefined;
    }
}

export const getTlChainId = (chain: Chain | undefined): number => {
    return chain ? chain.id : MAIN_CHAIN;
}

export const isChainSupported = (chain: Chain | undefined) => {
    return chain !== undefined && SupportedChains.includes(chain.id);
}

export const getContractAddress = (isConnected: boolean, chain: Chain | undefined, contractAddressKey: string): `0x${string}` | undefined => {
    const chainId = getTlChainId(chain);

    if (!isConnected || isChainSupported(chain)) {
        const networkData = networkConfigs[chainId];
        return networkData ? (networkData[contractAddressKey] as string)?.toLowerCase() as `0x${string}` : undefined;
    }

    return undefined;
}

export const getExplorer = (chain: Chain | undefined): string => {
    return getExplorerByChainId(getTlChainId(chain))!;
}

export const getExplorerByChainId = (chainId: number): string => {
    return getStringOrUndefined(chainId, "blockExplorerUrl")!;
}

export const getOpenSeaUrl = (chain: Chain | undefined): string => {
    return getStringOrUndefined(getTlChainId(chain), "openSeaUrl")!;
}

export const getOpenSeaNetwork = (chain: Chain | undefined): string => {
    return getStringOrUndefined(getTlChainId(chain), "openSeaNetwork")!;
}

export const getCurrencySymbol = (chain: Chain | undefined): string => {
    return getStringOrUndefined(getTlChainId(chain), "currencySymbol")!;
}

export const getChainName = (chain: number | undefined) => {
    return getStringOrUndefined(chain, "name");
}


export const getAlchemyNetwork = (chain: number): Network => {
    const networkData = networkConfigs[chain];
    return networkData["alchemyNetwork"] as Network;
}

export const getViemNetwork = (chain: number): Chain => {
    const networkData = networkConfigs[chain];
    return networkData["wagmiNetwork"] as Chain;
}

export const isNftContractValid = (nftContractAddress: string, isConnected: boolean, chain: Chain | undefined): boolean => {
    return getContractAddress(isConnected, chain, BUILDING_CONTRACT_ADDRESS_KEY) === nftContractAddress
        || getContractAddress(isConnected, chain, UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY) === nftContractAddress
        || getContractAddress(isConnected, chain, LAND_CONTRACT_ADDRESS_KEY) === nftContractAddress
        || getContractAddress(isConnected, chain, TOWN_CONTRACT_ADDRESS_KEY) === nftContractAddress
        || getContractAddress(isConnected, chain, TOWN_EFFECT_CONTRACT_ADDRESS_KEY) === nftContractAddress;
}

const getStringOrUndefined = (chain: number | undefined, configKey: string): string | undefined => {
    if (chain) {
        const networkData = networkConfigs[chain];
        return networkData ? networkData[configKey] as string : undefined;
    }

    return undefined;
}

export const networkConfigs: { [id: number]: any } = {
    1: {
        name: "Mainnet",
        currencySymbol: "ETH",
        blockExplorerUrl: "https://etherscan.io/",
        openSeaUrl: "https://opensea.io/",
    },
    5: {
        name: "Goerli",
        currencySymbol: "ETH",
        alchemyNetwork: Network.ETH_GOERLI,
        wagmiNetwork: goerli,
        blockExplorerUrl: "https://goerli.etherscan.io/",
        openSeaUrl: "https://testnets.opensea.io/",
        openSeaNetwork: "goerli",
        buildingContractAddress: "0xFb8517c7B3Ea46D8be8569771387Be3b813d9279",
        uniqueBuildingContractAddress: "0x2738202de973BB0E60c0b4c3E9bb7566E2d6e277",
        landContractAddress: "0x5A7E1192f80D69C1f55b748b7383B2EfF21B8846",
        townContractAddress: "0x5CF052d46435D2A5BFcf86557c824376FDb9a175",
        tlcContractAddress: "0xC76E0bb083d6D0D53CdFbB9c30c27ACA31Ec8DB3",
        townBuilderAddress: "0x09269e03A67245Cb37cE56Ad51c0248eA2bceCf7"
    },
    11155111: {
        name: "Sepolia",
        currencySymbol: "ETH",
        alchemyNetwork: Network.ETH_SEPOLIA,
        wagmiNetwork: sepolia,
        blockExplorerUrl: "https://sepolia.etherscan.io/",
        openSeaUrl: "https://testnets.opensea.io/",
        openSeaNetwork: "sepolia",
        buildingContractAddress: "0xD74887A41762580d5B4f7BabCc083EC7351Ee03b",
        uniqueBuildingContractAddress: "0x7787ccB4b89947435da1C786318173b9C976491b",
        landContractAddress: "0xf6d238f7e4FA95298Acba0a0E0b2c3427Ef39ea3",
        townContractAddress: "0x8e43C9075b5aFE937A1a98575854c632B13CE20d",
        tlcContractAddress: "0x6C7E829AC78349a5F5F12D57Fa603363B757a642",
        townBuilderAddress: "0x8B483328C621288A16f6Afd2324bb2C04f1Ffa66"
    },
    80001: {
        name: "Polygon Mumbai",
        currencySymbol: "MATIC",
        alchemyNetwork: Network.MATIC_MUMBAI,
        wagmiNetwork: polygonMumbai,
        blockExplorerUrl: "https://mumbai.polygonscan.com/",
        openSeaUrl: "https://testnets.opensea.io/",
        openSeaNetwork: "mumbai",
        buildingContractAddress: "0x2eC1954d7bc89fc927Bfc39986Efe23b12Ff4F13",
        uniqueBuildingContractAddress: "0x528B70F71b4B4b8CA3A87b5e6ed2a7A088C34ab6",
        landContractAddress: "0x6D053C0e64e846E9d35044522e3E8869D166098B",
        townContractAddress: "0x5D52a61f96a21bE693142Df7164C29633E57d1D8",
        townEffectContractAddress: "0x2869885d98235FFda336D6EA331e29d4B90659d3",
        tlcContractAddress: "0x07A889Cb382bd7FaF9f147DD121d50020D3c5995",
        townBuilderAddress: "0xA5c7E8B466A53f02044A7e3eefcc2910A8659D39"
    },
    80002: {
        name: "Polygon Amoy",
        currencySymbol: "MATIC",
        alchemyNetwork: Network.MATIC_AMOY,
        wagmiNetwork: polygonAmoy,
        blockExplorerUrl: "https://www.oklink.com/amoy/",
        openSeaUrl: "https://testnets.opensea.io/",
        openSeaNetwork: "amoy",
        buildingContractAddress: "0xE169a2288CDf6775eC408d0BaFa05969ea7D518c",
        uniqueBuildingContractAddress: "0xD63f27a7bbB515e7d9a2B243A0c683b637BEe3e5",
        landContractAddress: "0xCBc8177C266C88c9C8d72c2645DDed876FE1D31b",
        townContractAddress: "0x6cf94018292Ad3C450AE8fD2A458F9fADb6bb9F7",
        townEffectContractAddress: "0x9dE1Acdd12722d7BaC8a02831105B65d510a1015",
        tlcContractAddress: "0xf437438f5605ee804AA067f2AB0861314285B081",
        townBuilderAddress: "0xA5BFbfceeBbd941d8e28682A95c933f6065915e6"
    },
    539: {
        name: "Local",
        currencyName: "ETH",
        currencySymbol: "ETH",
        rpcUrl: "http://127.0.0.1:8545",
        blockExplorerUrl: "",
        openSeaUrl: "https://testnets.opensea.io/",
    },
    137: {
        name: "Polygon Mainnet",
        currencyName: "MATIC",
        currencySymbol: "MATIC",
        rpcUrl: "https://rpc-mainnet.maticvigil.com/",
        blockExplorerUrl: "https://explorer-mainnet.maticvigil.com/",
        openSeaUrl: "https://testnets.opensea.io/",
    }
};