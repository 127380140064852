import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { WagmiProvider } from 'wagmi';
import { NftContextProvider } from './components/NftContext';
import { wagmiConfig } from './configs';

const Application = () => {
  global.Buffer = global.Buffer || require("buffer").Buffer;

  useEffect(() => {
    document.getElementsByTagName("html")[0].classList.add("custom-scrollbar");

    setTimeout(() => {
      // removing .preload class from body to enable all animations after page loaded
      document.body.classList.remove("preload");
    }, 50);
  }, []);

  const queryClient = new QueryClient();

  return (
    <React.StrictMode>
      <WagmiProvider config={wagmiConfig}>
        <QueryClientProvider client={queryClient} >
          <App />
        </QueryClientProvider>
      </WagmiProvider>
    </React.StrictMode>
  )
}

ReactDOM.render(
  <NftContextProvider>
    <Application />
  </NftContextProvider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
