import React, { useEffect, useRef, useState } from "react";
import { Alert } from "antd";
import { useNfts } from "../hooks/useNfts";
import {
    UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY,
    getContractAddress
} from "../helpers/networks";
import { useLocation } from "react-router-dom";
import { isInWhitelist } from "../helpers/whitelist";
import NftPageTitle from "./Nfts/NftPageTitle";
import NftPageSubTabs from "./Nfts/NftPageSubTabs";
import NftList from "./Nfts/NftList";
import {
    NOT_CONNECTED_TEXT,
    NOT_IN_WHITELIST_MESSAGE
} from "../helpers/constants";
import {
    MY_PROPERTY_BUILDINGS_SUBROUTE,
    MY_PROPERTY_TOWNS_SUBROUTE,
    MY_PROPERTY_TOWN_EFFECTS_SUBROUTE,
    resolveContractAddressFromRoute,
    resolveMyPropertyNftsAmountSubTitle,
    resolveMyPropertyNoNftsPlaceholder,
} from "../helpers/routes";
import { useAccount } from "wagmi";
import { SwitchChainBlock } from "./Common/SwitchChainBlock";
import { Nft } from "../helpers/nfts";

const DEFAULT_SUBROUTE = MY_PROPERTY_TOWNS_SUBROUTE;
const DEFAULT_SUBROUTE_INDEX = 0;

const MyProperty = () => {
    const { pathname } = useLocation();
    const { isConnected, address, chain } = useAccount();
    const [warningMessage, setWarningMessage] = useState<any | undefined>(NOT_CONNECTED_TEXT);
    const [contractAddress, setContractAddress] = useState<string | undefined>();
    const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);
    const [intersected, setIntersected] = useState<boolean>(false);
    const [currentTabIndex, setCurrentTabIndex] = useState<number>(-1);
    const [currentTabRoute, setCurrentTabRoute] = useState<string>("");

    const {
        getNfts: getUniqueNfts,
        resetFetchedData: resetFetchedUniqueNfts,
        isLoading: isUniqueNftsLoading,
        fetchSuccessful: isUniqueNftsFetchSuccessful,
        fetchedNfts: fetchedUniqueNfts,
        totalNfts: totalUniqueNfts
    } = useNfts(getContractAddress(isConnected, chain, UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY)!, true, 999);

    const {
        getNfts,
        resetFetchedData,
        isLoading,
        fetchSuccessful,
        fetchedNfts,
        cursor,
        hasMore,
        totalNfts
    } = useNfts(contractAddress!, true);

    useEffect(() => {
        if (pathname.includes(MY_PROPERTY_TOWNS_SUBROUTE)) {
            setCurrentTabIndex(0);
            setCurrentTabRoute(MY_PROPERTY_TOWNS_SUBROUTE);
        } else if (pathname.includes(MY_PROPERTY_BUILDINGS_SUBROUTE)) {
            setCurrentTabIndex(1);
            setCurrentTabRoute(MY_PROPERTY_BUILDINGS_SUBROUTE);
        } else if (pathname.includes(MY_PROPERTY_TOWN_EFFECTS_SUBROUTE)) {
            setCurrentTabIndex(2);
            setCurrentTabRoute(MY_PROPERTY_TOWN_EFFECTS_SUBROUTE);
        } else {
            setCurrentTabIndex(DEFAULT_SUBROUTE_INDEX);
            setCurrentTabRoute(DEFAULT_SUBROUTE);
        }
    }, [pathname]);

    // consider showing collection from mainnet if user not authenricated
    useEffect(() => {
        if (isConnected) {
            const currentContractAddress = resolveContractAddressFromRoute(isConnected, chain, pathname, undefined);
            if (currentContractAddress) {
                if (isInWhitelist(address)) {
                    setWarningMessage(undefined);
                    setContractAddress(currentContractAddress);
                } else {
                    setWarningMessage(NOT_IN_WHITELIST_MESSAGE);
                    setContractAddress(undefined);
                }
            } else {
                setWarningMessage(<SwitchChainBlock />);
                setContractAddress(undefined);
            }
        } else {
            setWarningMessage(NOT_CONNECTED_TEXT);
            setContractAddress(undefined);
        }
    }, [isConnected, address, chain, currentTabIndex]);

    useEffect(() => {
        if (contractAddress && address && chain) {
            resetFetchedUniqueNfts();
            if (pathname.includes(MY_PROPERTY_BUILDINGS_SUBROUTE)) {
                getUniqueNfts();
            }

            getNfts();
        }
    }, [contractAddress, address, chain]);

    const observer = useRef(
        new IntersectionObserver(
            (entries) => {
                const first = entries[0];

                if (first.isIntersecting) {
                    setIntersected(true);
                }
            })
    );

    useEffect(() => {
        if (intersected && fetchSuccessful && hasMore) {
            getNfts(cursor);
        }

        return () => {
            setIntersected(false);
        }
    }, [intersected]);

    useEffect(() => {
        if (!fetchSuccessful) {
            return;
        }

        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    if (warningMessage) {
        return (
            <Alert message={warningMessage} type="info" showIcon />
        );
    }

    const resolveNftsListToDisplay = (): Nft[] => {
        return pathname.includes(MY_PROPERTY_BUILDINGS_SUBROUTE) && fetchedUniqueNfts !== undefined
            ? !isUniqueNftsLoading && !isLoading ? fetchedUniqueNfts.concat(fetchedNfts) : []
            : fetchedNfts;
    }

    const resolveIsCollectionLoading = (): boolean => {
        return pathname.includes(MY_PROPERTY_BUILDINGS_SUBROUTE)
            ? isUniqueNftsLoading && isLoading
            : isLoading;
    }

    const resolveCollectionFetchSuccessful = (): boolean | undefined => {
        return pathname.includes(MY_PROPERTY_BUILDINGS_SUBROUTE)
            ? isUniqueNftsFetchSuccessful && fetchSuccessful
            : fetchSuccessful;
    }

    const resolveTotalItemsCount = (): number => {
        return pathname.includes(MY_PROPERTY_BUILDINGS_SUBROUTE)
            ? isUniqueNftsLoading || isLoading ? -1 : totalUniqueNfts + totalNfts
            : totalNfts;
    }

    return (
        <>
            <div>
                <NftPageTitle
                    title="Your Property"
                    subTitle={resolveMyPropertyNftsAmountSubTitle(currentTabIndex)}
                    isLoading={resolveIsCollectionLoading()}
                    totalNfts={resolveTotalItemsCount()} />
                <NftPageSubTabs currentTabIndex={currentTabIndex} subTabs={
                    [
                        { tabKey: "town", tabDisplayName: "My Towns", tabSubRoute: MY_PROPERTY_TOWNS_SUBROUTE },
                        { tabKey: "building", tabDisplayName: "My Buildings", tabSubRoute: MY_PROPERTY_BUILDINGS_SUBROUTE },
                        { tabKey: "town-effect", tabDisplayName: "My Town Effects", tabSubRoute: MY_PROPERTY_TOWN_EFFECTS_SUBROUTE },
                    ]
                } />
                <NftList isLoading={resolveIsCollectionLoading()} fetchSuccessful={resolveCollectionFetchSuccessful()}
                    currentTabRoute={currentTabRoute} fetchedNfts={resolveNftsListToDisplay()}
                    setLastElement={setLastElement}
                    getCustomCardDescription={(n) => "You own: " + n.amount}
                    noNftsPlaceholder={resolveMyPropertyNoNftsPlaceholder(currentTabIndex)}
                    isNftsOwnedByUser={true} />
            </div>
        </>
    );
}

export default MyProperty;

