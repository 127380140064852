import React from "react";
import { OwnedNftsHook } from "../../../hooks/useNfts";
import { NftFreeBalanceOfBatchHook } from "../../../hooks/contracts/useNftFreeBalanceOf";
import { OrderedNft, SelectedTownNft } from "../../../helpers/townBuilder";
import { Nft, TlNftType } from "../../../helpers/nfts";
import { BUILDING_CATEGORY_ATTRIBUTE_NAME, BUILDING_TOWN_TYPE_ATTRIBUTE_NAME, TOWN_TYPE_ATTRIBUTE_NAME, UNIQUE_CATEGORY } from "../../../helpers/constants";
import { LoadingItemsSpin } from "../LoadingItemsSpin";
import { LoadingItemsError } from "../LoadingItemsError";
import { Badge, Button, List, Popover } from "antd";
import { TownBuilderNftPopover } from "../TownBuilderNftPopover";
import { ListItemImage } from "../ListItemImage";

interface BuildingStepProps {
    ownedBuildingsHook: OwnedNftsHook;
    buildingFreeBalanceOfBatchHook: NftFreeBalanceOfBatchHook;
    uniqueBuildingFreeBalanceOfBatchHook: NftFreeBalanceOfBatchHook;

    selectedTown: SelectedTownNft | undefined;
    selectedBuildings: OrderedNft[];
    setSelectedBuildings: React.Dispatch<React.SetStateAction<OrderedNft[]>>;
}

export const BuildingsStep = (
    {
        ownedBuildingsHook,
        buildingFreeBalanceOfBatchHook,
        uniqueBuildingFreeBalanceOfBatchHook,
        selectedTown,
        selectedBuildings,
        setSelectedBuildings
    }: BuildingStepProps) => {

    const {
        isLoading: isBuildingsLoading,
        fetchSuccessful: isBuildingsFetchSuccessful,
        fetchedNfts: buildingNfts,
    } = ownedBuildingsHook;

    const {
        isBatchLoading: isFreeBuildingsAmountsLoading,
        freeAmounts: freeBuildingsAmounts,
        freeBatchAmountsError: buildingsFreeAmountsError,
    } = buildingFreeBalanceOfBatchHook;

    const {
        isBatchLoading: isFreeUniqueBuildingsAmountsLoading,
        freeAmounts: freeUniqueBuildingsAmounts,
        freeBatchAmountsError: uniqueBuildingsFreeAmountsError,
    } = uniqueBuildingFreeBalanceOfBatchHook;

    const isUserHasUniqueBuildings = (): boolean => {
        return !isBuildingsLoading && isBuildingsFetchSuccessful === true
            && filterBuildingsByTownType(selectedTown, buildingNfts).filter(b => b.tlNftType === TlNftType.UniqueBuilding).length > 0;
    }

    const isUserHasCommonBuildings = (): boolean => {
        return !isBuildingsLoading && isBuildingsFetchSuccessful === true
            && filterBuildingsByTownType(selectedTown, buildingNfts).filter(b => b.tlNftType === TlNftType.Building).length > 0;
    }

    const getFreeBuildingsAmount = (building: Nft): number => {
        if (isUserHasCommonBuildings() && freeBuildingsAmounts === undefined || isUserHasUniqueBuildings() && freeUniqueBuildingsAmounts === undefined) {
            return 0;
        }

        if (freeBuildingsAmounts !== undefined && freeBuildingsAmounts[building.token_id] > 0) {
            return freeBuildingsAmounts[building.token_id];
        }
        if (freeUniqueBuildingsAmounts !== undefined && freeUniqueBuildingsAmounts[building.token_id] > 0) {
            return freeUniqueBuildingsAmounts[building.token_id];
        }

        return 0;
    }

    const getSelectedCountByTokenId = (building: Nft): number => {
        return selectedBuildings.filter(b => b.nft.token_id === building.token_id).length;
    }

    const resolveIsRemoveEnabled = (building: Nft): boolean => {
        return selectedBuildings.some(b => b.nft.token_id === building.token_id);
    }

    const resolveIsAddEnabled = (building: Nft): boolean => {
        const selectedBuildingNumber = getSelectedCountByTokenId(building);
        return selectedTown !== undefined && selectedBuildings.length < selectedTown.emptySlots && getFreeBuildingsAmount(building) - selectedBuildingNumber > 0;
    }

    const removeSelectedBuilding = (building: Nft) => {
        const lastIndex = selectedBuildings.findLastIndex(b => b.nft.token_id === building.token_id);
        if (lastIndex !== -1) {
            const newArray = [...selectedBuildings];
            newArray.splice(lastIndex, 1);
            setSelectedBuildings(newArray);
        }
    }

    const addBuilding = (building: Nft) => {
        const newArray = [...selectedBuildings, { index: String(selectedBuildings.length + 1), nft: building }];
        setSelectedBuildings(newArray);
    }

    if (isBuildingsLoading || isBuildingsFetchSuccessful === undefined
        || (isUserHasUniqueBuildings() && uniqueBuildingsFreeAmountsError === null && (isFreeUniqueBuildingsAmountsLoading || freeUniqueBuildingsAmounts === undefined))
        || (isUserHasCommonBuildings() && buildingsFreeAmountsError === null && (isFreeBuildingsAmountsLoading || freeBuildingsAmounts === undefined))) {
        return <LoadingItemsSpin />;
    }

    if (isBuildingsFetchSuccessful === false && isBuildingsLoading === false
        || uniqueBuildingsFreeAmountsError
        || buildingsFreeAmountsError) {
        return <LoadingItemsError itemNamePlural="Buildings" />;
    }

    return (
        <List
            className="tl-builder-list"
            grid={{ gutter: 16, column: 3 }}
            dataSource={filterAndSortBuildings(selectedTown, buildingNfts, { ...freeUniqueBuildingsAmounts, ...freeBuildingsAmounts })}
            locale={{ emptyText: isTownFullFromTheStart(selectedTown) ? "Town is already full, but you can apply effects to it" : "You have no suitable buildings" }}
            renderItem={(building: Nft, index) =>
                <Popover
                    key={`building-popover-${index}`}
                    overlayClassName="tl-builder-item-popover"
                    content={<TownBuilderNftPopover nft={building} ownedAmount={getFreeBuildingsAmount(building)} />}
                    mouseLeaveDelay={0}
                >
                    <List.Item
                        key={`building-${index}`}
                        className={`tl-builder-list-item tl-building ${resolveIsRemoveEnabled(building) ? "tl-selected-list-item" : ""}`}
                        style={{ marginBottom: "0px" }}
                    >
                        <Badge
                            overflowCount={99}
                            count={getSelectedCountByTokenId(building)}
                            color={getSelectedCountByTokenId(building) > 0 ? "var(--tl-badge-color)" : undefined}
                            className="tl-builder-badge"
                            style={{ boxShadow: "none", fontWeight: "600", position: "absolute" }} />
                        <ListItemImage nft={building} />
                        <div className="ant-image-mask tl-builder-building-hover">
                            <Button
                                className="tl-builder-building-button tl-remove"
                                danger
                                disabled={!resolveIsRemoveEnabled(building)}
                                onClick={() => removeSelectedBuilding(building)}>
                                Remove
                            </Button>
                            <Button
                                className="tl-builder-building-button tl-select"
                                disabled={!resolveIsAddEnabled(building)}
                                onClick={() => addBuilding(building)}>
                                Add
                            </Button>
                        </div>
                    </List.Item>
                </Popover>
            }
        >
        </List>
    );
}

const isTownFullFromTheStart = (selectedTown: SelectedTownNft | undefined): boolean => {
    return selectedTown === undefined || selectedTown.emptySlots === 0;
}

const filterBuildingsByTownType = (selectedTown: SelectedTownNft | undefined, buildingsList: Nft[]): Nft[] => {
    return buildingsList
        .filter(b => {
            const attribute = b.metadata?.attributes.find(attr => attr.trait_type === BUILDING_TOWN_TYPE_ATTRIBUTE_NAME);
            const townAttribute = selectedTown?.nft.metadata?.attributes.find(attr => attr.trait_type === TOWN_TYPE_ATTRIBUTE_NAME);
            return attribute && townAttribute && attribute.value === townAttribute.value;
        });
}

const filterAndSortBuildings = (
    selectedTown: SelectedTownNft | undefined,
    buildingsList: Nft[],
    notFrozenAmounts: { [k: string]: number; }
) => {

    return isTownFullFromTheStart(selectedTown)
        ? []
        : filterBuildingsByTownType(selectedTown, buildingsList)
            .filter(b => {
                return notFrozenAmounts[b.token_id] > 0;
            })
            .sort((a, b) => {
                const aIsUnique = a.metadata?.attributes.some(attr => attr.trait_type === BUILDING_CATEGORY_ATTRIBUTE_NAME && attr.value === UNIQUE_CATEGORY);
                const bIsUnique = b.metadata?.attributes.some(attr => attr.trait_type === BUILDING_CATEGORY_ATTRIBUTE_NAME && attr.value === UNIQUE_CATEGORY);

                if (aIsUnique === bIsUnique) {
                    return parseInt(a.token_id) - parseInt(b.token_id);
                } else {
                    return aIsUnique ? -1 : 1;
                }
            });
}
