import React, { useEffect, useState } from "react";
import { Nft, isPurchaseable } from "../../helpers/nfts";
import { Button, Modal, Tooltip, Image, InputNumber } from "antd";
import { NFT_NOT_ON_SALE_MESSAGE, NOT_ENOUGH_CRYPTO_MESSAGE, TOWNSLAND_MAIN_WALLET } from "../../helpers/constants";
import { useErc1155GetPrice } from "../../hooks/contracts/useErc1155GetPrice";
import { LOADING_CURRENCY_VALUE_PLACEHOLDER, useNativeAccountBalance } from "../../hooks/useBalance";
import { useNftFreeBalanceOf } from "../../hooks/contracts/useNftFreeBalanceOf";
import { useErc1155Buy } from "../../hooks/contracts/useErc1155Buy";
import NoImageIcon from "../../img/no-image.png";
import { ethToWei, roundPriceForDisplay } from "../../helpers/formatters";

export interface BuyModalProps {
    index: number | string;
    nft: Nft;
    isOpen: boolean;
    onCancel?: () => void | undefined;
    onOperationFinished?: () => void | undefined;
}

export const BuyModal = (props: BuyModalProps) => {
    const nft = props.nft;

    const { refetchPrice, price, isOnSale, getPriceError } = useErc1155GetPrice(
        props.isOpen && isPurchaseable(nft.tlNftType), nft.token_id, nft.tlNftType);
    const { fetchBalance, balance, fetchError, currencySymbol, tokenIcon, tokenIconSize } = useNativeAccountBalance();
    const {
        refetchNftFreeBalance,
        freeAmount,
        freeAmountError } = useNftFreeBalanceOf(
            props.isOpen && isPurchaseable(nft.tlNftType) && price !== undefined,
            nft.token_id,
            TOWNSLAND_MAIN_WALLET,
            nft.tlNftType);
    const [amountToBuy, setAmountToBuy] = useState<number | undefined>(1);

    const isPurchasePossible = (): boolean => {
        const userBalance = balance === LOADING_CURRENCY_VALUE_PLACEHOLDER ? undefined : Number(balance);
        const requestedAmount = amountToBuy === undefined ? undefined : amountToBuy!;
        const totalAmount = freeAmount === undefined ? undefined : Number(freeAmount);
        if (userBalance === undefined || requestedAmount === undefined || totalAmount === undefined
            || price === undefined || isOnSale !== true) {
            return false;
        }

        if (requestedAmount > totalAmount) {
            return false;
        }

        return userBalance - requestedAmount * price >= 0;
    }

    const {
        buy,
        isBuyInProgress,
        isBuyPrepared,
        buyPreparationError
    } = useErc1155Buy(isPurchasePossible(), nft.token_id, amountToBuy, calculateExactTotalPriceWei(price, amountToBuy), nft.tlNftType);

    const isBuyButtonLoading = (): boolean => {
        return !isBuyPrepared && !buyPreparationError && !freeAmount && balance != LOADING_CURRENCY_VALUE_PLACEHOLDER;
    }

    const resolveTooltipTitle = () => {
        if (amountToBuy === undefined) {
            return "Amount is empty";
        }
        else if (buyPreparationError !== undefined) {
            return buyPreparationError;
        }
        else if (isOnSale === false) {
            return NFT_NOT_ON_SALE_MESSAGE;
        }
        else if (!isPurchasePossible()) {
            return NOT_ENOUGH_CRYPTO_MESSAGE;
        }
        else {
            return "";
        }
    }

    useEffect(() => {
        if (props.isOpen) {
            refetchPrice();
            refetchNftFreeBalance();
            fetchBalance();
        }
    }, [props.isOpen]);

    return (
        <Modal
            className="tl-send-or-buy-nft-modal close-icon-hidden"
            closeIcon={null}
            centered={true}
            style={{ minWidth: "560px", marginTop: "-100px" }}
            maskStyle={{ backgroundColor: "rgba(0, 0, 0, 0.65)" }}
            open={props.isOpen}
            onCancel={props.onCancel}
            bodyStyle={{ paddingBottom: "10px", textAlign: "left" }}
            footer={[
                <Button key={`cancel-purchase${props.index}`} onClick={props.onCancel}>Cancel</Button>,
                <Tooltip key={`purchase${props.index}`} placement="bottom" title={resolveTooltipTitle()}>
                    <Button
                        key={props.index}
                        type="primary"
                        loading={isBuyButtonLoading()}
                        disabled={!isPurchasePossible() || buyPreparationError !== undefined || isBuyInProgress}
                        className="tl-button-loading"
                        style={{ justifyContent: "center", marginLeft: "8px" }}
                        onClick={async () => { await buy(() => props.onOperationFinished?.()); }}>
                        <div style={{ display: "flex" }}>
                            <span style={{ paddingRight: "6px" }}>Buy for {calculateTotalPriceForUi(price, amountToBuy)}</span>
                            <img src={tokenIcon} className="tl-balance-icon" width={20} height={20} style={{ marginRight: "0px" }} />
                        </div>
                    </Button>
                </Tooltip>
            ]}
        >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <Tooltip title={nft.name} trigger="click" showArrow={false} placement="bottom">
                    <div style={{ marginRight: "24px", cursor: "pointer" }}>
                        <Image
                            className="tl-send-or-buy-nft-image-clickable"
                            key={nft.token_id}
                            preview={false}
                            src={nft.preview_image}
                            fallback={NoImageIcon}
                            alt=""
                            style={{ marginRight: "24px", width: "160px" }}
                            width="160px"
                        />
                    </div>
                </Tooltip>
                <div>
                    <div>
                        <span style={{ fontSize: "16px" }}>How much do you want to purchase?</span>
                    </div>
                    <InputNumber
                        key={props.index}
                        min={1}
                        max={freeAmount ? Number(freeAmount) : 99}
                        defaultValue={1}
                        precision={0}
                        style={{ marginTop: "12px", width: "100%", minWidth: "100px" }}
                        onChange={(n) => { setAmountToBuy(n === null ? undefined : n) }}></InputNumber>
                    <div style={{ color: "var(--tl-text-color)", display: "flex", fontSize: "14px", justifyContent: "right", marginTop: "50px" }}>
                        <span style={{ marginRight: "12px", marginTop: "0.5px" }}>Your balance: </span>
                        <Tooltip title={currencySymbol} placement="right">
                            <div style={{ display: "flex", justifyContent: "center" }}>
                                {tokenIcon &&
                                    <img
                                        src={tokenIcon}
                                        className="tl-balance-icon noselect"
                                        width={18}
                                        height={18}
                                    />
                                }
                                <span style={{ marginTop: "1.5px", fontWeight: "600" }}>
                                    {balance}
                                </span>
                            </div>
                        </Tooltip>
                    </div>
                    <div style={{ color: "var(--tl-text-color)", display: "flex", fontSize: "14px", justifyContent: "right" }}>
                        <span style={{ marginRight: "12px", marginTop: "0.5px" }}>Available amount: </span>
                        <span style={{ fontWeight: 600 }}>{freeAmount ? Number(freeAmount) : "..."}</span>
                    </div>
                </div>
            </div>
        </Modal>
    );
}

const calculateTotalPriceForUi = (pricePerEach: number | undefined, amountToBuy: number | undefined): string => {
    let totalPrice = pricePerEach && amountToBuy ? pricePerEach * amountToBuy : undefined;
    return roundPriceForDisplay(totalPrice);
}

const calculateExactTotalPriceWei = (pricePerEach: number | undefined, amountToBuy: number | undefined): BigInt | undefined => {
    let totalPrice = pricePerEach && amountToBuy ? BigInt(ethToWei(pricePerEach)!) * BigInt(amountToBuy) : undefined;
    return totalPrice;
}
