import { useAccount, useReadContract } from "wagmi";
import balanceOfAbi from "../../data/erc1155_balanceOf_abi.json";

const FUNCTION_NAME = "balanceOf";

export const useErc1155BalanceOf = (enabled: boolean, nftContractAddress: string | undefined, tokenId: string | undefined) => {
    const { address } = useAccount();
    const { data, error } = useReadContract({
        query: {
            enabled: tokenId !== undefined && nftContractAddress !== undefined && enabled,
        },
        functionName: FUNCTION_NAME,
        abi: [balanceOfAbi],
        address: nftContractAddress as `0x${string}`,
        args: [address, tokenId]
    });

    return {
        balance: data ? Number((data as bigint)) : undefined,
        balanceError: error
    };
}
