import { useAccount, useReadContract } from "wagmi";
import { getContractAddress } from "../../helpers/networks";
import { TlNftType, resolveContractAddressKey } from "../../helpers/nfts";
import freeBalanceOfAbi from "../../data/nft_freeBalanceOf_abi.json";
import freeBalanceOfBatchAbi from "../../data/nft_freeBalanceOfBatch_abi.json";

const FUNCTION_NAME = "freeBalanceOf";
const BATCH_FUNCTION_NAME = "freeBalanceOfBatch";

export const useNftFreeBalanceOf = (enabled: boolean, tokenId: string | undefined, address: string | undefined, tlNftType: TlNftType) => {
    const { isConnected, chain } = useAccount();
    const { refetch, data, error, isLoading, isFetching } = useReadContract({
        query: {
            enabled: address !== undefined && address !== "" && tokenId !== undefined && enabled,
        },
        functionName: FUNCTION_NAME,
        abi: [freeBalanceOfAbi],
        address: getContractAddress(isConnected, chain, resolveContractAddressKey(tlNftType)),
        args: [address, Number(tokenId)]
    });

    return {
        refetchNftFreeBalance: refetch,
        isLoading: isLoading || isFetching,
        freeAmount: data ? Number((data as bigint)) : undefined,
        freeAmountError: error
    }
}

export interface NftFreeBalanceOfBatchHook {
    refetchNftFreeBalanceBatch: () => {};
    isBatchLoading: boolean;
    freeAmounts: { [k: string]: number; } | undefined;
    freeBatchAmountsError: Error | null;
}

export const useNftFreeBalanceOfBatch = (enabled: boolean, tokenIds: string[] | undefined, addresses: string[] | undefined, tlNftType: TlNftType): NftFreeBalanceOfBatchHook => {
    const { isConnected, chain } = useAccount();
    const { refetch, data, error, isLoading, isFetching } = useReadContract({
        query: {
            enabled: enabled && addresses !== undefined && tokenIds !== undefined
                && addresses.length > 0 && addresses.length === tokenIds.length,
        },
        functionName: BATCH_FUNCTION_NAME,
        abi: [freeBalanceOfBatchAbi],
        address: getContractAddress(isConnected, chain, resolveContractAddressKey(tlNftType)),
        args: [addresses, tokenIds]
    });

    return {
        refetchNftFreeBalanceBatch: refetch,
        isBatchLoading: isLoading || isFetching,
        freeAmounts: data && tokenIds ? Object.fromEntries(tokenIds.map((tokenId, index) => [tokenId, Number((data as bigint[])[index])])) : undefined,
        freeBatchAmountsError: error
    }
}
