import { useEffect, useState } from "react";
import { useAccount, useWriteContract, useSimulateContract } from "wagmi";
import { getContractAddress } from "../../helpers/networks";
import { message } from "antd";
import { getMessageContentWithTxDetails, waitForTransactionComplete } from "../../helpers/transactions";
import { NOT_ENOUGH_NFT_TO_TRANSFER_MESSAGE } from "../../helpers/constants";
import { TlNftType, isErc1155, isErc721, resolveContractAddressKey } from "../../helpers/nfts";
import { isAddress } from "viem";
import erc1155SafeTransferFromAbi from "../../data/erc1155_safeTransferFrom_abi.json";
import erc721SafeTransferFromAbi from "../../data/erc721_safeTransferFrom_abi.json";

const SAFE_TRANSFER_FROM_FUNCTION_NAME = "safeTransferFrom";
const MESSAGE_BOX_KEY = "nft_safeTransferFrom";

export const useErc721SafeTransferFrom = (enabled: boolean, tokenId: string | undefined,
    to: string | undefined, tlNftType: TlNftType) => {
    return useNftSafeTransferFromInternal(enabled && isErc721(tlNftType), tokenId, 1, to, tlNftType);
}

export const useErc1155SafeTransferFrom = (enabled: boolean, tokenId: string | undefined,
    amount: number | undefined, to: string | undefined, tlNftType: TlNftType) => {
    return useNftSafeTransferFromInternal(enabled && isErc1155(tlNftType), tokenId, amount, to, tlNftType);
}

const useNftSafeTransferFromInternal = (enabled: boolean, tokenId: string | undefined,
    amount: number | undefined, to: string | undefined, tlNftType: TlNftType) => {

    const { isConnected, address, chain } = useAccount();
    const { data, error } = useSimulateContract({
        query: {
            enabled: enabled && tokenId !== undefined && amount !== undefined && to !== undefined && isAddress(to),
        },
        functionName: SAFE_TRANSFER_FROM_FUNCTION_NAME,
        args: resolveArgs(address, tokenId, amount, to, tlNftType),
        address: getContractAddress(isConnected, chain, resolveContractAddressKey(tlNftType)),
        abi: resolveAbi(tlNftType),
    });
    const { writeContractAsync } = useWriteContract();

    const [isSafeTransferInProgress, setIsSafeTransferInProgress] = useState<boolean>(false);
    const [isSafeTransferPrepared, setIsSafeTransferPrepared] = useState<boolean>(false);
    const [prepareError, setPrepareError] = useState<string>();

    useEffect(() => {
        setIsSafeTransferPrepared(data?.request !== undefined);
        if (data?.request) {
            setPrepareError(undefined);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            if (error.message.includes("insufficient balance")) {
                setPrepareError(NOT_ENOUGH_NFT_TO_TRANSFER_MESSAGE);
            } else {
                setPrepareError("Unable to transfer this NFT due to unknown error :(");
            }
        }
    }, [error]);

    const safeTransferFrom = async (onSuccess: () => void = () => { }) => {
        setIsSafeTransferInProgress(true);
        message.loading({ content: "Waiting for your wallet...", key: MESSAGE_BOX_KEY, duration: 0 });

        if (error) {
            message.error({ content: "Transfer failed to start due to Unknown error! Please try again later.", key: MESSAGE_BOX_KEY, duration: 5 });
            setIsSafeTransferInProgress(false);
            return;
        }

        if (data?.request) {
            await writeContractAsync(data.request)
                .then(async txHash => {
                    message.loading({
                        content: getMessageContentWithTxDetails("Transferring...", txHash, chain!.id),
                        key: MESSAGE_BOX_KEY,
                        duration: 0
                    });

                    await waitForTransactionComplete(txHash, chain!.id, MESSAGE_BOX_KEY,
                        onSuccess, "Transfer completed!");
                    setIsSafeTransferInProgress(false);
                })
                .catch(e => {
                    if (e) {
                        let error = e as any;
                        if (error.message.includes("User rejected")) {
                            message.warn({ content: "You cancelled the transfer.", key: MESSAGE_BOX_KEY, duration: 2 });
                            setIsSafeTransferInProgress(false);
                            return;
                        }
                    }

                    message.error({ content: "Transfer failed due to Unknown error! Please try again later.", key: MESSAGE_BOX_KEY, duration: 5 });
                    setIsSafeTransferInProgress(false);
                });
        }
    }

    return {
        safeTransferFrom,
        isSafeTransferPrepared,
        isSafeTransferInProgress,
        prepareError
    };
}

const resolveArgs = (from: `0x${string}` | undefined, tokenId: string | undefined,
    amount: number | undefined, to: string | undefined, tlNftType: TlNftType) => {
    return tlNftType === TlNftType.Town
        ? [
            from,
            to,
            tokenId
        ]
        : [
            from,
            to,
            tokenId,
            amount!,
            []
        ];
}

const resolveAbi = (tlNftType: TlNftType) => {
    return tlNftType === TlNftType.Town
        ? [erc721SafeTransferFromAbi]
        : [erc1155SafeTransferFromAbi];
}
