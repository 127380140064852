import React from "react";
import { Nft } from "../../../helpers/nfts";
import { TOWNEFFECT_TYPE_ATTRIBUTE_NAME } from "../../../helpers/constants";
import { OwnedNftsHook } from "../../../hooks/useNfts";
import { NftFreeBalanceOfBatchHook } from "../../../hooks/contracts/useNftFreeBalanceOf";
import { SelectedTownEffectNft } from "../../../helpers/townBuilder";
import { LoadingItemsSpin } from "../LoadingItemsSpin";
import { LoadingItemsError } from "../LoadingItemsError";
import { List } from "antd";
import { ListItemImage } from "../ListItemImage";

interface TownEffectsStepProps {
    ownedTownEffectsHook: OwnedNftsHook;
    townEffectFreeBalanceOfBatchHook: NftFreeBalanceOfBatchHook;

    selectedTownEffects: SelectedTownEffectNft[];
    setSelectedTownEffects: React.Dispatch<React.SetStateAction<SelectedTownEffectNft[]>>;
}

export const TownEffectsStep = ({ ownedTownEffectsHook, townEffectFreeBalanceOfBatchHook, selectedTownEffects, setSelectedTownEffects }: TownEffectsStepProps) => {

    const {
        isLoading: isTownEffectsLoading,
        fetchSuccessful: isTownEffectsFetchSuccessful,
        fetchedNfts: townEffectNfts,
    } = ownedTownEffectsHook;

    const {
        isBatchLoading: isFreeTownEffectAmountsLoading,
        freeAmounts: freeTownEffectsAmounts,
        freeBatchAmountsError: freeTownEffectsAmountsError,
    } = townEffectFreeBalanceOfBatchHook;

    const getGroupedAndFilteredTownEffects = (townEffectsList: Nft[], notFrozenAmounts: { [k: string]: number; } | undefined): { [key: string]: Nft[] } => {
        return notFrozenAmounts
            ? townEffectsList
                .filter(te => notFrozenAmounts[te.token_id] > 0)
                .reduce((groups: { [key: string]: Nft[] }, nft: Nft) => {
                    const key = nft.metadata?.attributes.find(attr => attr.trait_type === TOWNEFFECT_TYPE_ATTRIBUTE_NAME)?.value;
                    if (!key) {
                        return groups;
                    }

                    if (!groups[key]) {
                        groups[key] = [];
                    }

                    groups[key].push(nft);
                    return groups;
                }, {})
            : {};
    }

    const isTownEffectSelected = (townEffect: Nft): boolean => {
        return selectedTownEffects.some(ste => ste.townEffect.token_id === townEffect.token_id);
    }

    const onEffectClick = (townEffect: Nft) => {
        const typeAttribute = townEffect.metadata?.attributes.find(attr => attr.trait_type === TOWNEFFECT_TYPE_ATTRIBUTE_NAME);
        if (typeAttribute === undefined) {
            return;
        }

        const updatedSelectedEffects = [...selectedTownEffects.filter(ste => ste.townEffect.token_id !== townEffect.token_id)];
        if (updatedSelectedEffects.length !== selectedTownEffects.length) {
            setSelectedTownEffects(updatedSelectedEffects);
            return;
        }

        const sameTypeEffectIndex = updatedSelectedEffects.findIndex(ste => ste.type === typeAttribute.value);
        const newSelectedEffect = { type: typeAttribute.value, townEffect: townEffect };
        if (sameTypeEffectIndex === -1) {
            updatedSelectedEffects.push(newSelectedEffect);
        } else {
            updatedSelectedEffects[sameTypeEffectIndex] = newSelectedEffect;
        }

        setSelectedTownEffects(updatedSelectedEffects);
    }

    if (isTownEffectsLoading || isTownEffectsFetchSuccessful === undefined
        || townEffectNfts.length > 0 && freeTownEffectsAmountsError === null && (isFreeTownEffectAmountsLoading || freeTownEffectsAmounts === undefined)) {
        return <LoadingItemsSpin />;
    }

    if (isTownEffectsFetchSuccessful === false && isTownEffectsLoading === false
        || freeTownEffectsAmountsError
        || townEffectNfts.length > 0 && freeTownEffectsAmounts === undefined) {
        return <LoadingItemsError itemNamePlural="Town Effects" />;
    }

    if (Object.entries(getGroupedAndFilteredTownEffects(townEffectNfts, freeTownEffectsAmounts)).length === 0) {
        return (<div className="ant-list-empty-text">You have no Town Effects</div>);
    }

    return (
        Object.entries(getGroupedAndFilteredTownEffects(townEffectNfts, freeTownEffectsAmounts)).map(([type, townEffects], index) =>
            <div key={`effects-group-${index}`} style={{ marginBottom: "20px" }}>
                <div className="tl-town-effect-type">{type}</div>
                <List
                    className="tl-builder-list tl-effects-list"
                    grid={{ gutter: 16, column: 3, }}
                    dataSource={townEffects}
                    locale={{ emptyText: "You have no Town Effects" }}
                    renderItem={(townEffect: Nft, index) =>
                        <List.Item
                            key={`towneffect-${index}`}
                            className={`tl-builder-list-item tl-town-effect ${isTownEffectSelected(townEffect) ? "tl-selected-list-item" : ""}`}
                            style={{ marginBottom: "0px" }}
                            onClick={() => onEffectClick(townEffect)}
                        >
                            <ListItemImage nft={townEffect} />
                        </List.Item>
                    }
                >
                </List>
            </div>
        )
    );
}
