import React from "react";
import { Link } from "react-router-dom";
import { Card, Image, Tooltip } from "antd";
import { Nft, TlNftType, getPriceIfExists, getTlcPriceIfExists, isPurchaseable } from "../../helpers/nfts";
import NoImageIcon from "../../img/no-image.png";
import TlcIcon from "../../img/tlc.png";
import { LoadingImagePlaceholder } from "../Common/LoadingImagePlaceholder";
import { useErc1155FrozenBalanceOf } from "../../hooks/contracts/useErc1155FrozenBalanceOf";
import { useTownIsBuildInProgress } from "../../hooks/contracts/useTownIsBuildInProgress";
import { resolveOwnedBuildingSubTitle, resolveOwnedTownEffectsSubTitle, resolveOwnedTownSubTitle } from "../../helpers/subTitleResolver";
import { useTlcCurrencyInfo, useNativeCurrencyInfo } from "../../hooks/useBalance";
import { SubTitleWithCheckMark } from "../Common/SubTitleWithCheckMark";
import { useAccount } from "wagmi";

const styles = {
    card: {
        width: "330px",
        borderRadius: "1rem",
        overflow: "hidden",
    } as React.CSSProperties,
    cardCover: {
        height: "330px",
        width: "330px",
        marginTop: "1px",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    } as React.CSSProperties,
} as const;

interface NftCardProps {
    index: number;
    nft: Nft;
    currentTabRoute?: string;
    chainId: number | undefined;
    showPrices?: boolean;
    nftOwnedByUser?: boolean;
    ownedNfts?: Nft[];
    showUserOwnershipOnCard?: boolean;
    setNftToDisplay: (value: React.SetStateAction<Nft | undefined>) => void;
    getCustomCardDescription?: (nft: Nft) => string;
}

const NftCard = (props: NftCardProps) => {

    const isAnyOwnedBuilding = (): boolean => {
        return (props.nftOwnedByUser ?? false) && (nft.tlNftType === TlNftType.Building || nft.tlNftType === TlNftType.UniqueBuilding);
    }

    const isTownEffect = (): boolean => {
        return (props.nftOwnedByUser ?? false) && nft.tlNftType === TlNftType.TownEffect;
    }

    const isOwnedTown = (): boolean => {
        return (props.nftOwnedByUser ?? false) && nft.tlNftType === TlNftType.Town;
    }

    const nft = props.nft;

    const { currencySymbol, tokenIcon, tokenIconSize } = useNativeCurrencyInfo();
    const { tlcCurrencySymbol, tlcTokenIcon, tlcTokenIconSize } = useTlcCurrencyInfo();
    const { frozenNftsAmount, frozenBalanceOfError } = useErc1155FrozenBalanceOf(isAnyOwnedBuilding() || isTownEffect(), nft.token_id, nft.tlNftType);
    const { isTownBuildInProgress, isBuildInProgressError } = useTownIsBuildInProgress(isOwnedTown(), nft.token_id);

    const resolveCardTooltipText = (): string => {
        if (isAnyOwnedBuilding()) {
            return resolveOwnedBuildingSubTitle(nft.amount, frozenNftsAmount);
        } else if (isTownEffect()) {
            return resolveOwnedTownEffectsSubTitle(nft.amount, frozenNftsAmount);
        } else if (isOwnedTown()) {
            return resolveOwnedTownSubTitle(isTownBuildInProgress);
        }

        return "";
    }

    const getPriceBlockIfNeeded = () => {
        const emptyBlock = <></>;
        if (!props.showPrices || props.nftOwnedByUser) {
            return emptyBlock;
        }
        let currencySymbolToDisplay = undefined;
        let tokenIconToDisplay = undefined;
        let priceToDisplay = undefined;
        let priceClassName = "";

        if (nft.tlNftType === TlNftType.UniqueBuilding && getTlcPriceIfExists(nft)) {
            currencySymbolToDisplay = tlcCurrencySymbol;
            tokenIconToDisplay = tlcTokenIcon;
            priceToDisplay = getTlcPriceIfExists(nft);
        } else if (isPurchaseable(nft.tlNftType) && getPriceIfExists(nft)) {
            currencySymbolToDisplay = currencySymbol;
            tokenIconToDisplay = tokenIcon;
            priceToDisplay = getPriceIfExists(nft);

            if (nft.tlNftType === TlNftType.TownEffect) {
                priceClassName = "tl-shadow-extra";
            }
        }
        else {
            return emptyBlock;
        }

        return (
            <Tooltip title={"Price in " + currencySymbolToDisplay}>
                <div className={priceClassName} style={{ display: "flex", justifyContent: "center", marginRight: "auto" }}>
                    <img src={tokenIconToDisplay} className="tl-balance-icon" alt="" width="20" height="20" />
                    <span style={{ display: "flex", alignItems: "center" }}>{priceToDisplay}</span>
                </div>
            </Tooltip>
        );
    }

    const getOwnershipBlockIfNeeded = () => {

        const emptyBlock = <></>;
        if (!props.showUserOwnershipOnCard || !props.ownedNfts?.some(on => on.token_id === nft.token_id)) {
            return emptyBlock;
        }

        return (
            <div style={{ display: "flex", justifyContent: "center", marginRight: "auto", fontSize: "16px" }}>
                <SubTitleWithCheckMark text="Owned by you" />
            </div>
        );
    }

    const resolveCardClassName = () => {
        if (props.nftOwnedByUser && (frozenNftsAmount || isTownBuildInProgress)) {
            return "tl-constructing-item-card";
        } else if (props.showUserOwnershipOnCard && props.ownedNfts?.some(on => on.token_id === nft.token_id)) {
            return "tl-owned-item-card";
        } else {
            return "tl-default-item-card";
        }
    }

    return (
        <div key={props.index}>
            <Tooltip title={resolveCardTooltipText()}>
                <Link to={props.currentTabRoute
                    ? `${props.currentTabRoute}/${nft.token_id}`
                    : `${nft.token_id}`}>
                    <Card
                        extra={
                            <div style={{ display: "flex" }}>
                                {props.showPrices
                                    && getPriceBlockIfNeeded()}
                                {!props.showPrices && props.showUserOwnershipOnCard
                                    && getOwnershipBlockIfNeeded()}
                            </div>
                        }
                        hoverable
                        className={resolveCardClassName()}
                        style={styles.card}
                        cover={
                            <Image
                                preview={false}
                                src={nft.preview_image || "error"}
                                fallback={NoImageIcon}
                                placeholder={
                                    <div style={styles.cardCover} >
                                        <LoadingImagePlaceholder />
                                    </div>
                                }
                                alt=""
                                className=""
                                style={styles.cardCover}
                            />
                        }
                        onClick={() => {
                            props.setNftToDisplay(nft);
                        }}
                    >
                        <div className="ant-card-meta-detail">
                            <div className="ant-card-meta-title" style={{ marginBottom: "0px" }}>
                                {nft.tlNftType === TlNftType.UniqueBuilding
                                    ? (
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <Tooltip title="Unique NFT. Can only be purchased using TLC token.">
                                                <img src={TlcIcon} className="tl-balance-icon" alt="" width={20} height={20} />
                                            </Tooltip>
                                            <span style={{ paddingRight: "6px" }}>{nft.metadata!.name}</span>
                                        </div>
                                    )
                                    : nft.metadata!.name}
                            </div>
                            <div className="ant-card-meta-description">
                                {getCardSubTitle(nft, props.getCustomCardDescription)}
                            </div>
                        </div>
                    </Card>
                </Link>
            </Tooltip>
        </div>
    );
}

export default NftCard;

const getCardSubTitle = (nft: Nft, getCustomCardDescriptionFunc?: (nft: Nft) => string) => {
    if (getCustomCardDescriptionFunc) {
        return getCustomCardDescriptionFunc(nft);
    }

    if (!nft.amount && (nft.tlNftType === TlNftType.Building || nft.tlNftType === TlNftType.UniqueBuilding)) {
        return nft.metadata?.attributes?.find(a => a.trait_type === "Category")?.value;
    }

    if (!nft.amount && (nft.tlNftType === TlNftType.Land || nft.tlNftType === TlNftType.Town)) {
        return nft.metadata?.attributes?.find(a => a.trait_type === "Type")?.value;
    }

    if (!nft.amount && nft.tlNftType === TlNftType.TownEffect) {
        return nft.metadata?.attributes?.find(a => a.trait_type === "Effect Type")?.value;
    }

    if (nft.amount) {
        return "Total amount: " + nft.amount;
    }

    return " ";
}
