import { Tooltip } from "antd";
import React, { useEffect, useState } from "react";
import { isChainSupported } from "../helpers/networks";
import { useBalances } from "../hooks/useBalance";
import { useAccount } from "wagmi";

const DynamicBalance = () => {
    const { isConnected, chain } = useAccount();
    const [displayBalance, setDisplayBalance] = useState(false);
    const [hideBalance, setHideBalance] = useState(false);
    const [showNativeBalance, setShowNativeBalance] = useState(true);
    const [isTooltipVisible, setIsTooltipVisible] = useState(false);
    const { current: currentBalance, nativeBalanceHook, tlcBalanceHook } = useBalances(showNativeBalance);
    const { balance, fetchError, currencySymbol, tokenIcon, tokenIconSize } = currentBalance;

    const toggleDisplayedBalance = () => {
        setHideBalance(true);
    }

    useEffect(() => {
        if (hideBalance) {
            if (showNativeBalance) {
                tlcBalanceHook.fetchBalance();
            } else {
                nativeBalanceHook.fetchBalance();
            }

            setTimeout(() => {
                setShowNativeBalance(!showNativeBalance);
                setHideBalance(false);
                setDisplayBalance(true);
                setTimeout(() => setDisplayBalance(false), 200);
            }, 100);
        }
    }, [hideBalance]);

    if (!isConnected || !chain || !isChainSupported(chain) || !balance || fetchError) {
        return null;
    }

    return (
        <Tooltip title={currencySymbol} open={isTooltipVisible} placement="left" destroyTooltipOnHide>
            <div className="tl-dynamic-balance-container"
                onMouseOver={() => setIsTooltipVisible(true)}
                onMouseLeave={() => setIsTooltipVisible(false)}
            >
                <div
                    className={"tl-balance-container " + (hideBalance ? "hide-balance" : (displayBalance ? "display-balance" : ""))}
                    onClick={toggleDisplayedBalance}
                >
                    {tokenIcon &&
                        <img
                            src={tokenIcon}
                            className="tl-balance-icon noselect"
                            width={tokenIconSize}
                            height={tokenIconSize}
                        />
                    }
                    <span className="tl-dynamic-balance-amount noselect">
                        {balance}
                    </span>
                </div>
            </div>
        </Tooltip>
    );
}

export default DynamicBalance;
