import React from "react";
import Blockies from "react-blockies";
import { Skeleton } from "antd";
import "./identicon.css";
import { useAccount } from "wagmi";

interface BlockieProps {
    fromCurrentWallet?: boolean;
    address?: string;
    scale?: number;
    size?: number;
}

/**
 * Shows a blockie image for the provided wallet address.
 * @param {*} props if props.fromCurrentWallet is true - Blockie will be generated 
 * from current wallet address. If false - from custom props.address.
 * @returns Blockies element
 */
const Blockie = (props: BlockieProps) => {
    const { address } = useAccount();

    if (!props.address && !address)
        return <Skeleton.Avatar active size={30} />;

    return (
        <Blockies
            seed={
                props.fromCurrentWallet && address
                    ? address.toLowerCase()
                    : props.address?.toLowerCase() ?? ""
            }
            className="identicon"
            scale={props.scale}
            size={props.size}
        />
    )
}

export default Blockie;