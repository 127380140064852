import React from "react";
import AllTownsNftCollection from "./Nfts/AllTownsNftCollection";

const CONTRACT_ADDRESS_KEY = "townContractAddress";

const styles = {
    banner: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        textAlign: "center",
        margin: "0px auto 60px",
        width: "600px",
        height: "100px",
        filter: "drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px)",
    } as React.CSSProperties,
    collectionTitle: {
        color: "#d2cec9",
        fontSize: "60px",
        fontWeight: "bold",
    } as React.CSSProperties,
    collectionTitleSubText: {
        fontSize: "20px",
        color: "#9c9c9c",
        fontWeight: "normal",
        marginTop: "-10px"
    } as React.CSSProperties,
} as const;

const ViewTowns = () => {
    return (
        <AllTownsNftCollection
            title="Explore Towns"
            nftContractAddressKey={CONTRACT_ADDRESS_KEY}
            customBannerStyle={styles.banner}
            customTitleStyle={styles.collectionTitle}
            customTotalSubtitleStyle={styles.collectionTitleSubText}
        />
    );
}

export default ViewTowns;
