import React, { useEffect } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { shuffleClasses } from "../../helpers/classShuffler";
import { scrollTop } from "../../helpers/scroller";
import { OVERVIEW_ROUTE } from "../../helpers/routes";
import { menuRouteKeys, menuRoutes } from "../../helpers/menuRoutes";
import "./menu.css";

interface MenuItemsProps {
    className?: string;
}

const MenuItems = (props: MenuItemsProps) => {
    const { pathname } = useLocation();

    useEffect(() => {
        let isHomePage = pathname.startsWith(OVERVIEW_ROUTE);
        shuffleClasses(isHomePage, "tl-body", "darken", "normal");
        shuffleClasses(isHomePage, "tl-layout", "darken", "normal");
        shuffleClasses(isHomePage, "tl-footer-text", "darken", "normal");
    }, [pathname]);

    const tabInputs: JSX.Element[] = [];
    const tabs: JSX.Element[] = [];
    let i = 0;
    let currentRouteIndex = -1;
    for (let route in menuRoutes) {
        let id = `tab${i + 1}`;
        if (pathname.startsWith(route)) {
            currentRouteIndex = i;
        }

        tabInputs.push(
            <input key={route} type="radio" id={id} name="tab-control" checked={pathname === route} readOnly />
        );
        tabs.push(
            <li key={route}>
                <label htmlFor={id} role="button">
                    <NavLink to={route} onClick={() => scrollTop()}>{menuRoutes[route].menuItemName}</NavLink>
                </label>
            </li>
        );
        i++;
    }

    return (
        <div className={`${props.className} tabs`}>
            {tabInputs}
            <ul>{tabs}</ul>
            <div className="slider" style={{
                width: `${100 / menuRouteKeys.length}%`,
                transform: `translateX(${currentRouteIndex * 100}%)`,
                display: currentRouteIndex === -1 ? "none" : "block"
            }}>
                <div className="indicator"></div>
            </div>
        </div>
    );
}

export default MenuItems;