import { Chain } from "viem";
import { getExplorer, getExplorerByChainId, getOpenSeaNetwork, getOpenSeaUrl } from "./networks";

export const buildNftBlockexplorerUrl = (chain: Chain | undefined, tokenAddress: string, tokenId: string): string => {
    const explorerUrl = getExplorer(chain);
    if (explorerUrl.includes("oklink")) {
        return `${explorerUrl}assets/${tokenAddress}/${tokenId}`;
    }

    return `${explorerUrl}nft/${tokenAddress}/${tokenId}`;
}

export const buildTxBlockexplorerUrl = (chain: Chain | undefined, txHash: string): string => {
    return `${getExplorer(chain)}tx/${txHash}`;
}

export const buildTxBlockexplorerUrlByChainId = (chainId: number, txHash: string): string => {
    return `${getExplorerByChainId(chainId)}tx/${txHash}`;
}

export const buildNftOpenSeaUrl = (chain: Chain | undefined, tokenAddress: string, tokenId: string): string => {
    return `${getOpenSeaUrl(chain)}assets/${getOpenSeaNetwork(chain)}/${tokenAddress}/${tokenId}`;
}