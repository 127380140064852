import { useEffect, useState } from "react";
import { useAccount, useWriteContract, useSimulateContract } from "wagmi";
import { TLC_CONTRACT_ADDRESS_KEY, getContractAddress } from "../../helpers/networks";
import { message } from "antd";
import { getMessageContentWithTxDetails, waitForTransactionComplete } from "../../helpers/transactions";
import { NOT_ENOUGH_TLC_TO_TRANSFER_MESSAGE } from "../../helpers/constants";
import { isAddress } from "viem";
import erc20TransferAbi from "../../data/erc20_transfer_abi.json";
import { tlcToWei } from "../../helpers/formatters";

const TRANSFER_FUNCTION_NAME = "transfer";
const MESSAGE_BOX_KEY = "erc20_transfer";

export const useErc20Transfer = (enabled: boolean, amount: number | undefined, to: string | undefined) => {
    const { isConnected, chain } = useAccount();
    const { data, error } = useSimulateContract({
        query: {
            enabled: enabled && amount !== undefined && to !== undefined && isAddress(to)
        },
        functionName: TRANSFER_FUNCTION_NAME,
        args: [
            to,
            amount === undefined ? 1 : tlcToWei(amount)
        ],
        address: getContractAddress(isConnected, chain, TLC_CONTRACT_ADDRESS_KEY),
        abi: [erc20TransferAbi],
    });
    const { writeContractAsync } = useWriteContract();

    const [isTransferInProgress, setIsTransferInProgress] = useState<boolean>(false);
    const [isTransferPrepared, setIsTransferPrepared] = useState<boolean>(false);
    const [prepareTransferError, setPrepareTransferError] = useState<string>();

    useEffect(() => {
        setIsTransferPrepared(data?.request !== undefined);
        if (data?.request) {
            setPrepareTransferError(undefined);
        }
    }, [data]);

    useEffect(() => {
        if (error) {
            if (error.message.includes("amount exceeds balance")) {
                setPrepareTransferError(NOT_ENOUGH_TLC_TO_TRANSFER_MESSAGE);
            } else {
                setPrepareTransferError("Unable to send TLC due to unknown error :(");
            }
        }
    }, [error]);

    const transfer = async (onSuccess: () => void = () => { }) => {
        setIsTransferInProgress(true);
        message.loading({ content: "Waiting for your wallet...", key: MESSAGE_BOX_KEY, duration: 0 });

        if (error) {
            message.error({ content: "Transfer failed to start due to Unknown error! Please try again later.", key: MESSAGE_BOX_KEY, duration: 5 });
            setIsTransferInProgress(false);
            return;
        }

        if (data?.request) {
            await writeContractAsync(data.request)
                .then(async txHash => {
                    message.loading({
                        content: getMessageContentWithTxDetails("Sending TLC...", txHash, chain!.id),
                        key: MESSAGE_BOX_KEY,
                        duration: 0
                    });

                    await waitForTransactionComplete(txHash, chain!.id, MESSAGE_BOX_KEY,
                        onSuccess, "TLC tokens have been sent!");
                    setIsTransferInProgress(false);
                })
                .catch(e => {
                    if (e) {
                        let error = e as any;
                        if (error.message.includes("User rejected")) {
                            message.warn({ content: "You cancelled the transfer.", key: MESSAGE_BOX_KEY, duration: 2 });
                            setIsTransferInProgress(false);
                            return;
                        }
                    }

                    message.error({ content: "Transfer failed due to Unknown error! Please try again later.", key: MESSAGE_BOX_KEY, duration: 5 });
                    setIsTransferInProgress(false);
                });
        }
    }

    return {
        transfer,
        isTransferPrepared,
        isTransferInProgress,
        prepareTransferError
    };
}
