import React, { useEffect, useRef, useState } from "react";
import { Alert } from "antd";
import { useNfts } from "../../hooks/useNfts";
import { getContractAddress } from "../../helpers/networks";
import NftList from "./NftList";
import NftPageTitle from "./NftPageTitle";
import { useAccount } from "wagmi";
import { SwitchChainBlock } from "../Common/SwitchChainBlock";
import { VIEW_TOWNS_ROUTE } from "../../helpers/routes";
import { CONNECTING_TEXT } from "../../helpers/constants";

interface NftCollectionProps {
    nftContractAddressKey: string;
    title: string;
    totalSubtitle?: string;
    cardTotalSubtitle?: string;
    forCurrentUser?: boolean;
    showIcon?: boolean;
    icon?: string;
    iconBorderColor?: string;
    customBannerStyle?: React.CSSProperties;
    customTitleStyle?: React.CSSProperties;
    customTotalSubtitleStyle?: React.CSSProperties;
}

const AllTownsNftCollection = (props: NftCollectionProps) => {
    const { isConnected, address, chain } = useAccount();
    const [warningMessage, setWarningMessage] = useState<any | undefined>(CONNECTING_TEXT);
    const [contractAddress, setContractAddress] = useState<string>();
    const [lastElement, setLastElement] = useState<HTMLDivElement | null>(null);
    const [intersected, setIntersected] = useState<boolean>(false);

    const {
        getNfts,
        isLoading,
        fetchSuccessful,
        fetchedNfts,
        cursor,
        hasMore,
        totalNfts
    } = useNfts(contractAddress!, props.forCurrentUser);

    const {
        getNfts: getUserNfts,
        isLoading: isUserNftsLoading,
        fetchSuccessful: isUserNftsFetchSuccessful,
        fetchedNfts: fetchedUserNfts,
        cursor: userNftsCursor,
        hasMore: hasMoreUserNfts,
        totalNfts: totalUserNfts
    } = useNfts(contractAddress!, true, undefined, isConnected);

    // consider showing collection from mainnet if user not authenricated
    useEffect(() => {
        const currentContractAddress = getContractAddress(isConnected, chain, props.nftContractAddressKey);
        if (currentContractAddress) {
            setWarningMessage(undefined);
            setContractAddress(currentContractAddress);
        } else if (isConnected) {
            setWarningMessage(<SwitchChainBlock />);
            setContractAddress(undefined);
        }
    }, [isConnected, chain]);

    useEffect(() => {
        if (contractAddress) {
            getUserNfts();
            getNfts();
        }
    }, [contractAddress, chain, isConnected, address]);

    const observer = useRef(
        new IntersectionObserver(
            (entries) => {
                const first = entries[0];

                if (first.isIntersecting) {
                    setIntersected(true);
                }
            })
    );

    useEffect(() => {
        if (intersected && fetchSuccessful && hasMore) {
            getNfts(cursor);
        }

        return () => {
            setIntersected(false);
        }
    }, [intersected]);

    useEffect(() => {
        if (!fetchSuccessful || !isUserNftsFetchSuccessful) {
            return;
        }

        const currentElement = lastElement;
        const currentObserver = observer.current;

        if (currentElement) {
            currentObserver.observe(currentElement);
        }

        return () => {
            if (currentElement) {
                currentObserver.unobserve(currentElement);
            }
        };
    }, [lastElement]);

    if (warningMessage) {
        return (
            <Alert message={warningMessage} type="info" showIcon />
        );
    }

    return (
        <>
            <div>
                <NftPageTitle
                    title={props.title}
                    subTitle={props.totalSubtitle ? props.totalSubtitle : "Total Items"}
                    isLoading={isLoading || isConnected && isUserNftsLoading}
                    totalNfts={totalNfts} />
                <NftList
                    isLoading={isLoading || isConnected && isUserNftsLoading}
                    fetchSuccessful={fetchSuccessful && (!isConnected || isUserNftsFetchSuccessful)}
                    currentTabRoute={VIEW_TOWNS_ROUTE}
                    fetchedNfts={fetchedNfts}
                    setLastElement={setLastElement}
                    isNftsOwnedByUser={props.forCurrentUser}
                    showUserOwnershipOnCard={isConnected}
                    ownedNfts={fetchedUserNfts}
                />
            </div>
        </>
    );
}

export default AllTownsNftCollection;
