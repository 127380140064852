export const TOWNSLAND_MAIN_WALLET = process.env.REACT_APP_MAIN_WALLET!;

export const MIN_UNIQUE_TOKEN_ID = 1000;
export const MAX_UNIQUE_TOKEN_ID = 1099;
export const UNIQUE_CATEGORY = "Unique";

export const TLC_DECIMALS = 18;
export const TLC_CURRENCY_SYMBOL = "TLC";

export const TOWN_PROGRESS_ATTRIBUTE_NAME = "Town Built-Up Progress";
export const TOWN_TYPE_ATTRIBUTE_NAME = "Type";
export const TOWN_CONSTRUCTED_AMOUNT_ATTRIBUTE_NAME = "Buildings Constructed";
export const TOWN_SIZE_ATTRIBUTE_NAME = "Max Buildings";
export const BUILDING_TOWN_TYPE_ATTRIBUTE_NAME = "Town Type";
export const BUILDING_CATEGORY_ATTRIBUTE_NAME = "Category";
export const TOWNEFFECT_TYPE_ATTRIBUTE_NAME = "Effect Type";

export const CONNECTING_TEXT = "Connecting...";
export const NOT_CONNECTED_TEXT = "Connect your Wallet to view this page";
export const NOT_CONNECTED_ACTION_TOOLTIP_TEXT = "Please connect your Wallet";
export const WRONG_CHAIN_TEXT = "Your current chain is not supported";
export const NOT_IN_WHITELIST_MESSAGE = "You are not allowed to view this page";
export const SWITCH_CHAIN_ERROR_MESSAGE = "Please switch chain in your wallet directly";
export const SWITCH_CHAIN_CANCELLED_MESSAGE = "You cancelled chain switch";
export const WALLET_WAITING_FOR_ACTION_MESSAGE = "Your wallet extension waits for your response";
export const NOT_ENOUGH_TLC_MESSAGE = "You don't have enough TLC tokens to buy this NFT!";
export const NOT_ENOUGH_CRYPTO_MESSAGE = "You don't have enough crypto to buy this NFT!";
export const NOT_ENOUGH_NFT_TO_TRANSFER_MESSAGE = "Transfer if not possible: You don't have specified amount of NFTs";
export const WRONG_TARGET_ACCOUNT_MESSAGE = "Target account address is not valid";
export const TARGET_ACCOUNT_IS_EQUAL_TO_CURRENT_MESSAGE = "You can't transfer to yourself!";
export const NOT_ENOUGH_TLC_TO_TRANSFER_MESSAGE = "Transfer if not possible: You don't have specified amount of TLC tokens";
export const NFT_NOT_ON_SALE_MESSAGE = "This NFT is not on sale right now";
export const TOO_BIG_AMOUNT_MESSAGE = "Purchase is not possible: Amount is too big";

export const LOADING_NFT_MESSAGE_BOX_KEY = "get_nft";

export const CONTENT_WIDTH = "1380px";

export const DISCORD_LINK = "https://discord.gg/phGzCnn7pu";
export const EMAIL_ADDRESS = "townsland@proton.me";