import React from "react";

const styles = {
    banner: {
        display: "flex",
        justifyContent: "space-evenly",
        alignItems: "center",
        textAlign: "center",
        margin: "0px auto 40px",
        height: "100px",
        filter: "drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px)",
    } as React.CSSProperties,
    collectionTitle: {
        color: "#d2cec9",
        fontSize: "60px",
        fontWeight: "bold",
    } as React.CSSProperties,
} as const;

export const BuilderBanner = () => {
    return (
        <div style={styles.banner}>
            <div style={styles.collectionTitle}>
                <div>Town Builder</div>
            </div>
        </div>
    )
}
