import React from "react";
import { CheckCircleOutlined } from "@ant-design/icons";


interface SubTitleWithCheckMarkProps {
    text: string
}

export const SubTitleWithCheckMark = (props: SubTitleWithCheckMarkProps) => {
    return (
        <>
            <CheckCircleOutlined style={{ marginRight: "6px", color: "#40d5ac" }} />
            {props.text}
        </>
    );
}