import { useAccount, useReadContract } from "wagmi";
import { getContractAddress } from "../../helpers/networks";
import { TlNftType, isErc1155, resolveContractAddressKey } from "../../helpers/nfts";
import getPriceAbi from "../../data/erc1155_getPrice_abi.json";
import { weiToEth } from "../../helpers/formatters";

const FUNCTION_NAME = "getPrice";

export const useErc1155GetPrice = (enabled: boolean, tokenId: string | undefined, tlNftType: TlNftType) => {
    const { isConnected, chain } = useAccount();
    const { refetch, isLoading, data, error } = useReadContract({
        query: {
            enabled: enabled && isConnected && isErc1155(tlNftType) && tokenId !== undefined,
        },
        functionName: FUNCTION_NAME,
        abi: [getPriceAbi],
        address: getContractAddress(isConnected, chain, resolveContractAddressKey(tlNftType)),
        args: [tokenId]
    });

    return {
        refetchPrice: refetch,
        price: data ? weiToEth(Number((data as any[])[0])) : undefined,
        isOnSale: data ? Boolean((data as any[])[1]) : undefined,
        isPriceLoading: isLoading,
        getPriceError: error
    }
}