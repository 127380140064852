import React from "react";
import {
    MARKET_BUILDINGS_SUBROUTE,
    MARKET_LANDS_SUBROUTE,
    MARKET_ROUTE,
    MARKET_UNIQUE_SUBROUTE,
    MARKET_TOWN_EFFECTS_SUBROUTE,
    MY_PROPERTY_BUILDINGS_SUBROUTE,
    MY_PROPERTY_ROUTE,
    MY_PROPERTY_TOWNS_SUBROUTE,
    NFT_ITEM_TEMPLATE_ROUTE,
    OVERVIEW_ROUTE,
    BUILDER_ROUTE,
    VIEW_TOWNS_ROUTE,
    MY_PROPERTY_TOWN_EFFECTS_SUBROUTE
} from "./routes"
import { Route } from "react-router-dom"
import Overview from "../components/Overview"
import MyProperty from "../components/MyProperty"
import Market from "../components/Market"
import ViewTowns from "../components/ViewTowns"
import Builder from "../components/Builder";

export const OVERVIEW_PAGE = <Overview />;
export const MY_PROPERTY_PAGE = <MyProperty />;
export const MARKET_PAGE = <Market />;
export const VIEW_TOWNS_PAGE = <ViewTowns />;
export const BUILDER_PAGE = <Builder />;

export interface MenuRouteData {
    menuItemName: string;
    routeElement: React.ReactElement;
}

const BasicRoute = (route: string, page: JSX.Element) => {
    return (
        <Route key={route} path={route} element={page} />
    )
}

const NftCollectionRoute = (key: string | number, path: string, page: JSX.Element) => {
    return (
        <Route key={key} path={path} element={page} >
            <Route path={`${path}${NFT_ITEM_TEMPLATE_ROUTE}`} element={page} />
        </Route>
    );
}

const RouteWithNftSubRoutes = (route: string, page: JSX.Element, subroutes: string[]) => {
    return (
        <Route key={route} element={page}>
            {subroutes.map((subroute, index) => NftCollectionRoute(index, subroute, page))}
        </Route>
    );
}

export const resolveRoute = (routeKey: string, page: JSX.Element) => {
    switch (routeKey) {
        case OVERVIEW_ROUTE:
            return BasicRoute(routeKey, page);
        case MY_PROPERTY_ROUTE:
            return RouteWithNftSubRoutes(routeKey, page,
                [
                    MY_PROPERTY_TOWNS_SUBROUTE,
                    MY_PROPERTY_BUILDINGS_SUBROUTE,
                    MY_PROPERTY_TOWN_EFFECTS_SUBROUTE
                ]);
        case MARKET_ROUTE:
            return RouteWithNftSubRoutes(routeKey, page,
                [
                    MARKET_LANDS_SUBROUTE,
                    MARKET_BUILDINGS_SUBROUTE,
                    MARKET_UNIQUE_SUBROUTE,
                    MARKET_TOWN_EFFECTS_SUBROUTE,
                ]);
        case VIEW_TOWNS_ROUTE:
            return NftCollectionRoute(routeKey, routeKey, page);
        case BUILDER_ROUTE:
            return BasicRoute(routeKey, page);
        default:
            throw new Error("Unknown route");
    }
}

export const menuRoutes: { [id: string]: MenuRouteData } = {
    [OVERVIEW_ROUTE]: { menuItemName: "Overview", routeElement: resolveRoute(OVERVIEW_ROUTE, OVERVIEW_PAGE) },
    [MY_PROPERTY_ROUTE]: { menuItemName: "My Property", routeElement: resolveRoute(MY_PROPERTY_ROUTE, MY_PROPERTY_PAGE) },
    [MARKET_ROUTE]: { menuItemName: "Market", routeElement: resolveRoute(MARKET_ROUTE, MARKET_PAGE) },
    [VIEW_TOWNS_ROUTE]: { menuItemName: "Explore Towns", routeElement: resolveRoute(VIEW_TOWNS_ROUTE, VIEW_TOWNS_PAGE) },
    [BUILDER_ROUTE]: { menuItemName: "Builder", routeElement: resolveRoute(BUILDER_ROUTE, BUILDER_PAGE) },
};

export const menuRouteKeys = Object.keys(menuRoutes);