import { useEffect, useState } from "react";
import { TLC_CONTRACT_ADDRESS_KEY, getContractAddress, getCurrencySymbol } from "../helpers/networks";
import TlcIcon from "../img/tlc.png"
import EthIcon from "../img/eth.svg"
import MaticIcon from "../img/matic.svg"
import { useAccount } from "wagmi";
import { useAlchemy } from "./useAlchemy";
import { formatEther } from "viem";
import { TLC_CURRENCY_SYMBOL, TLC_DECIMALS } from "../helpers/constants";

export const LOADING_CURRENCY_VALUE_PLACEHOLDER = "...";

const TLC_FETCH_ERROR = "Unknown error when trying to fetch TLC balance";

interface CurrencyInfo {
    currencySymbol: string;
    tokenIcon: string;
    tokenIconSize: number;
}

interface BalancesHook {
    current: BalanceHook;
    nativeBalanceHook: BalanceHook;
    tlcBalanceHook: BalanceHook;
}

interface BalanceHook extends CurrencyInfo {
    fetchBalance: () => any;
    balance: string;
    fetchError: string | undefined;
}

export const useBalances = (useNative: boolean): BalancesHook => {
    const nativeBalanceHook = useNativeAccountBalance();
    const tlcBalanceHook = useTlcBalance();

    return {
        current: {
            fetchBalance: useNative ? nativeBalanceHook.fetchBalance : tlcBalanceHook.fetchBalance,
            balance: useNative ? nativeBalanceHook.balance : tlcBalanceHook.balance,
            fetchError: useNative ? nativeBalanceHook.fetchError : tlcBalanceHook.fetchError,
            currencySymbol: useNative ? nativeBalanceHook.currencySymbol : tlcBalanceHook.currencySymbol,
            tokenIcon: useNative ? nativeBalanceHook.tokenIcon : tlcBalanceHook.tokenIcon,
            tokenIconSize: useNative ? nativeBalanceHook.tokenIconSize : tlcBalanceHook.tokenIconSize,
        },
        nativeBalanceHook: nativeBalanceHook,
        tlcBalanceHook: tlcBalanceHook
    };
}

export const useNativeCurrencyInfo = (): CurrencyInfo => {
    const { chain } = useAccount();
    const [currencySymbol, setCurrencySymbol] = useState<string>("---");
    const [tokenIcon, setTokenIcon] = useState<string>("");
    const [iconSizePx, setIconSizePx] = useState<number>(20);

    useEffect(() => {
        const updatedCurrencySymbol = getCurrencySymbol(chain);
        setCurrencySymbol(updatedCurrencySymbol);
        const iconData = resolveIconData(updatedCurrencySymbol);
        setTokenIcon(iconData.tokenIcon);
        setIconSizePx(iconData.iconSizePx);
    }, [chain]);

    return {
        currencySymbol: currencySymbol,
        tokenIcon: tokenIcon,
        tokenIconSize: iconSizePx
    };
}

export const useTlcCurrencyInfo = () => {
    const { tokenIcon, iconSizePx } = resolveIconData(TLC_CURRENCY_SYMBOL);

    return {
        tlcCurrencySymbol: TLC_CURRENCY_SYMBOL,
        tlcTokenIcon: tokenIcon,
        tlcTokenIconSize: iconSizePx
    };
}

export const useNativeAccountBalance = (): BalanceHook => {
    const { address, chain } = useAccount();
    const { alchemy } = useAlchemy(chain);
    const [balance, setBalance] = useState<string>(LOADING_CURRENCY_VALUE_PLACEHOLDER);
    const [fetchError, setFetchError] = useState<string>();
    const [currencySymbol, setCurrencySymbol] = useState<string>("---");
    const [tokenIcon, setTokenIcon] = useState<string>("");
    const [iconSizePx, setIconSizePx] = useState<number>(20);

    useEffect(() => {
        if (address && chain) {
            const updatedCurrencySymbol = getCurrencySymbol(chain)!;
            setCurrencySymbol(updatedCurrencySymbol);
            const iconData = resolveIconData(updatedCurrencySymbol);
            setTokenIcon(iconData.tokenIcon);
            setIconSizePx(iconData.iconSizePx);
        }
    }, [address, chain]);

    useEffect(() => {
        if (alchemy) {
            getNativeBalance();
        }
    }, [alchemy, address]);

    const getNativeBalance = async () => {
        if (alchemy && address) {
            await alchemy.core.getBalance(address)
                .then(balanceWei => {
                    setBalance(shrinkBalanceString(formatEther(balanceWei.toBigInt())));
                    setFetchError(undefined);
                })
                .catch(e => {
                    setFetchError(e ? e.toString() : "Unknown error when trying to fetch native balance");
                    setBalance(LOADING_CURRENCY_VALUE_PLACEHOLDER);
                })
        }
    }

    return {
        fetchBalance: getNativeBalance,
        balance: balance,
        fetchError: fetchError,
        currencySymbol: currencySymbol,
        tokenIcon: tokenIcon,
        tokenIconSize: iconSizePx
    };
}

export const useTlcBalance = (): BalanceHook => {
    const { isConnected, address, chain } = useAccount();
    const { alchemy } = useAlchemy(chain);
    const [balance, setBalance] = useState<string>(LOADING_CURRENCY_VALUE_PLACEHOLDER);
    const [fetchError, setFetchError] = useState<string>();

    const { tokenIcon, iconSizePx } = resolveIconData(TLC_CURRENCY_SYMBOL);

    useEffect(() => {
        if (alchemy) {
            getTlcBalance();
        }
    }, [alchemy, address]);

    const getTlcBalance = async () => {
        if (alchemy && address) {
            await alchemy.core.getTokenBalances(address, [getContractAddress(isConnected, chain, TLC_CONTRACT_ADDRESS_KEY)!])
                .then(balances => {
                    if (balances && balances.tokenBalances && balances.tokenBalances.length > 0) {
                        const tlcBalance = balances.tokenBalances[0];
                        if (tlcBalance.error || !tlcBalance.tokenBalance) {
                            setFetchError(tlcBalance.error ? tlcBalance.error : TLC_FETCH_ERROR);
                            setBalance(LOADING_CURRENCY_VALUE_PLACEHOLDER);
                            return;
                        }

                        setBalance(shrinkBalanceValue((+tlcBalance.tokenBalance) / 10 ** TLC_DECIMALS));
                        setFetchError(undefined);
                        return;
                    }

                    setFetchError(TLC_FETCH_ERROR);
                    setBalance(LOADING_CURRENCY_VALUE_PLACEHOLDER);
                })
                .catch(e => {
                    setFetchError(e ? e.toString() : TLC_FETCH_ERROR);
                    setBalance(LOADING_CURRENCY_VALUE_PLACEHOLDER);
                })
        }
    }

    return {
        fetchBalance: getTlcBalance,
        balance: balance,
        fetchError: fetchError,
        currencySymbol: TLC_CURRENCY_SYMBOL,
        tokenIcon: tokenIcon,
        tokenIconSize: iconSizePx
    };
}

const resolveIconData = (tokenSymbol: string): { tokenIcon: string, iconSizePx: number } => {
    switch (tokenSymbol) {
        case "TLC":
            return { tokenIcon: TlcIcon, iconSizePx: 20 };
        case "ETH":
            return { tokenIcon: EthIcon, iconSizePx: 18 };
        case "MATIC":
            return { tokenIcon: MaticIcon, iconSizePx: 18 };
        default:
            return { tokenIcon: "", iconSizePx: 20 };
    }
}

const shrinkBalanceString = (balance: string): string => {
    return shrinkBalanceValue(+balance);
}

const shrinkBalanceValue = (balance: number): string => {
    return parseFloat(balance.toFixed(4)).toString();
}