import React from "react";
import { Image } from "antd";
import { Nft } from "../../helpers/nfts";
import { PopoverPropertiesSection } from "./PopoverPropertiesSection";
import NoImageIcon from "../../img/no-image.png";
import { SubTitleWithCheckMark } from "../Common/SubTitleWithCheckMark";
import { getNftDevelopmentLevels, getNftEffects, getNftProperties } from "../../helpers/nftProperties";

interface TownBuilderNftPopoverProps {
    nft: Nft;
    ownedAmount?: number;
    showTokenId?: boolean;
    inProgressMessage?: string;
}

export const TownBuilderNftPopover = ({ nft, ownedAmount, showTokenId, inProgressMessage }: TownBuilderNftPopoverProps) => {
    return (
        <div style={{ maxWidth: "500px" }}>
            <div style={{ display: "flex" }}>
                <div style={{ marginRight: "14px", fontSize: "13px", padding: 8, }}>
                    <div style={{ minWidth: "100px", minHeight: "108px" }}>
                        <Image src={nft.preview_image} width={100} fallback={NoImageIcon} preview={false} />
                    </div>
                    <div style={{ textAlign: "center", marginTop: "8px" }}>{nft.name}</div>
                    {showTokenId &&
                        <div style={{ textAlign: "center", marginTop: "2px" }}>#{nft.token_id}</div>
                    }
                </div>
                <div style={{ marginBottom: "8px", padding: "12px", }}>
                    <PopoverPropertiesSection title="Properties" properties={getNftProperties(nft, renameNftPropertyNameIfNeeded, renameNftPropertyValueIfNeeded)} style={{ marginBottom: "20px" }} />
                    <PopoverPropertiesSection title="Effects" properties={getNftEffects(nft, renameNftPropertyNameIfNeeded, renameNftPropertyValueIfNeeded)} />
                    <PopoverPropertiesSection title="Development Levels" properties={getNftDevelopmentLevels(nft, renameNftPropertyNameIfNeeded, renameNftPropertyValueIfNeeded)} />
                </div>
            </div>
            {inProgressMessage && (
                <div style={{ fontSize: "15px", marginTop: "0px", color: "var(--tl-in-progress-color)" }}>
                    {inProgressMessage}
                </div>
            )}
            {ownedAmount && (
                <div style={{ fontSize: "15px", marginTop: "0px" }}>
                    <SubTitleWithCheckMark text={`You own: ${ownedAmount}`} />
                </div>
            )}
        </div>
    );
}

const renameNftPropertyNameIfNeeded = (name: string): string => {
    const lowerName = name.toLowerCase();

    if (lowerName === "town built-up progress") {
        return "Progress";
    }
    if (lowerName === "buildings constructed") {
        return "Buildings";
    }
    if (lowerName === "enterpreneurship level" || lowerName === "entrepreneurship level") {
        return "Entrepreneurs";
    }
    if (lowerName.includes(" level")) {
        return name.replace(" level", "");
    }

    return name;
}

const renameNftPropertyValueIfNeeded = (value: any): any => {

    if (typeof value === "string") {
        const lowerValue = value.toString().toLowerCase();

        if (lowerValue === "administrative") {
            return "Admin";
        }
        if (lowerValue === "business center") {
            return "Business";
        }
    }

    return value;
}
