import React, { useEffect, useState } from "react";
import { useAccount } from "wagmi";
import {
    BUILDING_CONTRACT_ADDRESS_KEY,
    TOWN_CONTRACT_ADDRESS_KEY,
    TOWN_EFFECT_CONTRACT_ADDRESS_KEY,
    UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY,
    getContractAddress,
    isChainSupported
} from "../helpers/networks";
import { useNfts, useOwnedNfts } from "../hooks/useNfts";
import { Alert, Button, Skeleton, Space, Steps, Typography } from "antd";
import {
    NOT_CONNECTED_TEXT, NOT_IN_WHITELIST_MESSAGE,
    TOWN_SIZE_ATTRIBUTE_NAME,
} from "../helpers/constants";
import { isInWhitelist } from "../helpers/whitelist";
import { SwitchChainBlock } from "./Common/SwitchChainBlock";
import { useNftFreeBalanceOfBatch } from "../hooks/contracts/useNftFreeBalanceOf";
import { TlNftType } from "../helpers/nfts";
import { MARKET_LANDS_SUBROUTE, MARKET_ROUTE, MY_PROPERTY_TOWNS_SUBROUTE, MY_PROPERTY_TOWN_EFFECTS_SUBROUTE } from "../helpers/routes";
import { useBuild } from "../hooks/contracts/useTownBuilderBuild";
import { BuilderBanner } from "./TownBuilder/Banner";
import { LoadingItemsSpin } from "./TownBuilder/LoadingItemsSpin";
import { OrderedNft, SelectedTownEffectNft, SelectedTownNft } from "../helpers/townBuilder";
import { TownStep } from "./TownBuilder/Steps/TownStep";
import { BuildingsStep } from "./TownBuilder/Steps/BuildingsStep";
import { ReorderBuildingsStep } from "./TownBuilder/Steps/ReorderBuildingsStep";
import { TownEffectsStep } from "./TownBuilder/Steps/TownEffectsStep";
import { useTownBuilderGetConstructedBuildingsBatch } from "../hooks/contracts/useTownBuilderGetConstructedBuildingsBatch";
import { Link } from "react-router-dom";

const { Text } = Typography;

const styles = {
    pageContainer: {
        maxWidth: "900px",
        width: "100%",
        textAlign: "center",
    } as React.CSSProperties,
    contentStyle: {
        textAlign: 'center',
        color: "var(--tl-text-color)",
        backgroundColor: "var(--tl-card-color)",
        borderRadius: "0.5rem",
        border: `1px dashed var(--tl-card-color)`,
        marginTop: "16px",
        minHeight: "420px",
        padding: "10px 20px",
        filter: "drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px)",
    } as React.CSSProperties,
    stepHeader: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "16px",
    } as React.CSSProperties,
    stepContentTitle: {
        fontWeight: "600",
        fontSize: "24px",
        textAlign: "center",
        margin: "auto 0px",
    } as React.CSSProperties,
    stepContent: {
    } as React.CSSProperties,
    stepButton: {
        filter: "drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px)",
    } as React.CSSProperties,
    stepButtonSecondary: {
        marginRight: "8px",
    } as React.CSSProperties,
    stepButtonPrimary: {
        minWidth: "114px",
    } as React.CSSProperties,
    townItem: {
        marginBottom: "0px",
    } as React.CSSProperties,
    placeholder: {
        border: "rgba(188, 188, 183, 0.85) 1px solid",
        borderRadius: "1rem",
        padding: "12px",
        filter: "drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px)",
        background: "rgb(0 0 0 / 6%)",
    } as React.CSSProperties,
    placeholderText: {
        fontSize: "16px",
        color: "rgb(188 188 183 / 85%)",
    } as React.CSSProperties,
    skeletonStepsContainer: {
        display: "flex",
        gap: "14px",
        width: "100%",
    } as React.CSSProperties,
    skeletonStepContent: {
        borderRadius: "0.5rem",
        minHeight: "420px",
        marginTop: "16px",
        width: "-webkit-fill-available",
    } as React.CSSProperties,
} as const;

type TownBuilderStep = {
    index: number;
    name: string;
    defaultContentHeader: string;
    defaultDescription: string;
    isDisabledByDefault: boolean;
}

const steps: TownBuilderStep[] = [
    {
        index: 0,
        name: "Choose a town",
        defaultContentHeader: "Choose a town",
        defaultDescription: "Nothing selected",
        isDisabledByDefault: false,
    },
    {
        index: 1,
        name: "Select buildings",
        defaultContentHeader: "Select buildings",
        defaultDescription: "Nothing selected",
        isDisabledByDefault: true,
    },
    {
        index: 2,
        name: "Set the order",
        defaultContentHeader: "Set the order",
        defaultDescription: "Unordered",
        isDisabledByDefault: true,
    },
    {
        index: 3,
        name: "Apply effects",
        defaultContentHeader: "Apply town effects",
        defaultDescription: "Nothing applied",
        isDisabledByDefault: true,
    },
];

const Builder = () => {
    const { isConnected, address, chain } = useAccount();

    const [townContractAddress, setTownContractAddress] = useState<string>();
    const [unqiueBuildingContractAddress, setUniqueBuildingContractAddress] = useState<string>();
    const [buildingContractAddress, setBuildingContractAddress] = useState<string>();
    const [townEffectContractAddress, setTownEffectContractAddress] = useState<string>();
    const [warningMessage, setWarningMessage] = useState<any | undefined>(NOT_CONNECTED_TEXT);

    const [currentStep, setCurrentStep] = useState(0);
    const [step1Completed, setStep1Completed] = useState<boolean>(false);
    const [step2Completed, setStep2Completed] = useState<boolean>(false);
    const [step3Completed, setStep3Completed] = useState<boolean>(false);
    const [step4Completed, setStep4Completed] = useState<boolean>(false);

    const [selectedTown, setSelectedTown] = useState<SelectedTownNft>();
    const [selectedBuildings, setSelectedBuildings] = useState<OrderedNft[]>([]);
    const [buildingsForReorder, setBuildingsForReorder] = useState<OrderedNft[]>([]);
    const [selectedTownEffects, setSelectedTownEffects] = useState<SelectedTownEffectNft[]>([]);

    const [isBuildSuccessfullyStarted, setIsBuildSuccessfullyStarted] = useState<boolean>(false);

    const {
        isInitialized: isGetAllNftsInitialized,
        getNfts,
        isLoading,
        fetchSuccessful,
        fetchedNfts,
    } = useOwnedNfts([townContractAddress!, unqiueBuildingContractAddress!, buildingContractAddress!, townEffectContractAddress!]);

    const {
        isInitialized: isGetAllUniqueBuildingsInitialized,
        getNfts: getAllUniqueBuildings,
        isLoading: isLoadingAllUniqueBuildings,
        fetchSuccessful: isAllUniqueBuildingsFetchSuccessful,
        fetchedNfts: fetchedAllUniqueBuildings,
    } = useNfts(unqiueBuildingContractAddress!);

    const {
        isInitialized: isGetAllCommonBuildingsInitialized,
        getNfts: getAllCommonBuildings,
        isLoading: isLoadingAllCommonBuildings,
        fetchSuccessful: isAllCommonBuildingsFetchSuccessful,
        fetchedNfts: fetchedAllCommonBuildings,
    } = useNfts(buildingContractAddress!);

    const preFilterTownsByMetadata = () => {
        return fetchedNfts.filter(n => n.token_address === townContractAddress?.toLowerCase());
    }

    const filterUniqueBuildings = () => {
        return fetchedNfts.filter(n => n.token_address === unqiueBuildingContractAddress?.toLowerCase());
    }

    const filterBuildings = () => {
        return fetchedNfts.filter(n => n.token_address === buildingContractAddress?.toLowerCase());
    }

    const filterTownEffects = () => {
        return fetchedNfts.filter(n => n.token_address === townEffectContractAddress?.toLowerCase());
    }

    const ownedTownsHook = useOwnedNfts([townContractAddress!]);
    const {
        isInitialized: isGetTownsInitialized,
        getNfts: getTownNfts,
        resetFetchedData: resetFetchedTowns,
        fetchedNfts: townNfts,
    } = ownedTownsHook;

    const ownedBuildingsHook = useOwnedNfts([buildingContractAddress!, unqiueBuildingContractAddress!]);
    const {
        isInitialized: isGetBuildingsInitialized,
        getNfts: getAllBuildingNfts,
        resetFetchedData: resetFetchedBuildings,
        fetchedNfts: buildingNfts,
    } = ownedBuildingsHook;

    const ownedTownEffectsHook = useOwnedNfts([townEffectContractAddress!]);
    const {
        isInitialized: isGetTownEffectsInitialized,
        getNfts: getTownEffectNfts,
        resetFetchedData: resetFetchedTownEffects,
        fetchedNfts: townEffectNfts,
    } = ownedTownEffectsHook;

    const townBuilderGetConstructedBuildingsHook = useTownBuilderGetConstructedBuildingsBatch(
        preFilterTownsByMetadata().length > 0,
        preFilterTownsByMetadata().map(t => t.token_id)
    );
    const {
        isLoadingConstructedBuildings,
        constructedBuildings,
        constructedBuildingsError
    } = townBuilderGetConstructedBuildingsHook;

    const uniqueBuildingFreeBalanceOfBatchHook = useNftFreeBalanceOfBatch(
        buildingNfts.filter(b => b.tlNftType === TlNftType.UniqueBuilding).length > 0 && address !== undefined,
        buildingNfts.filter(b => b.tlNftType === TlNftType.UniqueBuilding).map(t => t.token_id),
        address === undefined ? undefined : buildingNfts.filter(b => b.tlNftType === TlNftType.UniqueBuilding).map(_ => address),
        TlNftType.UniqueBuilding);

    const buildingFreeBalanceOfBatchHook = useNftFreeBalanceOfBatch(
        buildingNfts.filter(b => b.tlNftType === TlNftType.Building).length > 0 && address !== undefined,
        buildingNfts.filter(b => b.tlNftType === TlNftType.Building).map(t => t.token_id),
        address === undefined ? undefined : buildingNfts.filter(b => b.tlNftType === TlNftType.Building).map(_ => address),
        TlNftType.Building);

    const townEffectFreeBalanceOfBatchHook = useNftFreeBalanceOfBatch(
        townEffectNfts.length > 0 && address !== undefined,
        townEffectNfts.map(t => t.token_id),
        address === undefined ? undefined : townEffectNfts.map(_ => address),
        TlNftType.TownEffect);

    const canStartBuild = (): boolean => {
        return isConnected && address !== undefined && chain !== undefined
            && step1Completed && step2Completed && step3Completed
            && selectedTown !== undefined && (buildingsForReorder.length > 0 || selectedTownEffects.length > 0);
    }

    const {
        build,
        isBuildPrepared,
        isBuildInProgress,
    } = useBuild(
        canStartBuild(),
        selectedTown?.nft.token_id,
        buildingsForReorder.map(b => b.nft.token_id),
        selectedTownEffects.map(b => b.townEffect.token_id));

    const filterTowns = () => {
        return preFilterTownsByMetadata()
            .filter(townNft => {
                const maxSizeAttribute = townNft.metadata?.attributes.find(attr => attr.trait_type === TOWN_SIZE_ATTRIBUTE_NAME);
                return maxSizeAttribute && constructedBuildings
                    && (constructedBuildings[townNft.token_id].length < Number(maxSizeAttribute.value)
                        || filterTownEffects().length > 0);
            });
    }

    const canMoveToNextStep = (): boolean => {
        if (currentStep === 0 && selectedTown !== undefined) {
            return true;
        }
        if (currentStep === 1 && (selectedBuildings.length > 0 || filterTownEffects().length > 0)) {
            return true;
        }
        if (currentStep === 2) {
            return true;
        }

        return false;
    }

    const canSkipToTownEffectsStep = (): boolean => {
        return selectedBuildings.length === 0 && filterTownEffects().length > 0;
    }

    const resolveBuildButtonIcon = (): string => {
        return buildingsForReorder.length > 0 ? "🛠 " : "✨ ";
    }

    const resolveBuildButtonTitle = (): string => {
        return buildingsForReorder.length > 0 ? "Build" : "Apply";
    }

    const onStepChange = (value: number) => {
        setCurrentStep(value);
    };

    const nextStep = () => {
        if (currentStep === 0 && selectedTown) {
            setStep1Completed(true);
        } else if (currentStep === 1 && filterTownEffects().length > 0 || currentStep === 1 && selectedBuildings.length > 0) {
            const reorderingBuildings = buildingsForReorder.length > 0 ? buildingsForReorder : [...selectedBuildings];

            setStep2Completed(true);
            setBuildingsForReorder(reorderingBuildings);

            if (!isBuildingsReorderNeeded(reorderingBuildings)) {
                skipToTownEffects();
                if (selectedBuildings.length > 0) {
                    setStep4Completed(true);
                }

                return;
            }
        } else if (currentStep === 2) {
            setStep3Completed(true);
            setStep4Completed(true);
        }

        setCurrentStep(currentStep + 1);
    };

    const skipToTownEffects = () => {
        if (currentStep === 1 && (!isBuildingsReorderNeeded(buildingsForReorder) || selectedBuildings.length === 0 && filterTownEffects().length > 0)) {
            setStep2Completed(true);
            setStep3Completed(true);
            setCurrentStep(3);
        }
    }

    const prevStep = () => {
        if (currentStep === 3 && !isBuildingsReorderNeeded(buildingsForReorder)) {
            setCurrentStep(1);
            return;
        }

        setCurrentStep(currentStep - 1);
    };

    useEffect(() => {
        if (isConnected) {
            if (isChainSupported(chain)) {
                if (isInWhitelist(address)) {
                    setWarningMessage(undefined);

                    setTownContractAddress(getContractAddress(isConnected, chain, TOWN_CONTRACT_ADDRESS_KEY));
                    setUniqueBuildingContractAddress(getContractAddress(isConnected, chain, UNIQUE_BUILDING_CONTRACT_ADDRESS_KEY));
                    setBuildingContractAddress(getContractAddress(isConnected, chain, BUILDING_CONTRACT_ADDRESS_KEY));
                    setTownEffectContractAddress(getContractAddress(isConnected, chain, TOWN_EFFECT_CONTRACT_ADDRESS_KEY));
                } else {
                    setWarningMessage(NOT_IN_WHITELIST_MESSAGE);
                }
            } else {
                setWarningMessage(<SwitchChainBlock />);
            }
        } else {
            setWarningMessage(NOT_CONNECTED_TEXT);
        }

        setSelectedTown(undefined);
        setCurrentStep(0);
    }, [isConnected, address, chain]);

    useEffect(() => {
        if (isGetAllNftsInitialized && townContractAddress && unqiueBuildingContractAddress
            && buildingContractAddress && townEffectContractAddress) {
            getNfts();
        }
        if (isGetAllUniqueBuildingsInitialized && unqiueBuildingContractAddress) {
            getAllUniqueBuildings();
        }
        if (isGetAllCommonBuildingsInitialized && buildingContractAddress) {
            getAllCommonBuildings();
        }
    }, [address, isGetAllNftsInitialized, isGetAllUniqueBuildingsInitialized,
        isGetAllCommonBuildingsInitialized, townContractAddress, unqiueBuildingContractAddress,
        buildingContractAddress, townEffectContractAddress]);

    useEffect(() => {
        if (currentStep === 0 && isGetTownsInitialized && !step1Completed) {
            resetFetchedTowns();
            getTownNfts();
        } else if (currentStep === 1 && isGetBuildingsInitialized && !step2Completed) {
            resetFetchedBuildings();
            getAllBuildingNfts();
        } else if (currentStep === 3 && isGetTownEffectsInitialized && selectedTownEffects.length === 0) {
            resetFetchedTownEffects();
            getTownEffectNfts();
        }

        if (currentStep !== 0 && !step1Completed) {
            resetFetchedTowns();
            setSelectedTown(undefined);
        }
        if (currentStep !== 1 && !step2Completed) {
            resetFetchedBuildings();
            setSelectedBuildings([]);
            setBuildingsForReorder([]);
        }
        if (currentStep !== 3 && (!step4Completed || selectedTownEffects.length === 0)) {
            resetFetchedTownEffects();
            setSelectedTownEffects([]);
        }
    }, [currentStep, isGetTownsInitialized, isGetBuildingsInitialized, isGetTownEffectsInitialized, fetchSuccessful]);

    useEffect(() => {
        setStep1Completed(false);
        setStep2Completed(false);
        setStep3Completed(false);
        setStep4Completed(false);
        setSelectedBuildings([]);
        setBuildingsForReorder([]);
    }, [selectedTown]);

    useEffect(() => {
        // either buildings or effects must be selected to start build
        if (selectedBuildings.length === 0 && filterTownEffects().length === 0
            || selectedBuildings.length > 0) {
            setStep2Completed(false);
            setStep3Completed(false);
        }

        setBuildingsForReorder([]);
    }, [selectedBuildings]);

    useEffect(() => {
        // either buildings or effects must be selected to start build
        if (selectedTownEffects.length === 0 && buildingsForReorder.length === 0) {
            setStep4Completed(false);
        }
        else if (step1Completed && step2Completed && step3Completed && (buildingsForReorder.length > 0 || selectedTownEffects.length > 0)) {
            setStep4Completed(true);
        }
    }, [selectedTownEffects]);

    const isBuildingsReorderNeeded = (buildings: OrderedNft[]): boolean => {
        if (buildings.length <= 1) {
            return false;
        }

        const firstTokenId = buildings[0].nft.token_id;
        for (let building of buildings) {
            if (building.nft.token_id !== firstTokenId) {
                return true;
            }
        }

        return false;
    }

    const resolveStepContentHeader = () => {
        if (isBuildInProgress) {
            return "Attempting to start construction";
        }

        if (currentStep === 0 && selectedTown) {
            return "Town is selected";
        } else if (currentStep === 1 && selectedBuildings.length > 0) {
            const emptySlotsLeft = (selectedTown?.emptySlots ?? selectedBuildings.length) - selectedBuildings.length;
            return emptySlotsLeft === 0
                ? "The town is full"
                : `You can select ${emptySlotsLeft} more building${emptySlotsLeft === 1 ? "" : "s"}`;
        } else if (currentStep === 2) {
            return buildingsForReorder.length <= 1
                ? "No buildings to reorder"
                : "Drag and drop to reorder";
        } else if (currentStep === 3) {
            return selectedTownEffects.length > 0
                ? `${selectedTownEffects.length} town effect${selectedTownEffects.length === 1 ? "" : "s"} selected`
                : "Apply town effects if you like"
        }

        return steps[currentStep].defaultContentHeader;
    }

    const resolveStepDescription = (step: number): string => {
        if (step === 0 && selectedTown !== undefined && step1Completed) {
            return "Town is selected";
        }
        if (step === 1 && selectedBuildings.length > 0 && step2Completed) {
            return "Buildings selected";
        }
        if (step === 2 && step2Completed) {
            if (isBuildingsReorderNeeded(buildingsForReorder) && step3Completed) {
                return "The order is set";
            }
            if (!isBuildingsReorderNeeded(buildingsForReorder)) {
                return "Not needed";
            }
        }
        if (step === 3 && step3Completed && selectedTownEffects.length > 0) {
            return "Effects selected";
        }

        return steps[step].defaultDescription;
    }

    const resolveStepDisabled = (step: number): boolean => {
        if (step === currentStep) {
            return false;
        }

        if (step === 2) {
            return step > currentStep || !isBuildingsReorderNeeded(buildingsForReorder);
        }

        if (step < currentStep) {
            return false;
        }

        return steps[step].isDisabledByDefault;
    }

    const resolveStepStatus = (step: number): "wait" | "process" | "finish" => {
        if (step === currentStep) {
            return "process";
        }

        if (step === 0 && step1Completed
            || step === 1 && step2Completed
            || step === 2 && step3Completed
            || step === 3 && step4Completed) {
            return "finish";
        }

        if (step < currentStep) {
            return "finish";
        }

        return "wait";
    }

    const buildStepContent = () => {
        if (isBuildInProgress) {
            return <LoadingItemsSpin customTitle="Starting build..." hideSpin />;
        }

        if (currentStep === 0) {
            return (
                <TownStep
                    ownedTownsHook={ownedTownsHook}
                    townBuilderGetConstructedBuildingsHook={townBuilderGetConstructedBuildingsHook}
                    selectedTown={selectedTown}
                    setSelectedTown={setSelectedTown} />
            );
        } else if (currentStep === 1) {
            return (
                <BuildingsStep
                    ownedBuildingsHook={ownedBuildingsHook}
                    buildingFreeBalanceOfBatchHook={buildingFreeBalanceOfBatchHook}
                    uniqueBuildingFreeBalanceOfBatchHook={uniqueBuildingFreeBalanceOfBatchHook}
                    selectedTown={selectedTown}
                    selectedBuildings={selectedBuildings}
                    setSelectedBuildings={setSelectedBuildings}
                />
            );
        } else if (currentStep === 2) {
            return (
                <ReorderBuildingsStep
                    allUserBuildings={filterUniqueBuildings().concat(filterBuildings())}
                    allUniqueBuildings={fetchedAllUniqueBuildings}
                    allCommonBuildings={fetchedAllCommonBuildings}
                    selectedTown={selectedTown}
                    buildingsForReorder={buildingsForReorder}
                    setBuildingsForReorder={setBuildingsForReorder}
                    townBuilderGetConstructedBuildingsHook={townBuilderGetConstructedBuildingsHook} />
            );
        } else if (currentStep === 3) {
            return (
                <TownEffectsStep
                    ownedTownEffectsHook={ownedTownEffectsHook}
                    townEffectFreeBalanceOfBatchHook={townEffectFreeBalanceOfBatchHook}
                    selectedTownEffects={selectedTownEffects}
                    setSelectedTownEffects={setSelectedTownEffects}
                />
            );
        }
    }

    if (warningMessage) {
        return (
            <Alert message={warningMessage} type="info" showIcon />
        );
    }

    if (isBuildSuccessfullyStarted === true) {
        return (
            <div style={styles.pageContainer}>
                <BuilderBanner />
                <Space style={styles.placeholder}>
                    <Text style={styles.placeholderText}>
                        {buildingsForReorder.length > 0
                            ? "Build successfully started! It will take some time, but you can check status here:"
                            : "Effects application successfully started! You can check status here:"
                        }
                    </Text>
                    {buildingsForReorder.length > 0
                        ? (
                            <Button type="primary">
                                <Link to={MY_PROPERTY_TOWNS_SUBROUTE}>
                                    Go to My Towns
                                </Link>
                            </Button>
                        )
                        : (
                            <Button type="primary">
                                <Link to={MY_PROPERTY_TOWN_EFFECTS_SUBROUTE}>
                                    Go to My Town Effects
                                </Link>
                            </Button>
                        )
                    }
                </Space>
            </div>
        );
    }

    if (fetchSuccessful === undefined || isLoading === true || isLoadingConstructedBuildings
        || preFilterTownsByMetadata().length > 0 && constructedBuildings === undefined && constructedBuildingsError === null
        || isAllCommonBuildingsFetchSuccessful === undefined || isAllUniqueBuildingsFetchSuccessful === undefined
        || isLoadingAllCommonBuildings || isLoadingAllUniqueBuildings) {
        return (
            <div style={styles.pageContainer}>
                <BuilderBanner />
                <div style={styles.skeletonStepsContainer}>
                    {
                        [...Array(4)].map((_, i) =>
                            <Skeleton.Avatar active key={`${i}-step-skeleton`} className="tl-builder-step-skeleton" shape="square" />
                        )
                    }
                </div>
                <Skeleton.Avatar active shape="square" style={styles.skeletonStepContent} />
            </div>

        );
    }

    if (fetchSuccessful === false || constructedBuildingsError
        || isAllCommonBuildingsFetchSuccessful === false || isAllUniqueBuildingsFetchSuccessful === false) {
        return (
            <div style={styles.pageContainer}>
                <BuilderBanner />
                <Alert message={"Unknown error. Please reaload the page or try again later"} type="error" showIcon
                    style={{ width: "fit-content", marginLeft: "auto", marginRight: "auto" }} />
            </div>
        );
    }

    if (filterTowns().length === 0) {
        return (
            <div style={styles.pageContainer}>
                <BuilderBanner />
                <Space style={styles.placeholder}>
                    <Text style={styles.placeholderText}>You have no Towns. You can buy Land in the Market: </Text>
                    <Button type="primary">
                        <Link to={MARKET_LANDS_SUBROUTE}>
                            Explore Lands
                        </Link>
                    </Button>
                </Space>
            </div>
        )
    }

    if (filterUniqueBuildings().length === 0 && filterBuildings().length === 0 && filterTownEffects().length === 0) {
        return (
            <div style={styles.pageContainer}>
                <BuilderBanner />
                <Space style={styles.placeholder}>
                    <Text style={styles.placeholderText}>You don't have any Buildings or Town Effects. You can buy them in the Market: </Text>
                    <Button type="primary">
                        <Link to={MARKET_ROUTE}>
                            Go to Market
                        </Link>
                    </Button>
                </Space>
            </div>
        )
    }

    return (
        <div style={styles.pageContainer}>
            <BuilderBanner />
            <Steps
                type="default"
                current={currentStep}
                onChange={onStepChange}
                className="site-navigation-steps"
                items={
                    steps.map(s => {
                        return {
                            title: s.name,
                            description: resolveStepDescription(s.index),
                            status: resolveStepStatus(s.index),
                            disabled: resolveStepDisabled(s.index),
                            className: "tl-build-step",
                        }
                    })
                }
            />
            <div style={styles.contentStyle}>
                <div style={styles.stepHeader}>
                    <span style={styles.stepContentTitle}>{resolveStepContentHeader()}</span>
                    <div className="tl-builder-step-buttons" style={{ margin: "auto 0px" }}>
                        {currentStep === 1 && (
                            <Button
                                style={{ ...styles.stepButton, ...styles.stepButtonSecondary }}
                                disabled={!canSkipToTownEffectsStep()}
                                onClick={() => skipToTownEffects()}
                            >
                                Skip to Town Effects
                            </Button>
                        )}
                        {currentStep > 0 && (
                            <Button
                                style={{ ...styles.stepButton, ...styles.stepButtonSecondary }}
                                onClick={() => prevStep()}
                            >
                                Back
                            </Button>
                        )}
                        {currentStep < steps.length - 1 && (
                            <Button
                                type="primary"
                                style={{ ...styles.stepButton, ...styles.stepButtonPrimary }}
                                disabled={!canMoveToNextStep()}
                                onClick={() => nextStep()}
                            >
                                Proceed
                            </Button>
                        )}
                        {currentStep === steps.length - 1 && (
                            <Button
                                type="primary"
                                style={{ ...styles.stepButton, ...styles.stepButtonPrimary }}
                                icon={resolveBuildButtonIcon()}
                                disabled={!canStartBuild() || !isBuildPrepared}
                                loading={canStartBuild() && !isBuildPrepared}
                                onClick={() => build(() => setIsBuildSuccessfullyStarted(true))}
                            >
                                {resolveBuildButtonTitle()}
                            </Button>
                        )}
                    </div>
                </div>
                {buildStepContent()}
            </div>
        </div>
    );
}

export default Builder;
