import React from "react";
import { Space, message } from "antd";
import { buildTxBlockexplorerUrlByChainId } from "./nftUrls";
import { viemClient } from "../configs";

export const getMessageContentWithTxDetails = (message: string, txHash: string, chainId: number) => {
    return (
        <Space>
            <span>{message}</span>
            <a target="_blank"
                rel="noreferrer" href={buildTxBlockexplorerUrlByChainId(chainId, txHash)}>
                Click here to view transaction.
            </a>
        </Space>
    )
}

export const waitForTransactionComplete = async (txHash: `0x${string}`, chainId: number, messageBoxKey: string, onSuccess: () => void, customSuccessMessage?: string) => {
    const tx = await viemClient.waitForTransactionReceipt({ hash: txHash });

    if (tx.status === "reverted") {
        message.error({
            content: getMessageContentWithTxDetails("Transaction has failed.", txHash, chainId),
            key: messageBoxKey,
            duration: 5
        });
    } else if (tx.status === "success") {
        message.success({ content: customSuccessMessage ? customSuccessMessage : "Transaction successfully completed!ƒ", key: messageBoxKey, duration: 5 });
        onSuccess();
    } else {
        message.warning({
            content: getMessageContentWithTxDetails("Unknown transaction status.", txHash, chainId),
            key: messageBoxKey,
            duration: 5
        });
    }
}
