import { TOWN_PROGRESS_ATTRIBUTE_NAME } from "./constants";
import { Nft } from "./nfts";

export const PROPERTIES_WITH_DISPLAY_TYPE = [TOWN_PROGRESS_ATTRIBUTE_NAME];

export type NftProperty = {
    name: string,
    value: string,
    color: string,
}

const negativeEffectColors: { [id: number]: string } = {
    0: "red",
    1: "volcano",
}

const positiveEffectColors: { [id: number]: string } = {
    0: "magenta",
    1: "orange",
    2: "gold",
    3: "lime",
    4: "green",
    5: "cyan",
    6: "blue",
    7: "geekblue",
    8: "purple"
}

const delevelopmentLevelColors: { [id: number]: string } = {
    1: "",
    2: "orange",
    3: "lime",
    4: "green",
    5: "geekblue"
}

export const getNftProperties = (nft: Nft, renamePropertyFunc?: (name: string) => {}, renameValueFunc?: (value: any) => {}) => {
    return nft.metadata!.attributes.filter(a => a.trait_type && !a.display_type && !a.tl_enum_value
        || PROPERTIES_WITH_DISPLAY_TYPE.includes(a.trait_type) && a.display_type)
        .map(a => {
            const propertyValue = buildPropertyValue(a.display_type, a.value);
            return {
                name: renamePropertyFunc ? renamePropertyFunc(a.trait_type) : a.trait_type,
                value: renameValueFunc ? renameValueFunc(propertyValue) : propertyValue,
                color: resolvePropertyColor(a.trait_type)
            } as NftProperty;
        });
}

export const getNftEffects = (nft: Nft, renamePropertyFunc?: (name: string) => {}, renameValueFunc?: (value: any) => {}) => {
    return nft.metadata!.attributes.filter(a => a.trait_type && a.display_type && !a.tl_enum_value && !PROPERTIES_WITH_DISPLAY_TYPE.includes(a.trait_type))
        .map(a => {
            const [effectValue, effectIsPositive] = buildEffectValue(a.display_type, a.value);
            return {
                name: renamePropertyFunc ? renamePropertyFunc(a.trait_type) : a.trait_type,
                value: renameValueFunc ? renameValueFunc(effectValue) : effectValue,
                color: resolveEffectColor(a.trait_type, effectIsPositive)
            } as NftProperty;
        });
}

export const getNftDevelopmentLevels = (nft: Nft, renamePropertyFunc?: (name: string) => {}, renameValueFunc?: (value: any) => {}) => {
    return nft.metadata!.attributes.filter(a => a.trait_type && a.tl_enum_value)
        .map(a => {
            return {
                name: renamePropertyFunc ? renamePropertyFunc(a.trait_type) : a.trait_type,
                value: renameValueFunc ? renameValueFunc(a.value) : a.value,
                color: resolveDevelopmentLevelColor(a.tl_enum_value)
            } as NftProperty;
        });
}

export const buildEffectValue = (metadataDisplayType: string, metadataValue: any): [effectValue: string, positive: boolean] => {
    const [value, positive] = addEffectSign(metadataValue);
    if (metadataDisplayType === "boost_percentage") {
        return [`${value}%`, positive];
    }

    return [value, positive];
}

export const addEffectSign = (metadataValue: any): [metadataValue: string, positive: boolean] => {
    if (typeof metadataValue === "number") {
        return metadataValue >= 0 ? [`+${metadataValue.toString()}`, true] : [metadataValue.toString(), false];
    }

    if (metadataValue.startsWith("+")) {
        return [metadataValue, true];
    } else if (metadataValue.startsWith("-")) {
        return [metadataValue, false];
    } else {
        return [`+${metadataValue}`, true];
    }
}

export const buildPropertyValue = (metadataDisplayType: string | undefined, metadataValue: any) => {
    if (metadataDisplayType && metadataDisplayType === "boost_percentage") {
        return metadataValue + "%";
    }

    return metadataValue;
}


export const resolvePropertyColor = (metadataTraitType: string): string => {
    if (metadataTraitType === "Category") {
        return "lime";
    }

    return "blue";
}

export const resolveEffectColor = (metadataTraitType: string, effectIsPositive: boolean): string => {
    const colors = effectIsPositive ? positiveEffectColors : negativeEffectColors;
    const colorsCount = Object.keys(colors).length;
    const colorIndex = metadataTraitType.length % colorsCount;
    return colors[colorIndex];
}

export const resolveDevelopmentLevelColor = (metadataEnumValue: number): string => {
    return delevelopmentLevelColors[metadataEnumValue];
}
