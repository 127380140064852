import React from "react";
import { OrderedNft, SelectedTownNft } from "../../../helpers/townBuilder";
import { DndContext, DragEndEvent } from "@dnd-kit/core";
import { SortableContext, arrayMove } from "@dnd-kit/sortable";
import { restrictToFirstScrollableAncestor } from "@dnd-kit/modifiers";
import { Badge, List, Popover } from "antd";
import { SortableItem } from "../../DragNDrop/SortableItem";
import { ListItemImage } from "../ListItemImage";
import { GetConstructedBuildingsBatchHook } from "../../../hooks/contracts/useTownBuilderGetConstructedBuildingsBatch";
import { Nft } from "../../../helpers/nfts";

interface ReorderBuildingsStepProps {
    selectedTown: SelectedTownNft | undefined;

    allUserBuildings: Nft[];
    allUniqueBuildings: Nft[];
    allCommonBuildings: Nft[];

    buildingsForReorder: OrderedNft[];
    setBuildingsForReorder: React.Dispatch<React.SetStateAction<OrderedNft[]>>;

    townBuilderGetConstructedBuildingsHook: GetConstructedBuildingsBatchHook;
}

interface SortableNft {
    orderedNft: OrderedNft;
    isBuilt?: boolean;
    isInProgress?: boolean;
}

export const ReorderBuildingsStep = ({
    selectedTown,
    allUserBuildings,
    allUniqueBuildings,
    allCommonBuildings,
    buildingsForReorder,
    townBuilderGetConstructedBuildingsHook,
    setBuildingsForReorder }: ReorderBuildingsStepProps) => {

    const {
        inProgressBuildingsCounts,
        constructedBuildings
    } = townBuilderGetConstructedBuildingsHook;

    const getBuiltBuildings = (): SortableNft[] => {
        if (selectedTown === undefined || inProgressBuildingsCounts === undefined || constructedBuildings === undefined) {
            return [];
        }

        const builtCount = constructedBuildings[selectedTown.nft.token_id].length - inProgressBuildingsCounts[selectedTown.nft.token_id];
        return builtCount > 0
            ? constructedBuildings[selectedTown.nft.token_id].slice(0, builtCount).map((id, index) => {
                const tokenId = String(id);
                let matchedNft = allCommonBuildings.find(bn => bn.token_id === tokenId);
                if (matchedNft === undefined) {
                    matchedNft = allUniqueBuildings.find(bn => bn.token_id === tokenId);
                }

                return {
                    orderedNft: {
                        index: "-" + String(index + 1),
                        nft: matchedNft!,
                    } as OrderedNft,
                    isBuilt: true
                } as SortableNft
            })
            : [];
    }

    const builtBuildings = getBuiltBuildings();

    const getInProgressBuildings = (): SortableNft[] => {
        if (selectedTown === undefined || inProgressBuildingsCounts === undefined || constructedBuildings === undefined) {
            return [];
        }

        const inProgressCount = inProgressBuildingsCounts[selectedTown.nft.token_id];
        const buildings = constructedBuildings[selectedTown.nft.token_id];

        if (inProgressCount === 0) {
            return [];
        }

        const startIndex = buildings.length - inProgressCount;
        const inProgressBuildings: SortableNft[] = buildings.slice(startIndex)
            .map(id => allUserBuildings.find(ub => ub.token_id === String(id)))
            .filter(b => b !== undefined)
            .map((b, index) => { return { orderedNft: { index: "-" + String(index + 1 + builtBuildings.length), nft: b } as OrderedNft, isInProgress: true } as SortableNft });

        return inProgressBuildings;
    }

    const getBuildingsIncludingInProgress = (inProgressBuildingsList: SortableNft[], buildingsForReorder: OrderedNft[]): SortableNft[] => {
        return inProgressBuildingsList.concat(buildingsForReorder.map(b => { return { orderedNft: { index: b.index, nft: b.nft } } }));
    }

    const buildingsInProgress = getInProgressBuildings();
    const allBuildingsToDisplay = builtBuildings.concat(getBuildingsIncludingInProgress(buildingsInProgress, buildingsForReorder));

    const handleDragEnd = ({ active, over }: DragEndEvent) => {
        if (Number(active.id) < 0 || Number(over?.id) < 0) {
            return;
        }
        if (active.id !== over?.id) {
            setBuildingsForReorder((orderedNfts: OrderedNft[]) => {
                const oldIndex = orderedNfts.findIndex(ob => ob.index === active.id);
                const newIndex = orderedNfts.findIndex(ob => ob.index === over?.id);

                return arrayMove(orderedNfts, oldIndex, newIndex);
            });
        }
    }

    const resolvePopoverContent = (orderedBuilding: SortableNft) => {
        if (orderedBuilding.isBuilt) {
            return "This site is already occupied by a building that has been constructed";
        } else if (orderedBuilding.isInProgress) {
            return (
                <span style={{ color: "var(--tl-in-progress-color)" }}>
                    This building has already taken its place in the town, but is still under construction
                </span>
            );
        }

        return undefined;
    }

    return (
        <DndContext onDragEnd={handleDragEnd} modifiers={[restrictToFirstScrollableAncestor]}>
            <SortableContext items={allBuildingsToDisplay.map(building => String(building.orderedNft.index))}>
                <List
                    className="tl-builder-list"
                    grid={{ gutter: 16, column: 3, }}
                    dataSource={allBuildingsToDisplay}
                    locale={{ emptyText: "You have not selected any building" }}
                    renderItem={(orderedBuilding: SortableNft, index) =>
                        <SortableItem key={`order-${orderedBuilding.orderedNft.index}`} id={String(orderedBuilding.orderedNft.index)} isDisabled={orderedBuilding.isBuilt || orderedBuilding.isInProgress}>
                            <Popover
                                content={resolvePopoverContent(orderedBuilding)}
                                overlayClassName="tl-builder-item-popover"
                                mouseLeaveDelay={0}>
                                <List.Item
                                    className={`tl-builder-list-item tl-order-building 
                                    ${orderedBuilding.isBuilt ? "tl-building-constructed" : ""} 
                                    ${orderedBuilding.isInProgress ? "tl-building-in-progress" : ""}`}
                                    style={{ marginBottom: "0px", }}
                                >
                                    <Badge
                                        count={`#${index + 1}`}
                                        color="#3d6f73"
                                        className="tl-builder-order-badge"
                                        style={{ boxShadow: "none", fontWeight: "600", position: "absolute", borderRadius: "0.2rem", width: 38 }} />
                                    <ListItemImage nft={orderedBuilding.orderedNft.nft} />
                                </List.Item>
                            </Popover>
                        </SortableItem>
                    }
                >
                </List>
            </SortableContext >
        </DndContext >
    );
}
