import React from "react";
import { Image, Modal, Tag, Divider, Typography, Tooltip } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import NoImageIcon from "../../img/no-image.png";
import { useErc1155FrozenBalanceOf } from "../../hooks/contracts/useErc1155FrozenBalanceOf";
import { useTownIsBuildInProgress } from "../../hooks/contracts/useTownIsBuildInProgress";
import { useErc1155BalanceOf } from "../../hooks/contracts/useErc1155BalanceOf";
import { resolveOwnedBuildingSubTitleForModal, resolveOwnedTownEffectSubTitleForModal, resolveOwnedTownSubTitleForModal } from "../../helpers/subTitleResolver";
import { useErc721OwnerOf } from "../../hooks/contracts/useErc721OwnerOf";
import { useAccount } from "wagmi";
import { SubTitleWithCheckMark } from "../Common/SubTitleWithCheckMark";
import {
    NftProperty,
    getNftDevelopmentLevels,
    getNftEffects,
    getNftProperties,
} from "../../helpers/nftProperties";
import { Nft, TlNftType } from "../../helpers/nfts";

const NFT_MODAL_LEFT_SECTION_WIDTH = "340px";

const { Title, Text } = Typography;

const styles = {
    nftModal: {
        display: "flex",
        WebkitBoxPack: "start",
        justifyContent: "flex-start",
        margin: "0 auto",
        fontSize: "17px",
        fontWeight: "500",
    } as React.CSSProperties,
    image: {
        height: NFT_MODAL_LEFT_SECTION_WIDTH,
        width: NFT_MODAL_LEFT_SECTION_WIDTH,
        minWidth: NFT_MODAL_LEFT_SECTION_WIDTH,
        borderRadius: "1rem"
    } as React.CSSProperties,
    titleSection: {
        marginBottom: "10px",
        marginTop: "10px",
        textAlign: "center",
    } as React.CSSProperties,
    title: {
        fontSize: "30px",
        marginBottom: "8px",
    } as React.CSSProperties,
    dataSection: {
        marginLeft: "15px",
        marginTop: "10px",
        maxWidth: "600px",
        width: "100%",
    } as React.CSSProperties,
    propertiesTitleDivider: {
        fontSize: "20px",
        margin: "10px 0px",
    } as React.CSSProperties,
    property: {
        background: "none",
    } as React.CSSProperties,
    propertyName: {
        fontSize: "10px",
        color: "var(--tl-text-color)",
    } as React.CSSProperties,
    propertyValue: {
        fontSize: "16px",
        marginTop: "-2px",
        fontWeight: "600"
    } as React.CSSProperties,
    description: {
        fontSize: "20px",
        margin: "10px 0px",
    } as React.CSSProperties,
} as const;


interface NftModalProps {
    nft: Nft | undefined;
    chainId: number | undefined;
    forCurrentUser?: boolean | undefined;
    visible: boolean | undefined;
    buildActionButtons: (chainId: number | undefined, nft: Nft) => JSX.Element[];
    buildMainButtons: (chainId: number | undefined, nft: Nft) => JSX.Element[];
    onCancel: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
}

export const NftModal = (props: NftModalProps) => {
    const nft = props.nft!;

    const isAnyOwnedBuilding = (): boolean => {
        return (props.forCurrentUser ?? false) && (nft.tlNftType === TlNftType.Building || nft.tlNftType === TlNftType.UniqueBuilding);
    }

    const isOwnedTownEffect = (): boolean => {
        return (props.forCurrentUser ?? false) && nft.tlNftType === TlNftType.TownEffect;
    }

    const isOwnedTown = (): boolean => {
        return (props.forCurrentUser ?? false) && nft.tlNftType === TlNftType.Town;
    }

    const { address } = useAccount();

    const { nftOwner: townNftOwner, nftOwnerError: townNftOwnerError } = useErc721OwnerOf(
        (props.nft ?? false)
        && props.nft?.tlNftType === TlNftType.Town,
        props.nft?.token_address, props.nft?.token_id);

    const { balance, balanceError } = useErc1155BalanceOf(
        (props.nft ?? false)
        && props.nft?.tlNftType !== TlNftType.Town
        && props.nft?.amount === undefined,
        props.nft?.token_address, props.nft?.token_id);

    const { frozenNftsAmount, frozenBalanceOfError } = useErc1155FrozenBalanceOf(isAnyOwnedBuilding(), nft.token_id, nft.tlNftType);
    const { isTownBuildInProgress, isBuildInProgressError } = useTownIsBuildInProgress(isOwnedTown(), nft.token_id);

    let properties: NftProperty[] = [];
    let effects: NftProperty[] = [];
    let developmentLevels: NftProperty[] = [];

    let actionButtons: JSX.Element[] = [];
    let mainButtons: JSX.Element[] = [];

    const isConstructionInProgress = () => {
        return isOwnedTown() && isTownBuildInProgress || (isAnyOwnedBuilding() || isOwnedTownEffect()) && frozenNftsAmount && frozenNftsAmount > 0
    }

    const resolveSubTitle = () => {
        if (isOwnedTown() && townNftOwner?.toLowerCase() === address?.toLowerCase()) {
            if (isTownBuildInProgress) {
                return resolveOwnedTownSubTitleForModal(isTownBuildInProgress);
            }

            return <SubTitleWithCheckMark text="You own this Town" />;
        }

        if (!nft.amount && !balance) {
            return "";
        }

        if (props.forCurrentUser) {
            const amount = nft.amount ?? balance;
            if (isAnyOwnedBuilding() && frozenNftsAmount) {
                return resolveOwnedBuildingSubTitleForModal(amount, frozenNftsAmount);
            } else if (isOwnedTownEffect() && frozenNftsAmount) {
                return resolveOwnedTownEffectSubTitleForModal(amount, frozenNftsAmount);
            }

            return <SubTitleWithCheckMark text={`You own: ${amount}`} />;
        } else if (nft.amount) {
            return "Total amount: " + nft.amount;
        }

        return "";
    }

    if (nft) {
        properties = getNftProperties(nft);
        effects = getNftEffects(nft);
        developmentLevels = getNftDevelopmentLevels(nft);

        actionButtons = props.buildActionButtons(props.chainId, nft);
        mainButtons = props.buildMainButtons(props.chainId, nft);
    }

    return (
        <>
            <Modal
                className="nft-modal"
                open={props.visible}
                onCancel={props.onCancel}
                width="900px"
                bodyStyle={{ padding: "15px" }}
                footer={[
                    <div key={nft.token_id + "-action-buttons"} style={{
                        color: "var(--tl-text-color)",
                        display: "flex",
                        fontSize: "14px",
                        justifyContent: "center",
                        marginRight: "auto",
                        width: NFT_MODAL_LEFT_SECTION_WIDTH,
                        gap: "10px"
                    }}>
                        {actionButtons}
                    </div>,
                    ...mainButtons
                ]}
            >
                {props.visible && (
                    <div style={styles.nftModal}>
                        <div>
                            <Image
                                key={nft.token_id}
                                preview={{
                                    src: nft.image,
                                    mask: (
                                        <div className="ant-image-mask-info">
                                            <EyeOutlined />
                                            View Original Image
                                        </div>
                                    )
                                }}
                                src={nft.preview_image}
                                fallback={NoImageIcon}
                                alt=""
                                className={isConstructionInProgress() ? "tl-constructing-item-modal-image" : ""}
                                style={styles.image}
                            />
                            <div style={styles.titleSection}>
                                <Title style={styles.title}>{nft.metadata!.name}</Title>
                                <Text type="secondary" className={isConstructionInProgress() ? "tl-constructing-item-modal-subtitle" : ""}>
                                    {resolveSubTitle()}
                                </Text>
                            </div>
                        </div>
                        <div style={styles.dataSection}>
                            <PropertiesSection title="Properties" properties={properties} />
                            <PropertiesSection title="Effects" properties={effects} />
                            <PropertiesSection title="Development Level" properties={developmentLevels} />
                            <Divider orientation="center" style={styles.description}>Description</Divider>
                            <Text>
                                {
                                    nft.metadata!.description === undefined
                                        ? "No description provided."
                                        // : "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum."
                                        : nft.metadata!.description
                                }
                            </Text>
                        </div>
                    </div>
                )}
            </Modal >
        </>
    );
}

interface PropertiesSectionProps {
    title: string;
    properties: NftProperty[];
}

const PropertiesSection = (props: PropertiesSectionProps) => {
    const properties = props.properties;
    return (
        <>
            {properties.length > 0 && (
                <>
                    <Divider orientation="center" style={styles.propertiesTitleDivider}>{props.title}</Divider>
                    <div className="tl-props-container">
                        {properties.map(p => (
                            <Tooltip key={p.name} title={p.name} trigger="click" showArrow={false} overlayInnerStyle={{ marginBottom: "-12px" }}>
                                <Tag color={p.color} style={styles.property}>
                                    <p style={styles.propertyName}>{p.name.toUpperCase()}</p>
                                    <p style={styles.propertyValue}>{p.value}</p>
                                </Tag>
                            </Tooltip>
                        ))}
                    </div>
                </>
            )}
        </>
    );
}
