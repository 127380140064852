import React, { useEffect, useState } from "react";
import { Button } from "antd";
import { useAccount } from "wagmi";
import { SendTlcModal } from "./Nfts/SendTlcModal";
import { LOADING_CURRENCY_VALUE_PLACEHOLDER, useTlcBalance } from "../hooks/useBalance";

export interface SendTlcButtonProps {
    style?: React.CSSProperties | undefined;
    onOperationFinished?: () => void | undefined;
}

export const SendTlcButton = (props: SendTlcButtonProps) => {
    const { isConnected, address, chain } = useAccount();
    const { balance, fetchError, currencySymbol, tokenIcon, tokenIconSize } = useTlcBalance();

    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        setIsModalOpen(false);
    }, [address, chain, isConnected]);

    return (
        <div>
            <SendTlcModal
                currentBalance={balance === undefined || balance === LOADING_CURRENCY_VALUE_PLACEHOLDER ? undefined : Number(balance)}
                isOpen={isModalOpen}
                onCancel={() => setIsModalOpen(false)}
                onOperationFinished={props.onOperationFinished} />
            <Button
                size="large"
                type="primary"
                loading={balance === undefined || balance === LOADING_CURRENCY_VALUE_PLACEHOLDER}
                disabled={fetchError !== undefined || balance === "0"}
                style={props.style}
                onClick={() => setIsModalOpen(true)}>
                Send TLC
            </Button>
        </div>
    );
}