import React, { useContext, useEffect } from "react";
import { Alert, Button, Skeleton, Space, Tooltip, Typography, message } from "antd";
import { useNft } from "../../hooks/useNfts";
import NftCard from "./NftCard";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { buildNftBlockexplorerUrl, buildNftOpenSeaUrl } from "../../helpers/nftUrls";
import { BuyForTlcButton } from "../BuyForTlcButton";
import { NftModal } from "./NftModal";
import { useAccount } from "wagmi";
import { CONTENT_WIDTH, LOADING_NFT_MESSAGE_BOX_KEY } from "../../helpers/constants";
import { NftContext } from "../NftContext";
import { VIEW_TOWNS_ROUTE, resolveContractAddressFromRoute, resolveMarketSubRoute } from "../../helpers/routes";
import { TransferButton } from "../TransferButton";
import OpenSeaIcon from "../../img/opensea.svg"
import EtherscanIcon from "../../img/etherscan.svg"
import { BuyButton } from "../BuyButton";
import { Nft, TlNftType } from "../../helpers/nfts";

const { Text } = Typography;

const SVG_ICON_SIZE = "22px";

const styles = {
    nfts: {
        display: "flex",
        flexWrap: "wrap",
        WebkitBoxPack: "start",
        justifyContent: "center",
        margin: "0 auto",
        maxWidth: CONTENT_WIDTH,
        width: "fit-content",
        gap: "20px",
    } as React.CSSProperties,
    placeholder: {
        border: "rgba(188, 188, 183, 0.85) 1px solid",
        borderRadius: "1rem",
        padding: "12px",
        filter: "drop-shadow(rgba(0, 0, 0, 0.5) 3px 3px 2px)",
        background: "rgb(0 0 0 / 6%)"
    } as React.CSSProperties,
    placeholderText: {
        fontSize: "16px",
        color: "rgb(188 188 183 / 85%)"
    } as React.CSSProperties,
} as const;

export interface NoNftsPlaceholder {
    text: string;
    buttonText?: string;
    buttonRelativeHref?: string;
    button2Text?: string;
    button2RelativeHref?: string;
}

interface NftListProps {
    isLoading: boolean;
    fetchSuccessful: boolean | undefined;
    fetchedNfts: Nft[];
    currentTabRoute?: string;
    setLastElement: React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
    getCustomCardDescription?: (nft: Nft) => string;
    noNftsPlaceholder?: NoNftsPlaceholder;
    showPrices?: boolean;
    isNftsOwnedByUser?: boolean;
    ownedNfts?: Nft[];
    showUserOwnershipOnCard?: boolean;
    customModalButtons?: React.JSX.Element[];
}

const NftList = (props: NftListProps) => {
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const urlParams = useParams();
    const { isConnected, address, chain } = useAccount();
    const { nftToDisplay, setNftToDisplay } = useContext(NftContext);
    const { getNft, isLoading: isNftLoading, fetchSuccessful: fetchNftSuccessful, nft, error } = useNft(
        resolveContractAddressFromRoute(isConnected, chain, pathname, urlParams.id),
        urlParams.id,
        props.isNftsOwnedByUser ? address : undefined);

    useEffect(() => {
        if (!isConnected) {
            setNftToDisplay(undefined);
        }
    }, [isConnected]);

    useEffect(() => {
        setNftToDisplay(undefined);
    }, [address, chain]);

    useEffect(() => {
        const contractAddress = resolveContractAddressFromRoute(isConnected, chain, pathname, urlParams.id);
        if (urlParams.id && contractAddress) {
            // already showing or nft was set by click from card
            if (nftToDisplay && nftToDisplay.token_address === contractAddress && nftToDisplay.token_id === urlParams.id) {
                return;
            }

            message.loading({ content: "Loading NFT...", key: LOADING_NFT_MESSAGE_BOX_KEY, duration: 0 });
            setNftToDisplay(undefined);
            getNft();
            return;
        }
    }, [pathname, urlParams, isConnected, chain]);

    useEffect(() => {
        if (!urlParams.id || isNftLoading || !resolveContractAddressFromRoute(isConnected, chain, pathname, urlParams.id)) {
            return;
        }

        if (fetchNftSuccessful && nft) {
            message.destroy(LOADING_NFT_MESSAGE_BOX_KEY);
            setNftToDisplay(nft);
        } else if (error) {
            message.error({ content: error, key: LOADING_NFT_MESSAGE_BOX_KEY, duration: 3 });
            navigate("");
            setNftToDisplay(undefined);
        }
    }, [isNftLoading, fetchNftSuccessful, error]);

    return (
        <>
            {props.fetchSuccessful !== undefined && !props.fetchSuccessful &&
                (
                    <>
                        <Alert
                            message="Unable to fetch NFTs. Please try again later!"
                            type="error"
                            style={{ width: "fit-content", marginLeft: "auto", marginRight: "auto" }}
                            showIcon
                        />
                        <div style={{ marginBottom: "10px" }}></div>
                    </>
                )}
            <div style={styles.nfts}>
                {props.fetchedNfts.length > 0 &&
                    props.fetchedNfts.map((nft, index) => (
                        <NftCard
                            key={index}
                            index={index}
                            nft={nft}
                            chainId={chain?.id}
                            showPrices={props.showPrices}
                            nftOwnedByUser={props.isNftsOwnedByUser}
                            currentTabRoute={props.currentTabRoute}
                            setNftToDisplay={setNftToDisplay}
                            showUserOwnershipOnCard={props.showUserOwnershipOnCard}
                            ownedNfts={props.ownedNfts}
                            getCustomCardDescription={props.getCustomCardDescription}
                        />
                    ))
                }
                {
                    props.fetchSuccessful !== undefined && props.fetchedNfts.length === 0 && props.noNftsPlaceholder &&
                    <Space style={styles.placeholder}>
                        <Text style={styles.placeholderText}>{props.noNftsPlaceholder.text}</Text>
                        {
                            props.noNftsPlaceholder.buttonText && props.noNftsPlaceholder.buttonRelativeHref &&
                            <Button type="primary">
                                <Link to={props.noNftsPlaceholder.buttonRelativeHref}>
                                    {props.noNftsPlaceholder.buttonText}
                                </Link>
                            </Button>
                        }
                        {
                            props.noNftsPlaceholder.button2Text && props.noNftsPlaceholder.button2RelativeHref &&
                            <Button type="primary">
                                <Link to={props.noNftsPlaceholder.button2RelativeHref}>
                                    {props.noNftsPlaceholder.button2Text}
                                </Link>
                            </Button>
                        }
                    </Space>
                }
            </div >
            <br />
            <div style={styles.nfts}  >
                {
                    [...Array(4)].map((n, i) =>
                        <div key={`${i}-skeleton`}>
                            {(props.isLoading || props.fetchSuccessful === undefined) && (
                                <>
                                    <Skeleton.Avatar active className="tl-card-skeleton" shape="square" />
                                    <br /><br />
                                </>
                            )}
                            <Skeleton active loading={(props.isLoading || props.fetchSuccessful === undefined)} title={false} paragraph={{ rows: 2 }}>
                                {i === 2 && (<div ref={props.setLastElement} />)}
                            </Skeleton>
                        </div>
                    )
                }
            </div>
            {nftToDisplay
                ? <NftModal
                    visible={nftToDisplay !== undefined}
                    onCancel={() => {
                        navigate(props.currentTabRoute!);
                        setNftToDisplay(undefined);
                    }}
                    nft={nftToDisplay}
                    chainId={chain?.id}
                    forCurrentUser={props.isNftsOwnedByUser}
                    buildActionButtons={(chainId: number | undefined, nft: Nft) => {
                        let actionButtons = [
                            <Tooltip key="explorer" title="View on Blockchain">
                                <Button
                                    className="tl-nft-modal-action-button"
                                    size="large" onClick={() =>
                                        window.open(
                                            buildNftBlockexplorerUrl(chain, nft.token_address, nft.token_id),
                                            "_blank",
                                        )}>
                                    <img className="tl-modal-footer-svg-button" style={{ margin: "0px auto" }}
                                        src={EtherscanIcon} alt="" width={SVG_ICON_SIZE} height={SVG_ICON_SIZE}></img>
                                </Button>
                            </Tooltip>,
                            <Tooltip key="opensea" title="View on OpenSea">
                                <Button
                                    className="tl-nft-modal-action-button"
                                    style={{ marginLeft: "0px" }}
                                    size="large" onClick={() =>
                                        window.open(
                                            buildNftOpenSeaUrl(chain, nft.token_address, nft.token_id),
                                            "_blank",
                                        )}>
                                    <img className="tl-modal-footer-svg-button" style={{ margin: "0px auto" }}
                                        src={OpenSeaIcon} alt="" width={SVG_ICON_SIZE} height={SVG_ICON_SIZE}></img>
                                </Button>
                            </Tooltip>
                        ];

                        if (props.isNftsOwnedByUser && nft) {
                            let button = TransferButton({ index: nft?.token_id ?? "", nft: nft });
                            if (button) {
                                actionButtons.push(button);
                            }
                        }

                        return actionButtons;
                    }}
                    buildMainButtons={(chainId: number | undefined, nft: Nft) => {
                        let mainButtons: React.JSX.Element[] = [];

                        if (props.currentTabRoute && props.currentTabRoute.includes(VIEW_TOWNS_ROUTE)) {
                            return mainButtons;
                        }

                        if (props.isNftsOwnedByUser && nft && nft.tlNftType === TlNftType.Town) {
                            mainButtons.push(
                                <Button key="explore-towns" style={{ marginLeft: "12px" }} type="primary" size="large">
                                    <Link to={VIEW_TOWNS_ROUTE}>
                                        Explore Towns
                                    </Link>
                                </Button>
                            )
                        } else if (props.isNftsOwnedByUser && nft) {
                            mainButtons.push(
                                <Button key="view-market" style={{ marginLeft: "12px" }} type="primary" size="large">
                                    <Link to={resolveMarketSubRoute(nft.tlNftType) + "/" + nft.token_id}>
                                        View On Market
                                    </Link>
                                </Button>
                            )
                        }
                        else if (!props.isNftsOwnedByUser && nft.tlNftType === TlNftType.UniqueBuilding) {
                            let button = BuyForTlcButton({ index: nft?.token_id ?? "", nft: nft });
                            if (button) {
                                mainButtons.push(button);
                            }
                        } else if (!props.isNftsOwnedByUser) {
                            let button = BuyButton({ index: nft?.token_id ?? "", nft: nft });
                            if (button) {
                                mainButtons.push(button);
                            }
                        }

                        if (props.customModalButtons) {
                            mainButtons = mainButtons.concat(props.customModalButtons);
                        }

                        return mainButtons;
                    }}
                />
                :
                <></>
            }
        </>
    );
}

export default NftList;
